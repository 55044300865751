import { useContext } from "react";
import { AuthContext } from "../context/authContext";
import { Link, useNavigate } from "react-router-dom";
import { UserLayout } from "../layout/User";
import { SectionHead } from "../library/section/Head";
import { OverviewThisStats } from "../library/stats/OverviewThis";
import { OverviewLastStats } from "../library/stats/OverviewLast";
import { RevenueComparisonStats } from "../library/stats/RevenueComparison";
import { GroupStudentsStats } from "../library/stats/GroupStudents";
import { ProgramEnrolmentsStats } from "../library/stats/ProgramEnrolments";

const reports = [
  {
    title: "Attendance Report",
    description: "Attendance report for all students",
    link: "/report/attendance",
  },
  {
    title: "Retention Report",
    description: "Retention report for all students",
    link: "/report/retention",
  },
  {
    title: "Attrition Report",
    description: "Attrition report for student between terms",
    link: "/report/attrition",
  },
];

function Analytics(props) {
  const { isAdmin } = useContext(AuthContext);

  const navigate = useNavigate();

  if (!isAdmin) navigate("/");

  return (
    <UserLayout title="Finder">
      <header>
        <SectionHead heading="Analytics"></SectionHead>
      </header>
      <main className="py-8 grid grid-cols-12 gap-y-6 gap-x-6">
        <div className="col-span-6">
          {/* overview: this */}
          <OverviewThisStats />
        </div>
        <div className="col-span-6">
          {/* overview: last */}
          <OverviewLastStats />
        </div>
        {isAdmin && (
          <div className="col-span-12">
            {/* revenue comparison */}
            <RevenueComparisonStats />
          </div>
        )}
        <div className="col-span-6">
          {/* students by group */}
          <GroupStudentsStats />
        </div>
        {/* enrolments by programs */}
        <div className="col-span-6">
          <ProgramEnrolmentsStats />
        </div>
        <div className="col-span-12">
          {/* attendance */}
          {/* enrolments */}
          {/* retention */}
          <div className="card p-6 bg-white shadow w-full">
            <h3 className="text-lg font-semibold mb-4">Reports</h3>
            <div className="flex flex-col divide-y">
              {reports.map((report, index) => (
                <Link
                  to={report.link}
                  key={index}
                  className="p-2 flex flex-row gap-4 justify-between items-center hover:bg-gray-100"
                >
                  <div className="">
                    <h4 className="font-medium text-lg text-primary">
                      {report.title}
                    </h4>
                    <p className="label-text">{report.description}</p>
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6 fill-current text-primary"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </main>
    </UserLayout>
  );
}

export default Analytics;
