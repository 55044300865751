import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { CLASS_NOTIFICATION } from "../../utils/mutations";

export const NotificationForm = (props) => {
  const { id } = props;

  const [submission, setSubmission] = useState(null);
  const [message, setMessage] = useState("");

  const { register, watch, handleSubmit, reset } = useForm();

  const [notify, { loading }] = useMutation(CLASS_NOTIFICATION, {
    onCompleted: (data) => {
      if (data.sent) {
        reset();
        setSubmission("success");
        setMessage("Notification sent successfully!");
      } else {
        setSubmission("error");
        setMessage("Something went wrong!");
      }
    },
    onError: (error) => {
      setSubmission("error");
      setMessage(error.message);
    },
  });

  const selectedType = watch("notifyClass.type");

  const onSubmit = (data) => {
    const { type, subject, headline, body } = data.notifyClass;

    notify({
      variables: {
        input: {
          id,
          type,
          ...(type === "EMAIL" && subject && { subject }),
          ...(type === "EMAIL" && headline && { headline }),
          body,
        },
      },
    });
  };

  return (
    <div>
      {!submission ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* type: SMS / Email */}
          <div>
            <label className="label">
              <span className="label-text font-semibold">Type</span>
            </label>
            <select
              className="input input-bordered w-full bg-white"
              {...register("notifyClass.type")}
              required
              defaultValue={""}
            >
              <option disabled value="">
                Select Type
              </option>
              <option value="EMAIL">Email</option>
              <option value="SMS">SMS</option>
            </select>
          </div>
          {/* Note: for template values */}
          {selectedType === "EMAIL" && (
            <p className="text-sm border-t py-4">
              <span className="font-semibold">Note:</span> for dynamic values,
              use <span className="font-semibold">%value%</span> format.
              Accessible values are:
              {/* comma seperated values */}
              <span className="font-semibold">
                {" "}
                %class_name%, %student_name%
              </span>
              <br />
              <br />
              Ex. <span className="font-semibold">Dear %student_name%,</span>
            </p>
          )}
          {/* subject (if type is Email) */}
          {selectedType === "EMAIL" && (
            <div>
              <label className="label">
                <span className="label-text font-semibold">Subject</span>
              </label>
              <input
                className="input input-bordered w-full bg-white"
                {...register("notifyClass.subject")}
                required
              />
            </div>
          )}
          {/* headline (if type is Email) */}
          {selectedType === "EMAIL" && (
            <div>
              <label className="label">
                <span className="label-text font-semibold">Headline</span>
              </label>
              <input
                className="input input-bordered w-full bg-white"
                {...register("notifyClass.headline")}
                required
              />
            </div>
          )}
          {/* body */}
          {selectedType && (
            <>
              <div>
                <label className="label">
                  <span className="label-text font-semibold">Body</span>
                </label>
                <textarea
                  rows={10}
                  className="textarea textarea-bordered w-full bg-white"
                  {...register("notifyClass.body")}
                  required
                />
              </div>
              {/* submit */}
              <div className="pt-4">
                <button
                  type="submit"
                  className={loading ? "btn loading" : "btn"}
                >
                  Send Notification
                </button>
              </div>
            </>
          )}
        </form>
      ) : (
        <>
          <p
            className={`font-bold text-xl mb-6 ${
              submission === "error" && "text-error"
            } ${submission === "success" && "text-success"}`}
          >
            {message}
          </p>
          <label
            htmlFor="notification-modal"
            className="btn btn-outline"
            onClick={() => {
              setSubmission(null);
              setMessage("");
              reset();
            }}
          >
            Close
          </label>
        </>
      )}
    </div>
  );
};
