import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CREATE_PROGRAM, UPDATE_PROGRAM } from "../../utils/mutations";
import { PROGRAM_INFO, LIST_PROGRAMS } from "../../utils/queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import toast from "react-hot-toast";

export const ProgramForm = (props) => {
  const { id } = props;
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [info, setInfo] = useState(null);
  const { register, setValue, handleSubmit } = useForm();

  const notifyError = () => toast.error("Something went wrong!");
  const notifySuccess = () => toast.success("Updated successfully!");

  const [prefill, { data: prefillData, error: prefillError }] = useLazyQuery(
    PROGRAM_INFO,
    { variables: { programId: id } }
  );
  const [createProgram, { loading, data, error }] = useMutation(
    CREATE_PROGRAM,
    {
      refetchQueries: [{ query: LIST_PROGRAMS }],
    }
  );
  const [
    updateProgram,
    { loading: updateLoading, data: updateData, error: updateError },
  ] = useMutation(UPDATE_PROGRAM, {
    refetchQueries: [{ query: PROGRAM_INFO, variables: { programId: id } }],
  });

  const onSubmit = (data) => {
    const { name, details, active } = data.program;
    if (id) {
      updateProgram({
        variables: {
          input: {
            id,
            name,
            details,
            active: active === "true" ? true : false,
          },
        },
      });
    } else {
      createProgram({
        variables: {
          input: {
            name,
            details,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (id) {
      prefill();
    }
  }, [id, prefill]);

  useEffect(() => {
    if (prefillData) {
      setInfo({
        name: prefillData.program.name,
        details: prefillData.program.details,
        active: prefillData.program.active ? "true" : "false",
      });
    }
  }, [prefillData]);

  useEffect(() => {
    if (prefillError) notifyError();
  }, [prefillError]);

  useEffect(() => {
    if (info) {
      setValue("program", info);
    }
  }, [info, setValue]);

  useEffect(() => {
    if (loading) setLoader(true);
  }, [loading]);

  useEffect(() => {
    if (data) {
      setLoader(false);
      navigate(`/config/programs`);
    }
  }, [data, navigate]);

  useEffect(() => {
    if (error) {
      setLoader(false);
      notifyError();
    }
  }, [error]);

  useEffect(() => {
    if (updateLoading) setLoader(true);
  }, [updateLoading]);

  useEffect(() => {
    if (updateData) {
      setLoader(false);
      notifySuccess();
    }
  }, [updateData]);

  useEffect(() => {
    if (updateError) {
      setLoader(false);
      notifyError();
    }
  }, [updateError]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* name */}
        <div>
          <div>
            <label className="label">
              <span className="label-text font-semibold">Name</span>
            </label>
            <input
              type="text"
              name="name"
              placeholder="Program name"
              className="input input-bordered w-full max-w-md bg-white"
              {...register("program.name")}
              required
            />
          </div>
        </div>
        {/* details */}
        <div>
          <label className="label">
            <span className="label-text font-semibold">Details</span>
          </label>
          <textarea
            name="details"
            className="textarea textarea-bordered w-full max-w-md bg-white"
            placeholder="Program description"
            {...register("program.details")}
          ></textarea>
        </div>
        {/* active */}
        {id && (
          <div>
            <label className="label">
              <span className="label-text font-semibold">Status</span>
            </label>
            <select
              name="preassessment"
              defaultValue="Select"
              className="select select-bordered w-full max-w-md bg-white"
              {...register("program.active")}
              required
            >
              <option disabled>Select</option>
              <option value={true}>Active</option>
              <option value={false}>Inactive</option>
            </select>
          </div>
        )}
        {/* submit */}
        <div className="pt-4">
          <button type="submit" className={loader ? "btn loading" : "btn"}>
            {id ? "Update" : "Create"}
          </button>
        </div>
      </form>
    </div>
  );
};
