import { useQuery } from "@apollo/client";
import { ANALYTICS_STUDENTS_GROUPS } from "../../utils/queries";
import toast from "react-hot-toast";
import {
  ResponsiveContainer,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  Radar,
  Tooltip,
} from "recharts";

export const GroupStudentsStats = (props) => {
  const notifyError = (message) => toast.error(message);

  const { loading, data } = useQuery(ANALYTICS_STUDENTS_GROUPS, {
    onError: (error) => {
      notifyError(error.message);
    },
  });

  if (loading) return <></>;

  return (
    <div className="card bg-white shadow p-6 w-full h-[340px]">
      <h3 className="text-lg font-semibold mb-4">Students (by group)</h3>
      {data && (
        <ResponsiveContainer>
          <RadarChart
            outerRadius={90}
            width={730}
            height={250}
            data={data.students}
          >
            <PolarGrid />
            <PolarAngleAxis dataKey="group" />
            <Radar
              name="Students"
              dataKey="count"
              stroke="#e55a28"
              fill="#e55a28"
              fillOpacity={0.6}
            />
            <Tooltip wrapperClassName="rounded-xl" />
          </RadarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};
