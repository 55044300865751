import { useEffect, useState } from "react";
import { useForm, useFormState } from "react-hook-form";
import toast from "react-hot-toast";
import { useQuery, useMutation } from "@apollo/client";
import { PASS_SWITCH_SESSIONS } from "../../utils/queries";
import { UPDATE_PASS } from "../../utils/mutations";
import { toAmPm } from "../../utils/helpers";

export const ChangeDropInForm = (props) => {
  const { id, refetch } = props;

  const [message, setMessage] = useState("");
  const [classes, setClasses] = useState([]);
  const [allSessions, setAllSessions] = useState([]);
  const [sessions, setSessions] = useState([]);

  const { control, register, handleSubmit, reset, watch } = useForm({
    mode: "onChange",
  });
  const { isValid } = useFormState({ control });

  useQuery(PASS_SWITCH_SESSIONS, {
    variables: { passId: id },
    onCompleted: (data) => {
      if (data.sessions?.length > 0) {
        setAllSessions(data.sessions);

        const classList = data.sessions.map((session) => ({
          id: session.class.id,
          name: session.class.name,
          day: session.class.day,
          from: toAmPm(session.class.from),
          to: toAmPm(session.class.to),
        }));

        const uniqueClasses = classList.filter(
          (v, i, a) => a.findIndex((t) => t.id === v.id) === i
        );

        setClasses(uniqueClasses);
      }
    },
    onError: (error) => {
      console.error(error);
      setMessage("Unable to fetch applicable class sessions!");
      toast.error("Unable to fetch applicable class sessions!");
    },
  });

  const [updatePassSession, { updating }] = useMutation(UPDATE_PASS, {
    onCompleted: () => {
      setMessage("Pass updated successfully!");
      toast.success("Pass updated successfully!");
      reset();
      refetch();
    },
    onError: (error) => {
      setMessage("Unable to update pass!");
      toast.error("Unable to update pass!");
      console.error(error);
    },
  });

  const watchClass = watch("change.passClass");

  const onSubmit = (data) => {
    const { change } = data;

    const input = {
      id,
      changeSession: change.passSession,
    };

    updatePassSession({ variables: { input } });
  };

  useEffect(() => {
    if (watchClass) {
      // set sessions
      const classSessions = allSessions.filter(
        (session) => session.class.id === watchClass
      );

      setSessions(classSessions);
    }
  }, [watchClass, allSessions]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-2">
        {/* select class */}
        <div>
          <label className="label">
            <span className="label-text font-semibold">Class</span>
          </label>
          <select
            name="class"
            defaultValue="Select"
            className="select select-bordered w-full bg-white"
            {...register("change.passClass", {
              validate: (value) => value !== "Select",
              required: true,
            })}
            required
          >
            <option value="Select">Select</option>
            {classes.map((cls) => (
              <option key={cls.id} value={cls.id}>
                {cls.name} ({cls.day} {cls.from} - {cls.to})
              </option>
            ))}
          </select>
        </div>
        {/* select class session */}
        <div>
          <label className="label">
            <span className="label-text font-semibold">Session</span>
          </label>
          <select
            name="session"
            defaultValue="Select"
            className="select select-bordered w-full bg-white"
            required
            {...register("change.passSession", {
              validate: (value) => value !== "Select",
              required: true,
            })}
          >
            <option value="Select">Select</option>
            {sessions.map((session) => (
              <option key={session.id} value={session.id}>
                {session.name} (
                {new Date(session.date).toLocaleDateString("en-AU")})
              </option>
            ))}
          </select>
        </div>
        <div className="flex gap-2 items-center pt-4">
          {/* submit */}
          <div className="space-x-4">
            <button
              type="submit"
              disabled={!isValid}
              className={updating ? "btn loading" : "btn"}
            >
              Update Pass Usage
            </button>
          </div>
          <label
            htmlFor="change-pass-modal"
            className="btn btn-ghost btn-outline"
            onClick={() => {
              setMessage("");
              reset();
            }}
          >
            Cancel
          </label>
        </div>
      </form>
      {/* cancel */}
      <div className="pt-2">
        {!!message && (
          <p className={`font-medium text-base mb-6 text-center mt-4`}>
            {message}
          </p>
        )}
      </div>
    </>
  );
};
