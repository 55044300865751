import { useState } from "react";
import { useQuery } from "@apollo/client";
import { ANALYTICS_ENROLMENTS_COMPARISON } from "../../utils/queries";
import toast from "react-hot-toast";
import {
  ResponsiveContainer,
  CartesianGrid,
  BarChart,
  XAxis,
  Tooltip,
  Legend,
  Bar,
  LabelList,
} from "recharts";
import { formatter, compactFormatter } from "../../utils/helpers";

export const RevenueComparisonStats = (props) => {
  const [data, setData] = useState([]);
  const [thisYear, setThisYear] = useState(new Date().getFullYear());
  const [lastYear, setLastYear] = useState(new Date().getFullYear() - 1);

  const notifyError = (message) => toast.error(message);

  const { loading } = useQuery(ANALYTICS_ENROLMENTS_COMPARISON, {
    onCompleted: (data) => {
      const enrolments = data.enrolments;

      const enrolmentsByYear = enrolments.reduce((acc, enrolment) => {
        const year = enrolment.year;
        if (!acc[year]) {
          acc[year] = [];
        }
        acc[year].push(enrolment);
        return acc;
      }, {});

      // combine data ex. 2022[1] with 2021[1], 2022[2] with 2021[2], etc.
      const arr = [];
      // merge the two arrays into one ex. 2022[1] with 2021[1], 2022[2] with 2021[2], etc. if they exist else just push zero value
      for (let i = 0; i < Object.values(enrolmentsByYear)[0].length; i++) {
        const lastYearAmount =
          Object.values(enrolmentsByYear)[0][i].amount || 0;
        const thisYearAmount =
          i < Object.values(enrolmentsByYear)[1].length
            ? Object.values(enrolmentsByYear)[1][i].amount
            : 0;

        const obj = {
          term: `Term ${i + 1}`,
          [Object.keys(enrolmentsByYear)[0]]: lastYearAmount / 100,
          [Object.keys(enrolmentsByYear)[1]]: thisYearAmount / 100,
        };

        arr.push(obj);
      }

      setData(arr);
      setLastYear(Object.keys(enrolmentsByYear)[0]);
      setThisYear(Object.keys(enrolmentsByYear)[1]);
    },
    onError: (error) => {
      notifyError(error.message);
    },
  });

  if (loading) return <></>;

  return (
    <div className="card bg-white shadow p-6 w-full h-[380px]">
      <h3 className="text-lg font-semibold mb-4">Term Revenue Comparison</h3>
      {data && (
        <ResponsiveContainer>
          <BarChart width={730} height={250} data={data}>
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              dataKey="term"
              axisLine={false}
              tickLine={false}
              padding={{ top: 20 }}
              tick={{ fontWeight: 500 }}
            />
            <Tooltip
              formatter={(value) => formatter.format(value)}
              wrapperClassName="rounded-xl"
            />
            <Legend />
            <Bar dataKey={thisYear} fill="#e55a28" fillOpacity="80%">
              <LabelList
                dataKey={thisYear}
                position="top"
                formatter={(value) => compactFormatter.format(value)}
              />
            </Bar>
            <Bar dataKey={lastYear} fill="#111111" fillOpacity="70%">
              <LabelList
                dataKey={lastYear}
                position="top"
                formatter={(value) => compactFormatter.format(value)}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};
