import { LIST_REDEMPTION } from "../../utils/queries";
import { VOUCHER_IS_VALID, VOUCHER_IS_PAID } from "../../utils/mutations";
import { useQuery, useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";

export const RedemptionsTable = (props) => {
  const { term, formatted } = props;

  const [results, setResults] = useState([]);
  const [response, setResponse] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [resultsPerPage] = useState(10);
  const [list, setList] = useState([]);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(resultsPerPage);

  const notifyError = () => toast.error("Something went wrong!");
  const notifyLoading = () => toast.loading("Loading...");
  const notifySuccess = (message) => toast.success(message);

  const { loading, refetch } = useQuery(LIST_REDEMPTION, {
    onCompleted: (data) => {
      if (data?.vouchers) {
        const vouchers = data.vouchers.map((voucher) => {
          return {
            ...voucher,
            name: getFullName(
              voucher.student.firstName,
              voucher.student.middleName,
              voucher.student.lastName
            ),
          };
        });
        setResults(vouchers);
        setResponse(vouchers);
        formatted({
          title: "Redemptions",
          label: "redemption",
          list: vouchers.map((voucher) => ({
            Name: voucher.name,
            DoB: voucher.student.dob,
            Code: voucher.code,
            Type: voucher.type,
            Class: voucher.class.name,
            Amount: formatter.format(voucher.amount / 100),
            Charge: formatter.format(voucher.charge / 100),
            "Valid?": voucher.valid ? "Yes" : "No",
            "Paid?": voucher.paid ? "Yes" : "No",
            "Actioned?":
              voucher.status === "VALIDATED" || voucher.status === "FINALISED"
                ? "Yes"
                : "No",
          })),
        });
      }
    },
    onError: (error) => {
      if (error) {
        notifyError();
      }
    },
    pollInterval: 60000,
  });

  const [voucherIsValid] = useMutation(VOUCHER_IS_VALID, {
    onCompleted: (data) => {
      if (data?.updated) {
        notifySuccess("Voucher updated!");
        refetch();
      }
    },
    onError: (error) => {
      if (error) {
        notifyError();
      }
    },
  });
  const [voucherIsPaid] = useMutation(VOUCHER_IS_PAID, {
    onCompleted: (data) => {
      if (data?.updated) {
        notifySuccess("Voucher updated!");
        refetch();
      }
    },
    onError: (error) => {
      if (error) {
        notifyError();
      }
    },
  });

  const sliceData = (data, page, rows) => {
    setFrom(data.length > 0 ? (page - 1) * rows + 1 : 0);
    setTo(page * rows > data.length ? data.length : page * rows);
    return data.slice((page - 1) * rows, page * rows);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  useEffect(() => {
    if (loading) {
      notifyLoading();
    } else {
      toast.dismiss();
    }
  }, [loading]);

  useEffect(() => {
    if (term) {
      setResults(
        response.filter(
          (data) =>
            data.name.toLowerCase().includes(term.toLowerCase()) ||
            data.code.toLowerCase().includes(term.toLowerCase())
        )
      );
      setCurrentPage(1);
    } else {
      setResults(response);
    }
  }, [term, response]);

  useEffect(() => {
    if (results) setTotalPages(Math.ceil(results.length / resultsPerPage));
  }, [results, resultsPerPage]);

  useEffect(() => {
    const slice = sliceData(results, currentPage, resultsPerPage);
    setList([...slice]);
  }, [results, currentPage, setList, resultsPerPage]);

  return (
    <div>
      <div className="overflow-x-auto rounded-xl shadow-md">
        <table className="table-normal divide-y-2 bg-white rounded-xl w-full">
          {/* head */}
          <thead className="text-left">
            <tr>
              <th></th>
              <th>Name</th>
              <th>Voucher</th>
              <th className="pl-6">Valid?</th>
              <th>Class</th>
              <th>Amount</th>
              <th className="pl-6">Paid?</th>
            </tr>
          </thead>
          <tbody className="divide-y-2">
            {list.length > 0 &&
              list.map((value, index) => {
                const isValidated =
                  value.status === "VALIDATED" || value.status === "FINALISED";

                return (
                  <tr key={value.id}>
                    <th>{from + index}</th>
                    <td>
                      <p className="font-bold">{value.name}</p>
                      {value.student.gender} - {value.student.dob}
                    </td>
                    <td className="flex flex-row items-center gap-2">
                      <div>
                        {isValidated && value.valid && (
                          <CheckBadge className="w-6 h-6 fill-success" />
                        )}
                        {isValidated && !value.valid && (
                          <CrossBadge className="w-6 h-6 fill-error" />
                        )}
                      </div>
                      <div>
                        <p className="tracking-widest font-mono font-medium">
                          {value.code}
                        </p>
                        <p className="font-semibold">
                          {getVoucherLabel(value.type)}
                        </p>
                      </div>
                    </td>
                    <td className="space-x-2">
                      <button
                        className={`btn btn-xs ${
                          isValidated && !value.valid
                            ? "btn-disabled"
                            : "btn-ghost"
                        }`}
                        onClick={() =>
                          voucherIsValid({
                            variables: { voucherId: value.id, isValid: false },
                          })
                        }
                      >
                        No
                      </button>
                      <button
                        className={`btn btn-xs ${
                          isValidated && value.valid
                            ? "btn-disabled"
                            : "btn-ghost"
                        }`}
                        onClick={() =>
                          voucherIsValid({
                            variables: { voucherId: value.id, isValid: true },
                          })
                        }
                      >
                        Yes
                      </button>
                    </td>
                    <td>{truncateString(value.class.name, 20)}</td>
                    <td className="flex flex-row items-center gap-2">
                      <div>
                        {isValidated && value.paid && (
                          <CheckBadge className="w-6 h-6 fill-success" />
                        )}
                        {isValidated && !value.paid && (
                          <CrossBadge className="w-6 h-6 fill-error" />
                        )}
                      </div>
                      <div>
                        {isValidated && !value.valid && value.paid
                          ? formatter.format(value.charge / 100)
                          : formatter.format(value.amount / 100)}{" "}
                        <br />{" "}
                        {!value.valid &&
                          !value.paid &&
                          value.amount !== value.charge && (
                            <small>
                              Charge {formatter.format(value.charge / 100)} (if
                              invalid)
                            </small>
                          )}
                      </div>
                    </td>
                    <td className="space-x-2">
                      <button
                        className={`btn btn-xs ${
                          isValidated && !value.paid
                            ? "btn-disabled"
                            : "btn-ghost"
                        }`}
                        onClick={() =>
                          voucherIsPaid({
                            variables: { voucherId: value.id, isPaid: false },
                          })
                        }
                      >
                        No
                      </button>
                      <button
                        className={`btn btn-xs ${
                          isValidated && value.paid
                            ? "btn-disabled"
                            : "btn-ghost"
                        }`}
                        onClick={() =>
                          voucherIsPaid({
                            variables: { voucherId: value.id, isPaid: true },
                          })
                        }
                      >
                        Yes
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {/* table footer */}
      <div className="flex w-full px-2 py-4">
        {results && (
          <div className="flex flex-row justify-between items-center w-full">
            <div>
              <p>
                Showing <span className="font-bold">{from}</span> -{" "}
                <span className="font-bold">{to}</span> of{" "}
                <span className="font-bold">{results.length}</span> results
              </p>
            </div>
            <div className="btn-group">
              <button
                className="btn btn-outline btn-sm"
                disabled={currentPage === 1 || totalPages === 0}
                onClick={() => prevPage()}
              >
                Prev
              </button>
              <button
                className="btn btn-outline btn-sm"
                disabled={currentPage === totalPages || totalPages === 0}
                onClick={() => nextPage()}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const getFullName = (first, middle, last) => {
  let fullName = `${first} ${last}`;
  if (middle) {
    fullName = `${first} ${middle} ${last}`;
  }
  return fullName;
};

const getVoucherLabel = (type) => {
  // remove _ and capitalize
  return type
    .toLowerCase()
    .replace(/_/g, " ")
    .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
};

const formatter = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD",
});

function truncateString(string, limit) {
  if (string.length > limit) {
    return string.substring(0, limit) + "...";
  } else {
    return string;
  }
}

const CheckBadge = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
      clipRule="evenodd"
    />
  </svg>
);

const CrossBadge = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
      clipRule="evenodd"
    />
  </svg>
);
