import { CLASS_INFO } from "../../utils/queries";
import { useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import moment from "moment";

export const ClassDetails = (props) => {
  const {
    classId,
    option,
    allocation,
    setTitle,
    setCount,
    setHallAllocation,
    setClassLocation,
  } = props;

  const notifyError = () => toast.error("Unable to get information!");

  const { data } = useQuery(CLASS_INFO, {
    variables: {
      classId,
    },
    onCompleted: (info) => {
      setTitle(info.info.name);
      setCount(info.info.enrolments);
      if (info.info.status === "FULL") option(true);
      if (info.info.status === "AVAILABLE") option(false);
      if (info.info.allocated) allocation(true);
      if (info.info.location?.id) setClassLocation(info.info.location.id);
      if (info.info.hall?.id) setHallAllocation(true);
    },
    onError: () => notifyError(),
  });

  return (
    <>
      {data && data.info && (
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 py-6 px-8 my-6 bg-white rounded-lg shadow-md">
          <div>
            <dt className="p-1 pb-0">
              <span className="label-text font-semibold">Name</span>
            </dt>
            <dd className="p-1">
              <span className="mt-1 text-md">{data.info.name}</span>
            </dd>
          </div>

          <div>
            <dt className="p-1 pb-0">
              <span className="label-text font-semibold">Schedule</span>
            </dt>
            <dd className="p-1">
              <span className="mt-1 text-md">
                Every {data.info.day ?? ""} {formatTime(data.info.from)} -{" "}
                {formatTime(data.info.to)}
              </span>
            </dd>
          </div>

          <div>
            <dt className="p-1 pb-0">
              <span className="label-text font-semibold">Price</span>
            </dt>
            <dd className="p-1">
              <span className="mt-1 text-md">
                {formatter.format(data.info.price / 100)} per session
              </span>
            </dd>
          </div>

          <div>
            <dt className="p-1 pb-0">
              <span className="label-text font-semibold">Total</span>
            </dt>
            <dd className="p-1">
              <span className="mt-1 text-md">
                {formatter.format(
                  (data.info.price * data.info.remaining) / 100
                )}{" "}
                (current)
              </span>
            </dd>
          </div>

          <div>
            <dt className="p-1 pb-0">
              <span className="label-text font-semibold">Sessions</span>
            </dt>
            <dd className="p-1">
              <span className="mt-1 text-md">
                {data.info.remaining} (remaining)
              </span>
            </dd>
          </div>

          <div>
            <dt className="p-1 pb-0">
              <span className="label-text font-semibold">Enrolments</span>
            </dt>
            <dd className="p-1">
              <span className="mt-1 text-md">
                {data.info.enrolments} (overall)
              </span>
            </dd>
          </div>

          <div>
            <dt className="p-1 pb-0">
              <span className="label-text font-semibold">Term</span>
            </dt>
            <dd className="p-1">
              <span className="mt-1 text-md">{data.info.term.name}</span>
            </dd>
          </div>

          <div>
            <dt className="p-1 pb-0">
              <span className="label-text font-semibold">Program</span>
            </dt>
            <dd className="p-1">
              <span className="mt-1 text-md">{data.info.program.name}</span>
            </dd>
          </div>

          <div>
            <dt className="p-1 pb-0">
              <span className="label-text font-semibold">Group</span>
            </dt>
            <dd className="p-1">
              <span className="mt-1 text-md">{data.info.group.name}</span>
            </dd>
          </div>

          <div>
            <dt className="p-1 pb-0">
              <span className="label-text font-semibold">Location</span>
            </dt>
            <dd className="p-1">
              <span className="mt-1 text-md">
                {data.info.location.name ?? "-"}{" "}
                {data.info.hall?.name && `(${data.info.hall?.name ?? "-"})`}
              </span>
            </dd>
          </div>

          <div>
            <dt className="p-1 pb-0">
              <span className="label-text font-semibold">Preassessment</span>
            </dt>
            <dd className="p-1">
              <span className="mt-1 text-md">
                {data.info.preassessment ? "Required" : "Not required"}
              </span>
            </dd>
          </div>

          {/* <div>
            <dt className="p-1 pb-0">
              <span className="label-text font-semibold">Allocated</span>
            </dt>
            <dd className="p-1">
              <span className="mt-1 text-md">
                {data.info.allocated ? "Yes" : "No"}
              </span>
            </dd>
          </div> */}

          <div>
            <dt className="p-1 pb-0">
              <span className="label-text font-semibold">Status</span>
            </dt>
            <dd className="p-1">
              <span
                className={`text-xs py-1 px-2 rounded-md text-white ${statusTagColor(
                  data.info.status
                )}`}
              >
                {data.info.status}
              </span>
            </dd>
          </div>
        </div>
      )}
    </>
  );
};

const formatTime = (str) => moment(str, ["H:mm"]).format("LT");

const formatter = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD",
});

function statusTagColor(str) {
  switch (str) {
    case "AVAILABLE":
      return `bg-info`;
    case "ONGOING":
      return `bg-info`;
    case "FULL":
      return `bg-warning`;
    default:
      return `bg-base-300`;
  }
}
