import { useQuery } from "@apollo/client";
import { LIST_PRODUCTS } from "../../utils/queries";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { formatter } from "../../utils/helpers";

const BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL;

export const ProductsTable = (props) => {
  const { data } = useQuery(LIST_PRODUCTS, {
    onError: () => toast.error("Error listing products!"),
    fetchPolicy: "network-only",
  });

  return (
    <div>
      <div className="overflow-x-auto rounded-xl shadow-md">
        <table className="table-normal divide-y-2 bg-white rounded-xl w-full">
          {/* head */}
          <thead className="text-left">
            <tr>
              <th></th>
              <th>Name</th>
              <th>Description</th>
              <th>Price</th>
              <th>Status</th>
            </tr>
          </thead>
          {/* body */}
          <tbody className="divide-y-2">
            {data &&
              data.products &&
              data.products.map((value, index) => (
                <tr key={value.id}>
                  <th>{index + 1}</th>
                  <td>
                    <div className="flex flex-row gap-4 items-center">
                      <img
                        src={`${BUCKET_URL}/${value.thumbnail}`}
                        className="w-14 h-14 rounded-lg"
                        alt={`${value.name}`}
                      />
                      <p className="font-semibold">{value.name}</p>
                    </div>
                  </td>
                  <td>
                    <span className="line-clamp-1">
                      {value.description ?? "-"}
                    </span>
                  </td>
                  <td>{formatter.format(value.price / 100)}</td>
                  <td>
                    <span
                      className={`text-xs py-1 px-2 rounded-md text-white ${statusTagColor(
                        value.status
                      )}`}
                    >
                      {value.status}
                    </span>
                  </td>
                  <td>
                    <Link
                      to={`/edit/product/${value.id}`}
                      className={`btn btn-xs ${
                        value.status === "COMPLETE"
                          ? `btn-ghost btn-disabled`
                          : `btn-ghost`
                      }`}
                    >
                      edit
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

function statusTagColor(str) {
  switch (str) {
    case "AVAILABLE":
      return `bg-info`;
    default:
      return `bg-base-300`;
  }
}
