import { UPDATE_ROSTER } from "../../utils/mutations";
import { SESSION_DETAILS } from "../../utils/queries";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { useEffect } from "react";

export const RosterTable = (props) => {
  const { sessionId, roster } = props;

  const notifyError = () => toast.error("Something went wrong!");
  const notifySuccess = () => toast.success("Updated successfully!");

  const [updateRosterEntry, { data, error }] = useMutation(UPDATE_ROSTER, {
    refetchQueries: [{ query: SESSION_DETAILS, variables: { sessionId } }],
  });

  const action = (id, bool) => {
    updateRosterEntry({ variables: { input: { id, attended: bool } } });
  };

  useEffect(() => {
    if (data && data.success) notifySuccess();
  }, [data]);

  useEffect(() => {
    if (error) notifyError();
  }, [error]);

  return (
    <div>
      <div className="overflow-x-auto rounded-xl shadow-md">
        <table className="table-normal divide-y-2 bg-white rounded-xl w-full">
          {/* head */}
          <thead className="text-left">
            <tr>
              <th></th>
              <th>Student</th>
              <th>Attended</th>
              <th></th>
            </tr>
          </thead>
          <tbody className="divide-y-2">
            {roster &&
              roster.map((value, index) => (
                <tr key={value.id}>
                  <th>{index + 1}</th>
                  <td className="font-bold">{value.name}</td>
                  <td>
                    <span
                      className={`text-xs py-1 px-2 rounded-md text-white ${
                        value.attended ? `bg-success` : `bg-error`
                      }`}
                    >
                      {value.attended ? "YES" : "NO"}
                    </span>
                  </td>
                  <td className="space-x-4">
                    <button
                      className={`btn btn-xs ${
                        value.attended ? "btn-disabled" : "btn-ghost"
                      }`}
                      onClick={() => action(value.id, true)}
                    >
                      Present
                    </button>
                    <button
                      className={`btn btn-xs ${
                        !value.attended ? "btn-disabled" : "btn-ghost"
                      }`}
                      onClick={() => action(value.id, false)}
                    >
                      Absent
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
