import { UserLayout } from "../layout/User";
import { SectionHead } from "../library/section/Head";
import { ProfileInfo } from "../library/form/Info";
import { ChangePassword } from "../library/form/Password";
import { PROFILE_INFO } from "../utils/queries";
import { useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import { useEffect } from "react";

function Profile(props) {
  const notifyError = () => toast.error("Unable to get profile information!");

  const { data, error } = useQuery(PROFILE_INFO);

  useEffect(() => {
    if (error) notifyError();
  }, [error]);

  return (
    <UserLayout title="Profile">
      <section>
        <SectionHead heading="Profile" />
        {data && data.info && (
          <div className="divide-y-2 space-y-8">
            <ProfileInfo info={data.info} />
            <ChangePassword />
          </div>
        )}
      </section>
    </UserLayout>
  );
}

export default Profile;
