import { LIST_TERMS } from "../../utils/queries";
import { useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { formatter } from "../../utils/helpers";

export const TermsTable = (props) => {
  const { data } = useQuery(LIST_TERMS, {
    onError: () => toast.error("Error listing terms!"),
  });

  return (
    <div>
      <div className="overflow-x-auto rounded-xl shadow-md">
        <table className="table-normal divide-y-2 bg-white rounded-xl w-full">
          {/* head */}
          <thead className="text-left">
            <tr>
              <th></th>
              <th>Name</th>
              <th>Dates</th>
              <th>Drop-in</th>
              <th>Unlimited</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody className="divide-y-2">
            {data &&
              data.terms &&
              data.terms.map((value, index) => (
                <tr key={value.id}>
                  <th>{index + 1}</th>
                  <td className="font-bold">{value.name}</td>
                  <td>
                    {value.start} - {value.end}
                  </td>
                  <td>{formatter.format(value.dropIn / 100)}</td>
                  <td>{formatter.format(value.unlimited / 100)}</td>
                  <td>
                    <span
                      className={`text-xs py-1 px-2 rounded-md text-white ${statusTagColor(
                        value.status
                      )}`}
                    >
                      {value.status}
                    </span>
                  </td>
                  <td>
                    <Link
                      to={`/edit/term/${value.id}`}
                      className={`btn btn-xs ${
                        value.status === "COMPLETE"
                          ? `btn-ghost btn-disabled`
                          : `btn-ghost`
                      }`}
                    >
                      edit
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

function statusTagColor(str) {
  switch (str) {
    case "UPCOMING":
      return `bg-warning`;
    case "CURRENT":
      return `bg-info`;
    default:
      return `bg-base-300`;
  }
}
