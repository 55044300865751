import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context/authContext";
import { useSearchParams } from "react-router-dom";
import { UserLayout } from "../layout/User";
import { SectionHead } from "../library/section/Head";
import { CustomersTable } from "../library/table/Customers";
import { Tab } from "@headlessui/react";
import { StudentsTable } from "../library/table/Students";
import { PaymentsTable } from "../library/table/Payments";
import { RedemptionsTable } from "../library/table/Redemptions";
import { CSVLink } from "react-csv";

function Finder(props) {
  const { isAdmin } = useContext(AuthContext);

  const [searchParams, setSearchParams] = useSearchParams();

  const [type, setType] = useState(null);

  const [selectedTab, setSelectedTab] = useState(0);
  const [searchTerm, setSearchTerm] = useState(null);
  const [formattedData, setFormattedData] = useState({
    title: "",
    label: "",
    list: [],
  });

  const handleChange = (event) => {
    if (event.target.value) {
      setSearchTerm(event.target.value);
    } else {
      setSearchTerm(null);
    }
  };

  useEffect(() => {
    if (selectedTab === 0)
      setSearchParams({ type: "customers" }, { replace: true });
    if (selectedTab === 1)
      setSearchParams({ type: "students" }, { replace: true });
    if (selectedTab === 2)
      setSearchParams({ type: "payments" }, { replace: true });
    if (selectedTab === 3)
      setSearchParams({ type: "redemptions" }, { replace: true });
  }, [selectedTab, setSearchParams]);

  useEffect(() => {
    if (!type) setType(searchParams.get("type"));
  }, [type, searchParams, setType]);

  useEffect(() => {
    switch (type) {
      case "customers":
        setSelectedTab(0);
        break;
      case "students":
        setSelectedTab(1);
        break;
      case "payments":
        setSelectedTab(2);
        break;
      case "redemptions":
        setSelectedTab(3);
        break;
      default:
        setSelectedTab(0);
        return;
    }
  }, [type]);

  return (
    <UserLayout title="Finder">
      <section>
        <SectionHead heading="Finder">
          <input
            className="input input-bordered w-full md:w-[380px] max-w-xl bg-white ring-primary-focus form-control"
            type="search"
            placeholder="Filter by Keyword"
            onChange={handleChange}
          />
          {isAdmin && formattedData.list.length > 0 && (
            <CSVLink
              data={formattedData.list}
              target="_blank"
              filename={`${formattedData.label}.csv`}
              className="btn btn-dark btn-outline"
            >
              Download CSV
            </CSVLink>
          )}
        </SectionHead>
      </section>
      <section className="space-y-8 mt-8">
        <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
          <Tab.List className="flex space-x-1 rounded-xl max-w-md bg-base-300 p-1">
            <Tab
              className={({ selected }) =>
                classNames(
                  "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-neutral",
                  "ring-white ring-opacity-60 ring-offset-2 ring-offset-neutral focus:outline-none focus:ring-2",
                  selected
                    ? "bg-white shadow"
                    : "text-neutral hover:bg-white/[0.12] hover:text-neutral-focus"
                )
              }
            >
              Customers
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-neutral",
                  "ring-white ring-opacity-60 ring-offset-2 ring-offset-neutral focus:outline-none focus:ring-2",
                  selected
                    ? "bg-white shadow"
                    : "text-neutral hover:bg-white/[0.12] hover:text-neutral-focus"
                )
              }
            >
              Students
            </Tab>
            {isAdmin && (
              <Tab
                className={({ selected }) =>
                  classNames(
                    "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-neutral",
                    "ring-white ring-opacity-60 ring-offset-2 ring-offset-neutral focus:outline-none focus:ring-2",
                    selected
                      ? "bg-white shadow"
                      : "text-neutral hover:bg-white/[0.12] hover:text-neutral-focus"
                  )
                }
              >
                Payments
              </Tab>
            )}
            <Tab
              className={({ selected }) =>
                classNames(
                  "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-neutral",
                  "ring-white ring-opacity-60 ring-offset-2 ring-offset-neutral focus:outline-none focus:ring-2",
                  selected
                    ? "bg-white shadow"
                    : "text-neutral hover:bg-white/[0.12] hover:text-neutral-focus"
                )
              }
            >
              Redemptions
            </Tab>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <CustomersTable term={searchTerm} formatted={setFormattedData} />
            </Tab.Panel>
            <Tab.Panel>
              <StudentsTable term={searchTerm} formatted={setFormattedData} />
            </Tab.Panel>
            {isAdmin && (
              <Tab.Panel>
                <PaymentsTable term={searchTerm} formatted={setFormattedData} />
              </Tab.Panel>
            )}
            <Tab.Panel>
              <RedemptionsTable
                term={searchTerm}
                formatted={setFormattedData}
              />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </section>
    </UserLayout>
  );
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default Finder;
