import { Link } from "react-router-dom";
import { PublicLayout } from "../layout/Public";

function Error(props) {
  return (
    <PublicLayout title="Error">
      <div className="text-center">
        <h1 className="text-4xl font-bold">Error</h1>
        <div className="text-center pt-4">
          <p className="text-gray-600 italic">
            Either something went wrong or the resource you are looking for is
            not available.
          </p>
        </div>
        <div className="pt-8">
          <Link to="/" className="btn btn-primary w-full">
            Back to home
          </Link>
        </div>
      </div>
    </PublicLayout>
  );
}

export default Error;
