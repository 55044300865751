import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { SCHEDULE_FILTER, CLASS_INFO } from "../../utils/queries";
import { CREATE_CLASS, UPDATE_CLASS } from "../../utils/mutations";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import toast from "react-hot-toast";

export const ClassForm = (props) => {
  const { id } = props;
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [info, setInfo] = useState(null);
  const { register, setValue, handleSubmit, control } = useForm();

  const locationValue = useWatch({
    control,
    name: "class.location",
  });

  const notifyError = () => toast.error("Something went wrong!");
  const notifySuccess = () => toast.success("Updated successfully!");

  const { data: filterData } = useQuery(SCHEDULE_FILTER, {
    fetchPolicy: "cache-and-network",
    onError: () => notifyError(),
  });
  const [prefill, { data: prefillData }] = useLazyQuery(CLASS_INFO, {
    variables: { classId: id },
    onError: () => notifyError(),
  });

  const [createClass, { loading: creating }] = useMutation(CREATE_CLASS, {
    onCompleted: (data) => {
      if (data) {
        setLoader(false);
        navigate(`/class/${data.class}`);
      }
    },
    onError: () => {
      setLoader(false);
      notifyError();
    },
  });
  const [updateClass, { loading: updating }] = useMutation(UPDATE_CLASS, {
    onCompleted: (data) => {
      if (data) {
        setLoader(false);
        notifySuccess();
      }
    },
    onError: () => {
      setLoader(false);
      notifyError();
    },
    refetchQueries: [{ query: CLASS_INFO, variables: { classId: id } }],
  });

  // call mutation based on mode: create or edit
  const onSubmit = (data) => {
    const {
      name,
      price,
      day,
      from,
      to,
      preassessment,
      term,
      program,
      group,
      location,
      hall,
    } = data.class;
    if (id) {
      updateClass({
        variables: {
          input: {
            id,
            name,
            price: price * 100,
            preassessment: preassessment === "true" ? true : false,
            program,
            group,
            location,
            hall: !!hall ? hall : null,
          },
        },
      });
    } else {
      createClass({
        variables: {
          input: {
            name,
            price: price * 100,
            day,
            from,
            to,
            preassessment: preassessment === "true" ? true : false,
            term,
            program,
            group,
            location,
            hall: !!hall ? hall : null,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (id) {
      prefill();
    }
  }, [id, prefill]);

  useEffect(() => {
    if (filterData && prefillData) {
      setInfo({
        name: prefillData.info.name,
        price: prefillData.info.price / 100,
        preassessment: prefillData.info.preassessment ? "true" : "false",
        program: prefillData.info.program.id,
        group: prefillData.info.group.id,
        location: prefillData.info.location.id,
        ...(prefillData.info.hall && {
          hall: prefillData.info.hall.id,
        }),
      });
    }
  }, [filterData, prefillData, setValue]);

  useEffect(() => {
    if (info) {
      setValue("class", info);
    }
  }, [info, setValue]);

  useEffect(() => {
    if (creating || updating) setLoader(true);
  }, [creating, updating]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* name */}
        <div>
          <div>
            <label className="label">
              <span className="label-text font-semibold">Name</span>
            </label>
            <input
              type="text"
              name="name"
              placeholder="Class name"
              className="input input-bordered w-full max-w-md bg-white"
              {...register("class.name")}
              required
            />
          </div>
        </div>
        {/* price */}
        <div>
          <div>
            <label className="label">
              <span className="label-text font-semibold">Price</span>
            </label>
            <input
              type="number"
              name="price"
              step=".01"
              placeholder="Price per session"
              className="input input-bordered w-full max-w-md bg-white"
              {...register("class.price", { valueAsNumber: true })}
              required
            />
          </div>
        </div>
        {/* day */}
        {!id && (
          <div>
            <label className="label">
              <span className="label-text font-semibold">Day</span>
            </label>
            <select
              name="day"
              defaultValue="Select"
              className="select select-bordered w-full max-w-md bg-white"
              {...register("class.day")}
              required
            >
              <option disabled>Select</option>
              <option value="Sunday">Sunday</option>
              <option value="Monday">Monday</option>
              <option value="Tuesday">Tuesday</option>
              <option value="Wednesday">Wednesday</option>
              <option value="Thursday">Thursday</option>
              <option value="Friday">Friday</option>
              <option value="Saturday">Saturday</option>
            </select>
          </div>
        )}
        {/* from - to */}
        {!id && (
          <div className="flex justify-between space-x-3 max-w-md">
            <div className="w-full">
              <div>
                <label className="label">
                  <span className="label-text font-semibold">From</span>
                </label>
                <input
                  type="time"
                  step="900"
                  name="from"
                  placeholder="Start time"
                  className="input input-bordered w-full max-w-md bg-white"
                  {...register("class.from")}
                  required
                />
              </div>
            </div>
            <div className="w-full">
              <div>
                <label className="label">
                  <span className="label-text font-semibold">To</span>
                </label>
                <input
                  type="time"
                  step="900"
                  name="to"
                  placeholder="Start time"
                  className="input input-bordered w-full max-w-md bg-white"
                  {...register("class.to")}
                  required
                />
              </div>
            </div>
          </div>
        )}
        {/* preassessment */}
        <div>
          <label className="label">
            <span className="label-text font-semibold">Preassessment</span>
          </label>
          <select
            name="preassessment"
            defaultValue="Select"
            className="select select-bordered w-full max-w-md bg-white"
            {...register("class.preassessment")}
            required
          >
            <option disabled>Select</option>
            <option value={false}>Not required</option>
            <option value={true}>Required</option>
          </select>
        </div>
        {/* term */}
        {!id && filterData && filterData.filters && (
          <div>
            <label className="label">
              <span className="label-text font-semibold">Term</span>
            </label>
            <select
              name="term"
              defaultValue="Select"
              className="select select-bordered w-full max-w-md bg-white"
              {...register("class.term")}
              required
            >
              <option disabled>Select</option>
              {filterData.filters.terms &&
                filterData.filters.terms.map((data, index) => {
                  return (
                    <option key={index} value={data.id}>
                      {`${data.name} (${data.start} - ${data.end})`}
                    </option>
                  );
                })}
            </select>
          </div>
        )}
        {/* program */}
        {filterData && filterData.filters && (
          <div>
            <label className="label">
              <span className="label-text font-semibold">Program</span>
            </label>
            <select
              name="program"
              defaultValue="Select"
              className="select select-bordered w-full max-w-md bg-white"
              {...register("class.program")}
              required
            >
              <option disabled>Select</option>
              {filterData.filters.programs &&
                filterData.filters.programs.map((data, index) => {
                  return (
                    <option key={index} value={data.id}>
                      {data.name}
                    </option>
                  );
                })}
            </select>
          </div>
        )}
        {/* group */}
        {filterData && filterData.filters && (
          <div>
            <label className="label">
              <span className="label-text font-semibold">Group</span>
            </label>
            <select
              defaultValue="Select"
              className="select select-bordered w-full max-w-md bg-white"
              {...register("class.group")}
              required
            >
              <option disabled>Select</option>
              {filterData.filters.groups &&
                filterData.filters.groups.map((data, index) => {
                  return (
                    <option key={index} value={data.id}>
                      {data.name}
                    </option>
                  );
                })}
            </select>
          </div>
        )}
        {/* location */}
        {filterData && filterData.filters && (
          <div>
            <label className="label">
              <span className="label-text font-semibold">Studio</span>
            </label>
            <select
              name="location"
              defaultValue="Select"
              className="select select-bordered w-full max-w-md bg-white"
              {...register("class.location")}
              required
            >
              <option disabled>Select</option>
              {filterData.filters.locations &&
                filterData.filters.locations.map((data, index) => {
                  return (
                    <option key={index} value={data.id}>
                      {data.name}
                    </option>
                  );
                })}
            </select>
          </div>
        )}
        {/* hall */}
        {filterData && filterData.filters && locationValue && (
          <div>
            <label className="label">
              <span className="label-text font-semibold">Hall</span>
            </label>
            <select
              name="hall"
              defaultValue="Select"
              className="select select-bordered w-full max-w-md bg-white"
              {...register("class.hall")}
            >
              <option value="">Select</option>
              {filterData.filters.halls &&
                filterData.filters.halls.map((data, index) => {
                  return (
                    <option
                      key={index}
                      value={data.id}
                      disabled={data.location.id !== locationValue}
                    >
                      {data.name}
                    </option>
                  );
                })}
            </select>
          </div>
        )}
        {/* submit */}
        <div className="pt-4 space-x-4">
          <button type="submit" className={loader ? "btn loading" : "btn"}>
            {id ? "Update" : "Create"}
          </button>
        </div>
      </form>
    </div>
  );
};
