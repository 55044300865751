import { useState, useContext } from "react";
import { AuthContext } from "../context/authContext";
import { useParams, useNavigate } from "react-router-dom";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { CSVLink } from "react-csv";
import toast from "react-hot-toast";
import clsx from "clsx";
import { UserLayout } from "../layout/User";
import { LoadingLayout } from "../layout/Loading";
import { SectionHead } from "../library/section/Head";
import { OverviewSection } from "../library/section/Overview";
import { ItemsList } from "../library/list/Items";
import { TransactionSection } from "../library/section/Transaction";
import {
  formatter,
  formatMobile,
  paymentTagColor,
  statusTagColor,
} from "../utils/helpers";
import { READY_ENTITY, COMPLETE_ENTITY } from "../utils/mutations";
import { ORDER_INFO, ORDERS_LIST } from "../utils/queries";

export default function Orders(props) {
  const { param } = useParams();

  const { isAdmin, isManager } = useContext(AuthContext);

  if (param) {
    if (param === "list") return <OrdersList />;
    return <OrderDetails id={param} isAdmin={isAdmin} isManager={isManager} />;
  } else {
    return <OrdersList isAdmin={isAdmin} isManager={isManager} />;
  }
}

// For listing all orders
function OrdersList(props) {
  const { isAdmin, isManager } = props;

  const navigate = useNavigate();

  // get tomorrow's date yyyy-mm-dd
  const dateTomorrow = new Date(new Date().setDate(new Date().getDate() + 1))
    .toISOString()
    .split("T")[0];
  // get 1 month ago yyyy-mm-dd
  const dateMonthAgo = new Date(new Date().setMonth(new Date().getMonth() - 1))
    .toISOString()
    .split("T")[0];

  const [status, setStatus] = useState("");
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState(null);
  const [from, setFrom] = useState(dateMonthAgo);
  const [to, setTo] = useState(dateTomorrow);
  const [orders, setOrders] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [limit /*, setLimit*/] = useState(10);
  const [maxPage, setMaxPage] = useState(0);
  const [data, setData] = useState([]);

  const { loading, refetch } = useQuery(ORDERS_LIST, {
    onCompleted: (data) => {
      setOrders(data.list.orders);
      setTotal(data.list.total);
      // based on the total and limit, calculate the max page
      setMaxPage(Math.ceil(data.list.total / limit));

      // set locations
      setLocations(data.listLocations ?? []);
    },
    onError: (error) => {
      toast.error(error.message);
    },
    variables: {
      from: !!from ? from : null,
      to: !!to ? to : null,
      status:
        status !== ""
          ? [status]
          : [
              "PENDING",
              "AWAITING",
              "PROCESSING",
              "DRAFT",
              "APPROVED",
              "READY",
              "FULFILLED",
              "CANCELLED",
              "REFUNDED",
            ],
      locationId: !!location ? location : null,
      page,
      limit,
    },
    fetchPolicy: "network-only",
  });

  const [getOrdersToDownload, { data: download, loading: downloading }] =
    useLazyQuery(ORDERS_LIST, {
      onCompleted: (res) => {
        if (res.list.orders.length > 0) {
          const formatted = res.list.orders.map((order, i) => ({
            "No.": i + 1,
            Number: order.number,
            Date: order.date,
            Time: order.time,
            Email: order.user.email ?? "-",
            Phone: order.user.phone ? formatMobile(order.user.phone) : "-",
            Location: order.location.name,
            Method: order.payment.method ?? "UNKNOWN",
            Items: order.items.length,
            ...(isAdmin && {
              Paid: formatter.format(order.total / 100),
              "Discount Amt.": formatter.format(order.amounts.discount / 100),
              "Total Amt.": formatter.format(
                (order.total +
                  order.amounts.discount -
                  order.amounts.refunded) /
                  100
              ),
              "Refund Amt.": formatter.format(order.amounts.refunded / 100),
              "Payment Ref.": order.payment.ref,
            }),
            Status: order.status,
          }));
          setData(formatted);
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
      fetchPolicy: "network-only",
    });

  const getDownloadData = () => {
    getOrdersToDownload({
      variables: {
        from: !!from ? from : null,
        to: !!to ? to : null,
        status:
          status !== ""
            ? [status]
            : [
                "PENDING",
                "AWAITING",
                "PROCESSING",
                "DRAFT",
                "APPROVED",
                "READY",
                "FULFILLED",
                "CANCELLED",
                "REFUNDED",
              ],
        locationId: !!location ? location : null,
        page,
        limit: total,
      },
    });
  };

  const formatLabel = (str) => str.replace(/_/g, " ");

  const nextPage = () => {
    // if page is max page, then do nothing
    if (page === maxPage - 1) return;
    setPage(page + 1);
    refetch();
  };

  const prevPage = () => {
    // if page is 0, then do nothing
    if (page === 0) return;
    setPage(page - 1);
    refetch();
  };

  return (
    <UserLayout title="Orders List">
      <section>
        <SectionHead heading="Orders List">
          {/* Back to Orders */}
          {/* <button
            className="btn btn-outline"
            onClick={() => navigate("/orders")}
          >
            Manage Orders
          </button> */}
        </SectionHead>
        <div className="flex flex-col gap-4 py-2">
          {/* table */}
          <div className="overflow-x-auto rounded-xl shadow-md my-4 bg-white">
            {/* table header */}
            <div className="flex w-full px-4 py-4 border-b-2">
              <div className="flex flex-row justify-between items-center w-full gap-4">
                {/* filters */}
                <div className="basis-3/4 flex flex-row gap-4">
                  {/* select location */}
                  <div className="w-1/3">
                    <label className="label sr-only">
                      <span className="label-text font-semibold">Location</span>
                    </label>
                    <select
                      className="select select-bordered w-full bg-white"
                      onChange={(e) => setLocation(e.target.value)}
                      value={location}
                    >
                      <option value="">ALL</option>
                      {locations.map((loc) => (
                        <option key={loc.id} value={loc.id}>
                          {loc.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* select status */}
                  <div className="w-1/3">
                    <label className="label sr-only">
                      <span className="label-text font-semibold">Name</span>
                    </label>
                    <select
                      className="select select-bordered w-full bg-white"
                      onChange={(e) => setStatus(e.target.value)}
                      value={status}
                    >
                      <option value="">ALL</option>
                      <option value="PENDING">PENDING</option>
                      <option value="AWAITING">AWAITING</option>
                      <option value="PROCESSING">PROCESSING</option>
                      <option value="DRAFT">DRAFT</option>
                      <option value="APPROVED">APPROVED</option>
                      <option value="READY">READY</option>
                      <option value="FULFILLED">FULFILLED</option>
                    </select>
                  </div>
                  {/* date from */}
                  <div className="w-1/3">
                    <label className="label sr-only">
                      <span className="label-text font-semibold">
                        Date From
                      </span>
                    </label>
                    <input
                      type="date"
                      className="input input-bordered w-full bg-white"
                      onChange={(e) => {
                        setFrom(e.target.value);
                        refetch();
                      }}
                      value={from}
                      max={to}
                    />
                  </div>
                  {/* date to */}
                  <div className="w-1/3">
                    <label className="label sr-only">
                      <span className="label-text font-semibold">Date To</span>
                    </label>
                    <input
                      type="date"
                      className="input input-bordered w-full bg-white"
                      onChange={(e) => {
                        setTo(e.target.value);
                        refetch();
                      }}
                      value={to}
                      min={from}
                    />
                  </div>
                </div>
                {/* buttons */}
                <div className="flex flex-row gap-2">
                  {(isAdmin || isManager) && (
                    <>
                      {/* download csv */}
                      {download &&
                      download?.list?.orders.length === data.length ? (
                        <CSVLink
                          className="btn btn-ghost"
                          data={data}
                          filename={`orders.csv`}
                          onClick={(e) => {
                            setData([]);
                          }}
                        >
                          Download CSV
                        </CSVLink>
                      ) : (
                        <button
                          className={clsx(
                            "btn btn-ghost",
                            downloading && "loading"
                          )}
                          onClick={getDownloadData}
                        >
                          Generate CSV
                        </button>
                      )}
                    </>
                  )}
                  {/* reset button */}
                  <button
                    className="btn btn-ghost"
                    onClick={() => {
                      setLocation("");
                      setStatus("");
                      setFrom(dateMonthAgo);
                      setTo(dateTomorrow);
                      setData([]);
                      refetch();
                    }}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
            {/* table content */}
            {!loading ? (
              <table className="table-normal divide-y-2 bg-white rounded-xl w-full">
                {/* head */}
                <thead className="text-left">
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Location</th>
                    <th>Method</th>
                    <th>Paid</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                {/* body */}
                {orders.length > 0 ? (
                  <tbody className="divide-y-2">
                    {orders.map((value, index) => (
                      <tr key={value.id}>
                        <th>{page * limit + index + 1}</th>
                        <td className="flex flex-row gap-4 items-center">
                          <div>
                            <p className="font-bold">
                              #{value.number} ({value.user.email})
                            </p>
                            <p>
                              <span className="text-sm text-gray-600">
                                {value.date} {value.time}
                              </span>
                            </p>
                          </div>
                        </td>
                        <td>{value.location.name ?? "-"}</td>
                        <td>
                          <span
                            className={`text-xs py-1 px-2 rounded-md text-white ${paymentTagColor(
                              value.payment.method
                            )}`}
                          >
                            {formatLabel(value.payment.method ?? "UNKNOWN")}
                          </span>
                        </td>
                        <td>
                          <span className="font-mono">
                            {formatter.format(value.total / 100)}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`text-xs py-1 px-2 rounded-md text-white ${statusTagColor(
                              value.status
                            )}`}
                          >
                            {value.status}
                          </span>
                        </td>
                        <td>
                          <button
                            className={`btn btn-xs btn-ghost ${
                              value.status === "ACTIVE" ? "hidden" : ""
                            }`}
                            onClick={() => navigate(`/orders/${value.id}`)}
                          >
                            Details
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="7">
                        <div className="flex flex-col items-center justify-center py-8">
                          <p className="text-gray-400 text-lg">
                            No orders found
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            ) : (
              <>
                {/* loading skeleton */}
                <LoadingLayout type="list" />
              </>
            )}
            {/* table footer */}
            <div className="flex w-full px-4 py-4 border-t-2">
              <div className="flex flex-row justify-between items-center w-full">
                <div>
                  <p>
                    Showing{" "}
                    <span className="font-bold">{page * limit + 1}</span> -{" "}
                    <span className="font-bold">
                      {page * limit + orders.length}
                    </span>{" "}
                    of <span className="font-bold">{total}</span> results
                  </p>
                </div>
                <div className="btn-group">
                  <button
                    className="btn btn-outline btn-sm"
                    disabled={page === 0 || maxPage <= 1}
                    onClick={() => prevPage()}
                  >
                    Prev
                  </button>
                  <button
                    className="btn btn-outline btn-sm"
                    disabled={page === maxPage - 1 || maxPage <= 1}
                    onClick={() => nextPage()}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </UserLayout>
  );
}

// For viewing individual order details
function OrderDetails(props) {
  const { id, isAdmin } = props;

  const [canReady, setCanReady] = useState(false);
  const [canComplete, setCanComplete] = useState(false);

  const navigate = useNavigate();

  const { data, refetch } = useQuery(ORDER_INFO, {
    onCompleted: (information) => {
      if (information.order.status === "APPROVED") {
        setCanReady(true);
        setCanComplete(true);
      } else if (information.order.status === "READY") {
        setCanComplete(true);
        setCanReady(false);
      } else {
        setCanReady(false);
        setCanComplete(false);
      }
    },
    onError: (error) => {
      toast.error(error.message);
      navigate(-1);
    },
    variables: {
      orderId: id,
    },
    fetchPolicy: "network-only",
  });

  const [readyEntity, { loading: readying }] = useMutation(READY_ENTITY, {
    onError: (error) => {
      console.error(error);
      toast.error(error.message);
    },
    onCompleted: () => {
      toast.success("Status updated successfully");
      refetch();
    },
  });

  const [completeEntity, { loading: completing }] = useMutation(
    COMPLETE_ENTITY,
    {
      onError: (error) => {
        console.error(error);
        toast.error(error.message);
      },
      onCompleted: () => {
        toast.success("Status updated successfully");
        refetch();
      },
    }
  );

  const handleReadyRequest = () => {
    window.confirm("Are you sure you want to mark this order as READY?") &&
      readyEntity({ variables: { type: "ORDER", entityId: id, order: {} } });
  };
  const handleCompleteRequest = () => {
    window.confirm("Are you sure you want to mark this order as FULFILLED?") &&
      completeEntity({ variables: { type: "ORDER", entityId: id } });
  };

  return (
    <UserLayout title={`Order Details`}>
      <section>
        <SectionHead heading="Order Details">
          {/* ready order */}
          {canReady && (
            <button
              className={clsx(
                "btn btn-outline btn-primary",
                readying && "loading"
              )}
              onClick={handleReadyRequest}
            >
              Ready Order
            </button>
          )}
          {/* complete order */}
          {canComplete && (
            <button
              className={clsx(
                "btn btn-outline btn-success",
                completing && "loading"
              )}
              onClick={handleCompleteRequest}
            >
              Complete Order
            </button>
          )}
          {/* Back to Orders */}
          <button className="btn btn-outline" onClick={() => navigate(-1)}>
            Back
          </button>
        </SectionHead>
      </section>
      {/* overview section */}
      {data && data.order && <OverviewSection data={data.order} />}
      {/* items */}
      {data && data.order && data.order.items && (
        <ItemsList items={data.order.items} />
      )}
      {/* transaction section */}
      {data && data.order && data.order.status !== "DRAFT" && (
        <TransactionSection
          id={id}
          paymentRefundAllowed={data.order.status === "APPROVED" && isAdmin}
        />
      )}
    </UserLayout>
  );
}
