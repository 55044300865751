import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { CREATE_HALL, UPDATE_HALL } from "../../utils/mutations";
import { LIST_LOCATIONS, HALL_INFO, LIST_HALLS } from "../../utils/queries";
import clsx from "clsx";
import toast from "react-hot-toast";

export const HallForm = (props) => {
  const { id } = props;

  const [locations, setLocations] = useState([]);

  const navigate = useNavigate();

  const { register, setValue, handleSubmit } = useForm();

  useQuery(LIST_LOCATIONS, {
    onCompleted: (data) => {
      setLocations(data.locations);
    },
    onError: (error) => {
      console.error(error);
      toast.error("Error fetching locations!");
    },
  });

  const [prefill] = useLazyQuery(HALL_INFO, {
    variables: { hallId: id },
    onCompleted: (data) => {
      setValue("hall.name", data.hall.name);
      setValue("hall.location", data.hall.location.id);
      setValue("hall.capacity", data.hall.capacity);
      setValue("hall.price", data.hall.price / 100);
      setValue("hall.deposit", data.hall.deposit / 100);
      setValue("hall.from", data.hall.from);
      setValue("hall.to", data.hall.to);
      setValue("hall.days", data.hall.days);
      setValue("hall.status", data.hall.status);
    },
    onError: (error) => {
      console.error(error);
      toast.error("Error fetching hall info!");
    },
  });

  const [createHall, { loading: creating }] = useMutation(CREATE_HALL, {
    onCompleted: (data) => {
      if (data.created) {
        toast.success("Hall created successfully!");
        navigate(-1);
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error("Error creating hall!");
    },
    refetchQueries: [{ query: LIST_HALLS }],
  });

  const [updateHall, { loading: updating }] = useMutation(UPDATE_HALL, {
    onCompleted: (data) => {
      if (data.updated) {
        toast.success("Hall updated successfully!");
        navigate(-1);
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error("Error updating hall!");
    },
    refetchQueries: [{ query: LIST_HALLS }],
  });

  const onSubmit = (data) => {
    const { name, location, capacity, price, deposit, from, to, days, status } =
      data.hall;

    if (id) {
      updateHall({
        variables: {
          input: {
            id,
            name,
            locationId: location,
            capacity: parseInt(capacity),
            price: parseInt(parseFloat(price).toFixed(2)) * 100,
            deposit: parseInt(parseFloat(deposit).toFixed(2)) * 100,
            from,
            to,
            days,
            status,
          },
        },
      });
    } else {
      createHall({
        variables: {
          input: {
            name,
            locationId: location,
            capacity: parseInt(capacity),
            price: parseInt(parseFloat(price).toFixed(2)) * 100,
            deposit: parseInt(parseFloat(deposit).toFixed(2)) * 100,
            from,
            to,
            days,
            status,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (id) prefill();
  }, [id, prefill]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* name */}
        <div>
          <div>
            <label className="label">
              <span className="label-text font-semibold">Name</span>
            </label>
            <input
              type="text"
              name="name"
              placeholder="Hall name"
              className="input input-bordered w-full max-w-md bg-white"
              {...register("hall.name")}
              required
            />
          </div>
        </div>
        {/* select location */}
        <div className="pt-4">
          <label className="label">
            <span className="label-text font-semibold">Location</span>
          </label>
          <select
            className="select select-bordered w-full max-w-md bg-white"
            {...register("hall.location")}
          >
            <option value="">Select location</option>
            {locations.map((location) => (
              <option key={location.id} value={location.id}>
                {location.name}
              </option>
            ))}
          </select>
        </div>
        {/* capacity */}
        <div className="pt-4">
          <label className="label">
            <span className="label-text font-semibold">Capacity</span>
          </label>
          <input
            type="number"
            name="capacity"
            placeholder="Hall capacity"
            className="input input-bordered w-full max-w-md bg-white"
            {...register("hall.capacity")}
            required
            step={1}
            min={1}
          />
        </div>
        {/* price */}
        <div className="pt-4">
          <label className="label">
            <span className="label-text font-semibold">Price (per hour)</span>
          </label>
          <input
            type="number"
            name="price"
            placeholder="Hall price"
            className="input input-bordered w-full max-w-md bg-white"
            {...register("hall.price")}
            required
            step={0.01}
            min={0}
          />
        </div>
        {/* deposit */}
        <div className="pt-4">
          <label className="label">
            <span className="label-text font-semibold">Deposit</span>
          </label>
          <input
            type="number"
            name="deposit"
            placeholder="Hall deposit"
            className="input input-bordered w-full max-w-md bg-white"
            {...register("hall.deposit")}
            required
            step={0.01}
            min={0}
          />
        </div>
        <div className="pt-4 flex flex-row max-w-md gap-2">
          {/* time from (ex. 08:00) */}
          <div className="basis-1/2">
            <label className="label">
              <span className="label-text font-semibold">From</span>
            </label>
            <input
              type="time"
              name="from"
              className="input input-bordered w-full max-w-md bg-white"
              {...register("hall.from")}
              required
            />
          </div>
          {/* time to (ex. 18:00) */}
          <div className="basis-1/2">
            <label className="label">
              <span className="label-text font-semibold">To</span>
            </label>
            <input
              type="time"
              name="to"
              className="input input-bordered w-full max-w-md bg-white"
              {...register("hall.to")}
              required
            />
          </div>
        </div>
        {/* multiple select days of week */}
        <div>
          <label className="label">
            <span className="label-text font-semibold">Days of week</span>
          </label>
          <select
            className="select select-bordered w-full max-w-md bg-white"
            {...register("hall.days")}
            required
            multiple
          >
            <option value="Monday">Monday</option>
            <option value="Tuesday">Tuesday</option>
            <option value="Wednesday">Wednesday</option>
            <option value="Thursday">Thursday</option>
            <option value="Friday">Friday</option>
            <option value="Saturday">Saturday</option>
            <option value="Sunday">Sunday</option>
          </select>
        </div>
        {/* status (select) */}
        <div className="pt-4">
          <label className="label">
            <span className="label-text font-semibold">Status</span>
          </label>
          <select
            name="status"
            className="select select-bordered w-full max-w-md bg-white"
            {...register("hall.status")}
          >
            <option value="ACTIVE">Active</option>
            <option value="INACTIVE">Inactive</option>
          </select>
        </div>
        {/* submit button */}
        <div className="pt-4">
          <button
            type="submit"
            className={clsx(creating || updating ? "btn loading" : "btn")}
          >
            {id ? "Update" : "Create"}
          </button>
        </div>
      </form>
    </div>
  );
};
