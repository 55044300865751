import { LIST_CUSTOMERS } from "../../utils/queries";
import { useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { UpdateModal } from "../modal/Update";
import { CustomerForm } from "../form/Customer";
import { formatMobile } from "../../utils/helpers";

export const CustomersTable = (props) => {
  const { term, formatted } = props;

  const [update, setUpdate] = useState(null);
  const [selected, setSelected] = useState(null);

  const [results, setResults] = useState([]);
  const [response, setResponse] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [resultsPerPage] = useState(10);
  const [list, setList] = useState([]);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(resultsPerPage);

  const notifyError = () => toast.error("Something went wrong!");
  const notifyLoading = () => toast.loading("Loading...");

  const { loading } = useQuery(LIST_CUSTOMERS, {
    onCompleted: (data) => {
      if (data) {
        setResults(data.customers);
        setResponse(data.customers);
        formatted({
          title: "Customers",
          label: "customers",
          list: data.customers.map((customer) => ({
            Email: customer.email,
            Phone: formatMobile(customer.phone),
            Street: customer.street || "",
            Suburb: customer.suburb || "",
            Postcode: customer.postcode || "",
            Students: customer.students,
            Registered: customer.registered,
          })),
        });
      }
    },
    onError: (error) => {
      if (error) {
        notifyError();
      }
    },
  });

  const action = (id, email) => {
    setSelected({ id, email });
    setUpdate(id);
  };

  const sliceData = (data, page, rows) => {
    setFrom(data.length > 0 ? (page - 1) * rows + 1 : 0);
    setTo(page * rows > data.length ? data.length : page * rows);
    return data.slice((page - 1) * rows, page * rows);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  useEffect(() => {
    if (loading) {
      notifyLoading();
    } else {
      toast.dismiss();
    }
  }, [loading]);

  useEffect(() => {
    if (term) {
      setResults(
        response.filter((data) =>
          data.email.toLowerCase().includes(term.toLowerCase())
        )
      );
      setCurrentPage(1);
    } else {
      setResults(response);
    }
  }, [term, response]);

  useEffect(() => {
    if (results) setTotalPages(Math.ceil(results.length / resultsPerPage));
  }, [results, resultsPerPage]);

  useEffect(() => {
    const slice = sliceData(results, currentPage, resultsPerPage);
    setList([...slice]);
  }, [results, currentPage, setList, resultsPerPage]);

  return (
    <div>
      <div className="overflow-x-auto rounded-xl shadow-md">
        <table className="table-normal divide-y-2 bg-white rounded-xl w-full">
          {/* head */}
          <thead className="text-left">
            <tr>
              <th></th>
              <th>Email</th>
              <th>Phone</th>
              <th>Address</th>
              <th>Students</th>
              <th>Registered</th>
            </tr>
          </thead>
          <tbody className="divide-y-2">
            {list &&
              list.map((value, index) => (
                <tr key={value.id}>
                  <th>{from + index}</th>
                  <td className="font-bold">{value.email}</td>
                  <td>{value.phone}</td>
                  <td>{value.address}</td>
                  <td>{value.students}</td>
                  <td>{value.registered}</td>
                  <td>
                    <label
                      htmlFor="update-modal"
                      className="btn btn-xs btn-ghost"
                      onClick={() => action(value.id, value.email)}
                    >
                      Update Email
                    </label>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {/* table footer */}
      <div className="flex w-full px-2 py-4">
        {results && (
          <div className="flex flex-row justify-between items-center w-full">
            <div>
              <p>
                Showing <span className="font-bold">{from}</span> -{" "}
                <span className="font-bold">{to}</span> of{" "}
                <span className="font-bold">{results.length}</span> results
              </p>
            </div>
            <div className="btn-group">
              <button
                className="btn btn-outline btn-sm"
                disabled={currentPage === 1 || totalPages === 0}
                onClick={() => prevPage()}
              >
                Prev
              </button>
              <button
                className="btn btn-outline btn-sm"
                disabled={currentPage === totalPages || totalPages === 0}
                onClick={() => nextPage()}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
      <UpdateModal
        title="Update customer email"
        content="This will update the existing customer email"
        btn="btn btn-sm btn-outline hidden"
        label={"Update"}
      >
        {update && <CustomerForm customer={selected} />}
      </UpdateModal>
    </div>
  );
};
