import { useEffect, useContext } from "react";
import { AuthContext } from "../context/authContext";
import { useParams } from "react-router-dom";
import { UserLayout } from "../layout/User";
import { SectionHead } from "../library/section/Head";
import { TEACHER_DETAILS, LIST_TEACHERS } from "../utils/queries";
import { DEACTIVATE_TEACHER, ACTIVATE_TEACHER } from "../utils/mutations";
import { useQuery, useMutation } from "@apollo/client";
import { TeacherDetails } from "../library/details/Teacher";
import { TimesheetTable } from "../library/table/Timesheet";
import toast from "react-hot-toast";

function Teacher(props) {
  const { id } = useParams();

  const { isAdmin, isManager } = useContext(AuthContext);

  const notifyError = () => toast.error("Something went wrong!");
  const notifySuccess = () => toast.success("Information updated!");

  const { data, error } = useQuery(TEACHER_DETAILS, {
    variables: { teacherId: id },
  });

  const [deactivateTeacher, { data: deactivateData, error: deactivateError }] =
    useMutation(DEACTIVATE_TEACHER, {
      refetchQueries: [
        { query: TEACHER_DETAILS, variables: { teacherId: id } },
        { query: LIST_TEACHERS },
      ],
    });

  const [activateTeacher, { data: activateData, error: activateError }] =
    useMutation(ACTIVATE_TEACHER, {
      refetchQueries: [
        { query: TEACHER_DETAILS, variables: { teacherId: id } },
        { query: LIST_TEACHERS },
      ],
    });

  const deactivate = () => {
    if (
      window.confirm(`Are you sure you want to mark this teacher as inactive?`)
    ) {
      deactivateTeacher({ variables: { teacherId: id } });
    }
  };

  const activate = () => {
    if (window.confirm(`Are you sure you want mark this teacher as active?`)) {
      activateTeacher({ variables: { teacherId: id } });
    }
  };

  useEffect(() => {
    if (error) notifyError();
  }, [error]);

  useEffect(() => {
    if (deactivateError) notifyError();
  }, [deactivateError]);

  useEffect(() => {
    if (activateError) notifyError();
  }, [activateError]);

  useEffect(() => {
    if (deactivateData) notifySuccess();
  }, [deactivateData]);

  useEffect(() => {
    if (activateData) notifySuccess();
  }, [activateData]);

  return (
    <UserLayout title="Teacher Details">
      <section>
        <SectionHead heading="Teacher Details">
          {data?.details?.status === "ACTIVE" && (
            <button
              className="btn btn-outline"
              onClick={() => {
                deactivate();
              }}
            >
              Set as Inactive
            </button>
          )}
          {data?.details?.status === "INACTIVE" && (
            <button
              className="btn btn-outline"
              onClick={() => {
                activate();
              }}
            >
              Set as Active
            </button>
          )}
        </SectionHead>
      </section>
      {data?.details && <TeacherDetails details={data.details} />}
      {(isAdmin || isManager) && data?.details?.timesheet && (
        <TimesheetTable timesheet={data.details.timesheet} />
      )}
    </UserLayout>
  );
}

export default Teacher;
