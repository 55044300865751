import { useState, useEffect } from "react";
import { STUDENT_PROFILE } from "../../utils/queries";
import { useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import { CommentsList } from "../list/Comments";

const BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL;

export const StudentDrawer = (props) => {
  const { studentId, close } = props;

  const [profile, setProfile] = useState(null);

  const notifyError = () => toast.error("Something went wrong!");
  const notifyLoading = () => toast.loading("Loading...");

  const { loading } = useQuery(STUDENT_PROFILE, {
    variables: { studentId },
    onCompleted: (data) => {
      if (data?.profile) {
        setProfile(data.profile);
      }
    },
    onError: (error) => {
      if (error) {
        notifyError();
      }
    },
  });

  useEffect(() => {
    if (loading) notifyLoading();
    if (!loading) toast.dismiss();
  }, [loading]);

  return (
    <>
      {/* window overlay */}
      {studentId && (
        <>
          <div
            className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none bg-black/90"
            onClick={() => close()}
          />
          <div className="fixed bg-white inset-y-0 right-0 w-full max-w-xl mx-auto z-50 overflow-y-scroll">
            <button
              className="absolute top-4 right-4 btn btn-sm btn-circle"
              onClick={() => close()}
            >
              &#x2715;
            </button>
            {/* content */}
            {profile && (
              <div className="min-h-screen">
                <div className="pt-4 flex flex-col max-h-full">
                  {/* top */}
                  <div className="flex flex-row items-center gap-4 px-6 pt-6 pb-4">
                    {profile.student.picture ? (
                      <img
                        src={`${BUCKET_URL}/${profile.student.picture}`}
                        className="w-20 h-20 rounded-full"
                        alt={`${profile.student.name}`}
                      />
                    ) : (
                      <img
                        src={`${BUCKET_URL}/pictures/placeholder.jpg`}
                        className="w-20 h-20 rounded-full"
                        alt={`${profile.student.name}`}
                      />
                    )}
                    <div className="flex flex-col gap-1">
                      <h2 className="font-semibold text-2xl">
                        {getFullName(
                          profile.student.firstName,
                          profile.student.middleName,
                          profile.student.lastName
                        )}
                      </h2>
                      <p>
                        {profile.student.gender}, born on {profile.student.dob}
                      </p>
                    </div>
                  </div>
                  {/* student info */}
                  <div className="px-6 py-6 border-b-2">
                    <div className="divide-y">
                      <p className="py-1">
                        <span className="font-semibold">Email: </span>{" "}
                        {profile.customer.email}
                      </p>
                      <p className="py-1">
                        <span className="font-semibold">Phone: </span>
                        {profile.customer.phone}
                      </p>
                      <p className="py-1">
                        <span className="font-semibold">Street: </span>
                        {profile.customer.street}
                      </p>
                      <p className="py-1">
                        <span className="font-semibold">Suburb: </span>
                        {profile.customer.suburb}
                      </p>
                      <p className="py-1">
                        <span className="font-semibold">Postcode: </span>
                        {profile.customer.postcode}
                      </p>
                    </div>
                  </div>
                  {/* notes */}
                  <div className="px-6 py-6 border-b-2">
                    <h3 className="font-medium label-text">Notes:</h3>
                    <p>{profile.student.notes || "-"}</p>
                  </div>
                  {/* enrolments */}
                  <div className="px-6 py-6 border-b-2">
                    <h3 className="font-medium mb-4 label-text">
                      Enrolment History
                    </h3>
                    <div className="flex flex-col divide-y">
                      {profile.enrolments.length > 0 ? (
                        profile.enrolments.map((enrolment) => (
                          <div
                            key={enrolment.id}
                            className="py-2 flex flex-row justify-between items-start"
                          >
                            <div>
                              <h4 className="font-bold">
                                {enrolment.class.name}
                              </h4>
                              <p>{enrolment.created}</p>
                              {enrolment.status === "APPROVED" && (
                                <p>
                                  <span className="font-semibold">
                                    Attendance:{" "}
                                  </span>{" "}
                                  {enrolment.attendance}%
                                </p>
                              )}
                            </div>
                            <div className="text-right">
                              <span
                                className={`text-xs py-1 px-2 rounded-md text-white ${paymentTagColor(
                                  enrolment.status
                                )}`}
                              >
                                {formatLabel(enrolment.status)}
                              </span>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p className="text-center text-sm">
                          Has no enrolments.
                        </p>
                      )}
                    </div>
                  </div>
                  {/* comments */}
                  <div className="px-6 py-6">
                    <h3 className="font-medium mb-4 label-text">Comments</h3>
                    <CommentsList
                      type="Student"
                      id={studentId}
                      commenting={true}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

const getFullName = (first, middle, last) => {
  let fullName = `${first} ${last}`;
  if (middle) {
    fullName = `${first} ${middle} ${last}`;
  }
  return fullName;
};

function formatLabel(str) {
  // replace all _ with space
  return str.replace(/_/g, " ");
}

function paymentTagColor(str) {
  switch (str) {
    case "APPROVED":
      return `bg-info`;
    default:
      return `bg-gray-200`;
  }
}
