import { useContext } from "react";
import { AuthContext } from "../context/authContext";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import { UserLayout } from "../layout/User";
import { SectionHead } from "../library/section/Head";
import { ClassForm } from "../library/form/Class";
import { TermForm } from "../library/form/Term";
import { ProgramForm } from "../library/form/Program";
import { GroupForm } from "../library/form/Group";
import { LocationForm } from "../library/form/Location";
import { TeacherForm } from "../library/form/Teacher";
import { TemplateForm } from "../library/form/Template";
import { UserForm } from "../library/form/User";
import { HallForm } from "../library/form/Hall";
import { ProductForm } from "../library/form/Product";

function Create(props) {
  const { type } = useParams();

  const { isAdmin } = useContext(AuthContext);

  const navigate = useNavigate();

  const basedOnType = (param) => {
    switch (type) {
      case "class":
        return <ClassForm />;
      case "term":
        return <TermForm />;
      case "program":
        return <ProgramForm />;
      case "group":
        return <GroupForm />;
      case "location":
        return <LocationForm />;
      case "teacher":
        return <TeacherForm />;
      case "template":
        return <TemplateForm />;
      case "user":
        if (!isAdmin) return <Navigate to="/" />;
        return <UserForm />;
      case "hall":
        return <HallForm />;
      case "product":
        return <ProductForm />;
      default:
        return <Navigate to={-1} />;
    }
  };

  return (
    <UserLayout title={`Create ${type}`}>
      <section>
        <SectionHead heading={`Create ${type}`}>
          <button className="btn btn-outline" onClick={() => navigate(-1)}>
            Back
          </button>
        </SectionHead>
        {basedOnType(type)}
      </section>
    </UserLayout>
  );
}

export default Create;
