import { useState, useEffect } from "react";
import {
  useForm,
  useFormState,
  useWatch,
  useFieldArray,
} from "react-hook-form";
import toast from "react-hot-toast";
import { useMutation, useLazyQuery } from "@apollo/client";
import { ACCEPT_ENTITY } from "../../utils/mutations";
import { GET_HALL_AVAILABILITY_SLOTS } from "../../utils/queries";
import { toAmPm } from "../../utils/helpers";
import { HallSelector } from "../selector/Hall";

export const AcceptForm = (props) => {
  const { id, type, refetch } = props;

  const [acceptRequest, { loading }] = useMutation(ACCEPT_ENTITY, {
    onCompleted: () => {
      toast.success("Request accepted successfully!");
      refetch();
    },
    onError: (error) => {
      console.error(error);
      toast.error("Something went wrong!");
    },
  });

  return (
    <section className="mt-4">
      <div className="label">
        <span className="label-text font-semibold">Accept Request</span>
      </div>
      <div className="rounded-xl shadow-md p-6 bg-white">
        {type === "PERFORMANCE" && (
          <AcceptPerformanceForm
            id={id}
            type={type}
            accept={acceptRequest}
            loading={loading}
          />
        )}
        {type === "TRAINING" && (
          <AcceptTrainingForm
            id={id}
            type={type}
            accept={acceptRequest}
            loading={loading}
          />
        )}
      </div>
    </section>
  );
};

function AcceptPerformanceForm(props) {
  const { id, type, accept, loading } = props;

  const { control, register, handleSubmit, watch } = useForm({
    mode: "onChange",
  });

  const showReference = watch("performance.method") === "MANUAL";

  const { isValid } = useFormState({ control });

  const onSubmit = (data) => {
    const { total, method, ref } = data.performance;

    const input = {
      type,
      entityId: id,
      performance: {
        total: parseInt(total * 100),
        method: data.performance.method,
        ...(method === "MANUAL" && { ref }),
      },
    };

    accept({ variables: { ...input } });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-full flex flex-col gap-4"
    >
      {/* total */}
      <div>
        <label className="label">
          <span className="label-text font-semibold">Total Amount ($)</span>
        </label>
        <input
          type="number"
          name="total"
          placeholder="Total Amount"
          className="input input-bordered w-full bg-white"
          {...register("performance.total", {
            required: true,
            min: 1,
          })}
          defaultValue={0}
          min={0}
          step={1}
        />
      </div>
      {/* payment method */}
      <div>
        <label className="label">
          <span className="label-text font-semibold">Payment Method</span>
        </label>
        <select
          className="select select-bordered w-full bg-white"
          {...register("performance.method", {
            required: true,
            validate: (value) => {
              return !!value;
            },
          })}
          defaultValue={""}
        >
          <option value="">Select a payment method</option>
          <option value="MANUAL">
            Manual / Bank Transfer / EFTPOS / Other
          </option>
          <option value="STRIPE">
            Online (customer will see this under pending and pay online)
          </option>
        </select>
      </div>
      {/* ref (input) */}
      {showReference && (
        <div>
          <label className="label">
            <span className="label-text font-semibold">Payment Reference</span>
          </label>
          <input
            type="text"
            name="ref"
            placeholder="Reference Number"
            className="input input-bordered w-full bg-white"
            {...register("training.ref", {
              required: true,
            })}
          />
        </div>
      )}
      {/* submit */}
      <button
        type="submit"
        className="btn btn-primary w-full"
        disabled={loading || !isValid}
      >
        {loading ? "Loading..." : "Accept Request"}
      </button>
    </form>
  );
}

function AcceptTrainingForm(props) {
  const { id, type, accept, loading } = props;

  const tomorrowsDate = new Date(new Date().setDate(new Date().getDate() + 1))
    .toISOString()
    .split("T")[0];

  const twoMonthsFromNowDate = new Date(
    new Date().setMonth(new Date().getMonth() + 2)
  )
    .toISOString()
    .split("T")[0];

  const [hall, setHall] = useState(null);
  const [hallDisabled, setHallDisabled] = useState(false);
  const [slots, setSlots] = useState({});
  const [allowScheduling, setAllowScheduling] = useState(false);
  const [scheduling, setScheduling] = useState(false);
  const [validationDates, setValidationDates] = useState([]);
  const [allEntered, setAllEntered] = useState(false);

  const { control, register, handleSubmit, watch } = useForm({
    mode: "onChange",
  });

  const { isValid } = useFormState({ control });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "training.schedule",
    rules: { required: true, minLength: 1, maxLength: 12 },
  });

  const showReference = watch("training.method") === "MANUAL";

  const sessionsEntered = useWatch({ control, name: "training.sessions" });
  const durationEntered = useWatch({ control, name: "training.duration" });
  const scheduleEntered = useWatch({ control, name: "training.schedule" });

  const scheduleFields = watch("training.schedule");

  const [getAvailableSlots] = useLazyQuery(GET_HALL_AVAILABILITY_SLOTS, {
    onCompleted: (data) => {
      if (data && data.slots.length > 0) {
        const date = data.slots[0].date;
        setSlots((prev) => ({
          ...prev,
          [date]: data.slots,
        }));
      } else {
        toast.error("No timeslots available for the given date.");
      }
    },
    onError: () => {
      toast.error(
        "No timeslots available for the given date. Please try another date."
      );
    },
    fetchPolicy: "network-only",
  });

  const updateAvailableSlots = (date) => {
    getAvailableSlots({
      variables: {
        hallId: hall.id,
        date,
        duration: parseInt(durationEntered),
      },
    });
  };

  const setScheduleFields = () => {
    remove();

    const obj = { date: "", time: "" };

    const arr = Array.from({ length: sessionsEntered }, () => obj);

    append(arr);
    setScheduling(true);
  };

  const resetScheduleFields = () => {
    remove();
    setScheduling(false);
    setSlots({});
  };

  const onSubmit = (data) => {
    const { total, method, ref } = data.training;

    const schedule = data.training.schedule.map((v) => {
      const { date, time: id } = v;
      // based on the id, get the slot
      const slot = slots[date].find((slot) => slot.id === id);

      return { from: slot.from, to: slot.to };
    });

    const input = {
      type,
      entityId: id,
      training: {
        hallId: hall.id,
        duration: parseInt(durationEntered),
        schedule: schedule,
        total: parseInt(total * 100),
        method: method,
        ...(method === "MANUAL" && { ref }),
      },
    };

    accept({ variables: { ...input } });
  };

  useEffect(() => {
    if (!!sessionsEntered && !!durationEntered) {
      setAllowScheduling(true);
    } else {
      setAllowScheduling(false);
    }
  }, [sessionsEntered, durationEntered]);

  useEffect(() => {
    if (scheduling) setHallDisabled(true);
    else setHallDisabled(false);
  }, [scheduling, hallDisabled]);

  useEffect(() => {
    if (scheduleEntered?.length > 0) {
      const dates = scheduleEntered.map((schedule, i) => {
        const dateSet = !!schedule.date ? new Date(schedule.date) : new Date();
        !!schedule.date
          ? dateSet.setDate(dateSet.getDate() + 1)
          : dateSet.setDate(dateSet.getDate() + (i + 2));
        return dateSet.toISOString().split("T")[0];
      });

      setValidationDates(dates);
    }
  }, [scheduleEntered]);

  useEffect(() => {
    if (durationEntered && sessionsEntered) {
      const check = (field) => !!field.date && !!field.time;
      if (scheduleEntered?.length > 0) {
        setAllEntered(scheduleEntered.every(check));
      } else setAllEntered(false);
    } else {
      setAllEntered(false);
    }
  }, [durationEntered, sessionsEntered, scheduleEntered]);

  return (
    <>
      <HallSelector
        label="Select Hall"
        selection={hall}
        setSelection={setHall}
        showPricing={false}
        disable={hallDisabled}
      />
      {hall && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full flex flex-col gap-4"
        >
          <div className="border-b-2 pb-4 flex gap-4">
            {/* No. of sessions */}
            <div className="basis-1/2">
              <label className="label">
                <span className="label-text font-semibold">
                  No. of Sessions
                </span>
              </label>
              <input
                type="number"
                className="input input-bordered w-full bg-white"
                min={1}
                max={10}
                disabled={scheduling}
                placeholder="Enter no. of sessions"
                {...register("training.sessions", {
                  required: true,
                  min: 1,
                  max: 10,
                })}
              />
            </div>
            {/* duration */}
            <div className="basis-1/2">
              <label className="label">
                <span className="label-text font-semibold">
                  Session Duration
                </span>
              </label>
              <select
                className="select select-bordered w-full bg-white"
                disabled={scheduling}
                {...register("training.duration", { required: true })}
              >
                <option value="">Select duration</option>
                <option value={30}>30 mins.</option>
                <option value={60}>1 hr.</option>
                <option value={90}>1 hr. 30 mins.</option>
                <option value={120}>2 hrs.</option>
                <option value={150}>2 hrs. 30 mins.</option>
                <option value={180}>3 hrs.</option>
              </select>
            </div>
          </div>
          {/* repeating select slot */}
          {!!durationEntered && (
            <li className="list-none">
              {fields.map((field, index) => (
                <ul key={field.id} className="flex flex-col pt-2 pb-4">
                  <label className="label">
                    <span className="label-text font-semibold">
                      {index + 1}: Select Date & Time
                    </span>
                  </label>
                  <div className="flex flex-col md:flex-row gap-4">
                    <div className="basis-1/2">
                      <input
                        type="date"
                        min={
                          index === 0
                            ? tomorrowsDate
                            : validationDates[index - 1]
                        }
                        max={twoMonthsFromNowDate}
                        className="input input-bordered w-full bg-white"
                        {...register(`training.schedule.${index}.date`, {
                          onChange: (e) =>
                            updateAvailableSlots(e.target.value, index),
                          required: true,
                        })}
                      />
                    </div>
                    <div className="basis-1/2">
                      <select
                        className="select select-bordered w-full bg-white"
                        {...register(`training.schedule.${index}.time`, {
                          required: true,
                        })}
                      >
                        <option value="">Select time</option>
                        {slots[scheduleFields[index]?.date]?.map((slot) => (
                          <option key={slot.id} value={slot.id}>
                            {toAmPm(slot.start)} - {toAmPm(slot.end)}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </ul>
              ))}
            </li>
          )}
          {!scheduling ? (
            <button
              className="btn btn-outline"
              disabled={!allowScheduling}
              onClick={() => setScheduleFields()}
            >
              Set Schedule
            </button>
          ) : (
            <button
              className="btn btn-outline"
              onClick={() => resetScheduleFields()}
            >
              Clear Schedule
            </button>
          )}
          {/* total */}
          <div>
            <label className="label">
              <span className="label-text font-semibold">Total Amount ($)</span>
            </label>
            <input
              type="number"
              name="total"
              placeholder="Total Amount"
              className="input input-bordered w-full bg-white"
              {...register("training.total", {
                required: true,
                min: 1,
              })}
              defaultValue={0}
              min={0}
              step={1}
            />
          </div>
          {/* payment method */}
          <div>
            <label className="label">
              <span className="label-text font-semibold">Payment Method</span>
            </label>
            <select
              className="select select-bordered w-full bg-white"
              {...register("training.method", {
                required: true,
                validate: (value) => {
                  return !!value;
                },
              })}
              defaultValue={""}
            >
              <option value="">Select a payment method</option>
              <option value="MANUAL">
                Manual / Bank Transfer / EFTPOS / Other
              </option>
              <option value="STRIPE">
                Online (customer will see this under pending and pay online)
              </option>
            </select>
          </div>
          {/* ref (input) */}
          {showReference && (
            <div>
              <label className="label">
                <span className="label-text font-semibold">
                  Payment Reference
                </span>
              </label>
              <input
                type="text"
                name="ref"
                placeholder="Reference Number"
                className="input input-bordered w-full bg-white"
                {...register("training.ref", {
                  required: true,
                })}
              />
            </div>
          )}
          {/* submit */}
          <button
            type="submit"
            className="btn btn-primary w-full"
            disabled={loading || !isValid || !allEntered}
          >
            {loading ? "Loading..." : "Accept Request"}
          </button>
        </form>
      )}
    </>
  );
}
