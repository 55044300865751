import { formatter } from "../../utils/helpers";

const BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL;

export const ItemsList = (props) => {
  const { items } = props;

  return (
    <section className="mt-4">
      <div className="label">
        <span className="label-text font-semibold">Items</span>
      </div>
      <div className="bg-white rounded-xl p-4 divide-y">
        {items.length > 0 ? (
          items.map((item) => (
            <div
              key={item.product.id}
              className="flex flex-col md:flex-row md:items-center justify-between gap-4 p-2"
            >
              <div className="flex items-center gap-4 w-full">
                <img
                  src={`${BUCKET_URL}/${item.product.thumbnail}`}
                  alt={item.product.name}
                  className="w-16 h-16 rounded-md"
                />
                <div className="flex flex-col md:flex-row gap-2 flex-grow justify-between">
                  <div>
                    <h3 className="text-lg font-medium">{item.product.name}</h3>
                  </div>
                  <div className="flex items-center gap-4">
                    <p className="text-sm text-gray-900 font-medium">
                      Price: {formatter.format(item.price / 100)} | Qty:{" "}
                      {item.quantity} | Total:{" "}
                      {formatter.format((item.price * item.quantity) / 100)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center text-gray-500">Items not found</p>
        )}
      </div>
    </section>
  );
};
