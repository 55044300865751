import { useContext } from "react";
import { AuthContext } from "../../context/authContext";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { ADD_COMMENT } from "../../utils/mutations";
import { LIST_COMMENTS } from "../../utils/queries";
import toast from "react-hot-toast";

export const CommentForm = (props) => {
  const { type, id, reply, reset: onReset } = props;

  const { user } = useContext(AuthContext);

  const { register, handleSubmit, reset } = useForm();

  const [addComment] = useMutation(ADD_COMMENT, {
    onCompleted: () => {
      reset();
      if (reply.id) onReset();
    },
    onError: () => {
      toast.error("Error adding comment");
    },
    refetchQueries: [
      {
        query: LIST_COMMENTS,
        variables: { entity: type, ref: id },
      },
    ],
  });

  const onSubmit = (data) => {
    const { text, visibility } = data.comment;

    addComment({
      variables: {
        input: {
          entity: type,
          ref: id,
          text,
          from: user.data.id,
          to: reply.id ? reply.id : null,
          visibility,
        },
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mb-4 space-y-2">
      {/* show reply (if replying) */}
      {reply.id && (
        <div className="flex flex-row items-center gap-2">
          <div className="flex flex-row w-full">
            <div className="flex flex-col gap-1 w-full">
              <div className="text-sm text-gray-500">Replying to</div>
              <div className="text-sm text-gray-700">
                <h5 className="font-semibold">
                  {reply.from &&
                    `${reply.from?.username} (${reply.from?.email})`}
                </h5>
                <p className="line-clamp-3">{reply.text && reply.text}</p>
              </div>
            </div>
            <div>
              <button
                className="btn btn-ghost btn-xs"
                onClick={() => onReset()}
              >
                &#10005;
              </button>
            </div>
          </div>
        </div>
      )}

      {/* comment */}
      <div className="flex flex-col gap-2">
        <textarea
          {...register("comment.text", {
            required: true,
            minLength: 1,
            maxLength: 500,
          })}
          className="w-full border border-gray-300 rounded-md px-4 py-3"
          placeholder="Leave a comment"
          rows={reply.id ? 2 : 4}
        />
      </div>
      {/* select visibility */}
      <div className="flex flex-row gap-2">
        <select
          {...register("comment.visibility")}
          className="border border-gray-300 rounded-md py-1 w-44"
          defaultValue={"STAFF"}
        >
          {/* <option value="EVERYONE">Everyone</option> */}
          <option value="STAFF">Staff</option>
          <option value="ADMIN">Admin</option>
        </select>
        {/* submit */}
        <button
          type="submit"
          className="uppercase text-xs tracking-wider font-semibold inline-block px-4 py-2 rounded-md bg-black text-white"
        >
          {reply.id ? "reply" : "comment"}
        </button>
      </div>
    </form>
  );
};
