import { Link } from "react-router-dom";
import { ChatBubbleLeftEllipsisIcon as ChatAltIcon } from "@heroicons/react/20/solid";

export const ClassesGrid = (props) => {
  const { schedule } = props.schedule;

  return (
    <>
      <label className="label">
        <span className="label-text font-semibold">Classes</span>
      </label>
      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 pb-6">
        {schedule.length > 0 &&
          schedule.map((data) => (
            <div
              className="card w-full bg-white shadow-md hover:bg-secondary/50"
              key={data.id}
            >
              <Link to={`/class/${data.id}`}>
                <div className="card-body justify-between">
                  <div className="flex flex-row gap-4 w-full items-start justify-between">
                    <div>
                      <h3 className="text-xl font-semibold pb-2">
                        {data.name}
                      </h3>
                      <p className="text-sm font-semibold">
                        Every {data.schedule}
                      </p>
                      {data.preassessment && (
                        <p className="text-sm font-bold italic">
                          (pre-assessment required for admission)
                        </p>
                      )}
                    </div>
                    {/* comments count badge */}
                    <div className="bg-gray-50 rounded-full px-2">
                      <p className="text-gray-600 text-sm flex items-center gap-1">
                        <ChatAltIcon className="h-4 w-4" />{" "}
                        <span className="font-semibold font-mono">
                          {data.commentsCount ? data.commentsCount : 0}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center">
                    <div>
                      <p className="text-sm">
                        {data.remaining} sessions available / {data.enrolcount}{" "}
                        enrolled
                      </p>
                      <p className="text-lg font-semibold text-neutral">
                        {formatter.format((data.price * data.remaining) / 100)}
                      </p>
                    </div>
                    <span
                      className={`text-xs py-1 px-2 rounded-md text-white ${statusTagColor(
                        data.status
                      )}`}
                    >
                      {data.status}
                    </span>
                  </div>
                </div>
              </Link>
            </div>
          ))}
      </div>
      {schedule.length < 1 && (
        <div className="py-8">
          <div className="text-center">
            <h3 className="mt-2 text-md font-medium text-gray-900">
              No classes found
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              No available classes found for your selection
            </p>
          </div>
        </div>
      )}
    </>
  );
};

const formatter = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD",
});

function statusTagColor(str) {
  switch (str) {
    case "AVAILABLE":
      return `bg-info`;
    case "ONGOING":
      return `bg-info`;
    case "FULL":
      return `bg-warning`;
    default:
      return `bg-base-300`;
  }
}
