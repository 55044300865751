import { useEffect } from "react";
import { UserLayout } from "../layout/User";
import { SectionHead } from "../library/section/Head";
import { ScheduleFilter } from "../library/form/Schedule";
import { ClassesGrid } from "../library/grid/Classes";
import { ADMIN_SCHEDULE_FILTER } from "../utils/queries";
import { GET_CLASSES } from "../utils/mutations";
import { useQuery, useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

function Schedule(props) {
  const notifyError = () => toast.error("Something went wrong!");

  const { data: filterData, error: filterError } = useQuery(
    ADMIN_SCHEDULE_FILTER
  );

  const [getClassSchedule, { data: scheduleData, error: scheduleError }] =
    useMutation(GET_CLASSES);

  useEffect(() => {
    if (filterData || scheduleData) {
      toast.dismiss();
    }
  }, [filterData, scheduleData]);

  useEffect(() => {
    if (filterError || scheduleError) notifyError();
  }, [filterError, scheduleError]);

  return (
    <UserLayout title="Schedule">
      <section>
        <SectionHead heading="Schedule">
          <Link to="/create/class" className="btn">
            Create new
          </Link>
        </SectionHead>
        <div>
          {filterData && (
            <ScheduleFilter
              filters={filterData.filters}
              populate={getClassSchedule}
            />
          )}
          {scheduleData && <ClassesGrid schedule={scheduleData} />}
        </div>
      </section>
    </UserLayout>
  );
}

export default Schedule;
