import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context/authContext";
import { useParams, useNavigate } from "react-router-dom";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useDebounce } from "@uidotdev/usehooks";
import { CSVLink } from "react-csv";
import toast from "react-hot-toast";
import clsx from "clsx";
import { UserLayout } from "../layout/User";
import { LoadingLayout } from "../layout/Loading";
import { SectionHead } from "../library/section/Head";
import { OverviewSection } from "../library/section/Overview";
import { RostersList } from "../library/list/Rosters";
import { TransactionSection } from "../library/section/Transaction";
import { CustomerSelector } from "../library/selector/Customer";
import { StudentSelector } from "../library/selector/Student";
import { TermSelector } from "../library/selector/Term";
import { PassSelector } from "../library/selector/Pass";
import { ClassSelector } from "../library/selector/Class";
import { ChangePassModal } from "../library/modal/ChangePass";
import { ChangeDropInForm } from "../library/form/ChangeDropIn";
import {
  formatter,
  formatMobile,
  paymentTagColor,
  statusTagColor,
} from "../utils/helpers";
import { DRAFT_ENTITY, USE_ENTITY, CANCEL_ENTITY } from "../utils/mutations";
import { APPLICABLE_SESSIONS, PASSES_LIST, PASS_INFO } from "../utils/queries";

export default function Pass(props) {
  const { param } = useParams();

  const { isAdmin, isManager } = useContext(AuthContext);

  if (param) {
    if (param === "draft") return <PassDraft />;
    return <PassDetail id={param} isAdmin={isAdmin} isManager={isManager} />;
  } else {
    return <PassesList isAdmin={isAdmin} isManager={isManager} />;
  }
}

function PassDraft(props) {
  const [customer, setCustomer] = useState(null);
  const [student, setStudent] = useState(null);
  const [term, setTerm] = useState(null);
  const [pass, setPass] = useState(null);
  const [classInfo, setClassInfo] = useState(null);
  const [category, setCategory] = useState("DROPIN");
  const [canAddPass, setCanAddPass] = useState(true);

  const navigate = useNavigate();

  const getGroupIds = (groups) => groups.map((group) => group.id);

  useEffect(() => {
    if (!customer) {
      setStudent(null);
      setTerm(null);
      setPass(null);
      setClassInfo(null);
    }
  }, [customer]);

  useEffect(() => {
    if (student) {
      setClassInfo(null);
    }
  }, [student]);

  useEffect(() => {
    if (pass) {
      setClassInfo(null);
    }
  }, [pass]);

  return (
    <UserLayout title="Class Pass">
      <section>
        <SectionHead heading="Draft Pass">
          <button className="btn btn-outline" onClick={() => navigate("/pass")}>
            List Passes
          </button>
        </SectionHead>
        <div className="flex flex-col gap-4 py-2">
          {/* customer */}
          <CustomerSelector selection={customer} setSelection={setCustomer} />
          {/* students */}
          {customer && (
            <StudentSelector
              customerId={customer?.id}
              section={student}
              setSelection={setStudent}
              type="pass"
            />
          )}
          {/* terms */}
          {student && (
            <TermSelector selection={term} setSelection={setTerm} type="pass" />
          )}
          {/* passes */}
          {student && term && (
            <PassSelector
              term={term}
              student={student}
              setTerm={setTerm}
              selection={pass}
              setSelection={setPass}
              type="pass"
              category={category}
              setCategory={setCategory}
              canAddPass={canAddPass}
              setCanAddPass={setCanAddPass}
            />
          )}
          {/* classes */}
          {term && pass && (
            <ClassSelector
              term={term}
              student={student}
              setTerm={setTerm}
              groupsId={getGroupIds(student.groups)}
              selection={classInfo}
              setSelection={setClassInfo}
              type="pass"
            />
          )}
          {/* form */}
          {customer && term && student && (canAddPass || classInfo) && (
            <PassForm
              category={category}
              term={term}
              user={customer}
              student={student}
              pass={pass}
              classInfo={classInfo}
              clear={() => {
                setCustomer(null);
              }}
            />
          )}
        </div>
      </section>
    </UserLayout>
  );
}

function PassForm(props) {
  const { category, term, user, student, pass, classInfo, clear } = props;

  const [mode, setMode] = useState("ADD");
  const [total, setTotal] = useState(0);
  const [sessions, setSessions] = useState([]);

  const { register, handleSubmit, watch, reset } = useForm({
    mode: "onChange",
  });

  const [getApplicableSessions, { refetch }] = useLazyQuery(
    APPLICABLE_SESSIONS,
    {
      onCompleted: (data) => {
        setSessions(data.sessions);
      },
      onError: (error) => {
        toast.error(error.message);
      },
      fetchPolicy: "network-only",
    }
  );

  const [draftEntity, { loading: adding }] = useMutation(DRAFT_ENTITY, {
    onCompleted: (data) => {
      if (data.transaction) {
        clear();
        reset();
        refetch();
        toast.success("Pass drafted successfully!");
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error(
        "Unable to proceed with the request. Please try again later."
      );
    },
    refetchQueries: [
      {
        query: PASSES_LIST,
        variables: {
          studentId: student.id,
          termId: term.id,
          category,
        },
      },
    ],
  });

  const [usingEntity, { loading: using }] = useMutation(USE_ENTITY, {
    onCompleted: (data) => {
      if (data.entity) {
        clear();
        reset();

        toast.success("Pass utlised successfully!");
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error(
        "Unable to proceed with the request. Please try again later."
      );
    },
    refetchQueries: [
      {
        query: PASSES_LIST,
        variables: {
          studentId: student.id,
          termId: term.id,
          category,
        },
      },
    ],
  });

  const onSubmit = (data) => {
    const { method, ref, voucher = 0, discount = 0, session } = data.pass;

    if (mode === "ADD") {
      const input = {
        type: "PASS",
        pass: {
          userId: user.id,
          studentId: student.id,
          termId: term.id,
          category,
          total,
          method,
          voucher: parseInt(voucher * 100),
          discount: parseInt(discount * 100),
          ...(method === "MANUAL" && { ref }),
        },
      };

      draftEntity({ variables: { ...input } });
    } else {
      const input = {
        type: "PASS",
        entityId: pass.id,
        pass: {
          classId: classInfo.id,
          sessionId: session,
        },
      };

      usingEntity({ variables: { ...input } });
    }
  };

  useEffect(() => {
    if (!!pass) {
      setMode("USE");
    } else {
      setMode("ADD");
      setSessions([]);
      if (term) setTotal(category === "DROPIN" ? term.dropIn : term.unlimited);
    }
  }, [pass, term, category]);

  useEffect(() => {
    if (!!classInfo) {
      getApplicableSessions({
        variables: {
          classId: classInfo.id,
          studentId: student.id,
          status: ["COMPLETE", "ONGOING", "UPCOMING"],
        },
      });
    } else {
      setSessions([]);
    }
  }, [classInfo, student, getApplicableSessions]);

  // watch input value for discount and voucher
  const discount = watch("pass.discount");
  const voucher = watch("pass.voucher");
  const showReference = watch("pass.method") === "MANUAL";

  // calculate total
  useEffect(() => {
    let total = 0;
    if (category === "DROPIN") {
      total = term.dropIn;
    } else {
      total = term.unlimited;
    }
    if (discount) {
      total -= discount * 100;
    }
    if (voucher) {
      total -= voucher * 100;
    }
    setTotal(total < 0 ? 0 : total);
  }, [category, term, discount, voucher]);

  return (
    <div className="relative w-full">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full flex flex-col gap-4"
      >
        {/* sessions (select) */}
        {mode === "USE" && sessions && sessions.length > 0 && (
          <div>
            <label className="label">
              <span className="label-text font-semibold">Select Session</span>
            </label>
            <select
              className="select select-bordered bg-white w-full"
              {...register("pass.session", {
                required: true,
                validate: (value) => {
                  // if "" then not valid
                  if (!value) return false;
                  // if value is in sessions then valid
                  return !!sessions.find((session) => session.id === value);
                },
              })}
            >
              <option value="">
                {category === "DROPIN"
                  ? "Select a session"
                  : "Select from session"}
              </option>
              {sessions.map((session) => (
                <option key={session.id} value={session.id}>
                  {session.name} (
                  {new Date(session.date).toLocaleDateString("en-AU")})
                </option>
              ))}
            </select>
          </div>
        )}
        {mode === "ADD" && (
          <div className="flex flex-col gap-4">
            <div className="flex flex-col sm:flex-row gap-4">
              {/* voucher (input numeric) */}
              <div className="basis-1/2">
                <label className="label">
                  <span className="label-text font-semibold">
                    Voucher Amount ($)
                  </span>
                </label>
                <input
                  type="number"
                  name="voucher"
                  placeholder="Voucher"
                  className="input input-bordered w-full bg-white"
                  {...register("pass.voucher")}
                  defaultValue={0}
                  min={0}
                  step={1}
                  disabled={category === "DROPIN"}
                />
              </div>
              {/* discount */}
              <div className="basis-1/2">
                <label className="label">
                  <span className="label-text font-semibold">
                    Discount Amount ($)
                  </span>
                </label>
                <input
                  type="number"
                  name="discount"
                  placeholder="Discount"
                  className="input input-bordered w-full bg-white"
                  {...register("pass.discount")}
                  defaultValue={0}
                  min={0}
                  step={1}
                />
              </div>
            </div>
            <div className="flex flex-col gap-4">
              {/* method (select: MANUAL or STRIPE) */}
              <div>
                <label className="label">
                  <span className="label-text font-semibold">
                    Payment Method
                  </span>
                </label>
                <select
                  className="select select-bordered w-full bg-white"
                  {...register("pass.method", {
                    required: true,
                    validate: (value) => {
                      return !!value;
                    },
                  })}
                  defaultValue={""}
                >
                  <option value="">Select a payment method</option>
                  <option value="MANUAL">
                    Manual / Bank Transfer / EFTPOS / Other
                  </option>
                  <option value="STRIPE">
                    Online (customer will see this under pending and pay online)
                  </option>
                </select>
              </div>
              {/* ref (input) */}
              {showReference && (
                <div>
                  <label className="label">
                    <span className="label-text font-semibold">
                      Payment Reference
                    </span>
                  </label>
                  <input
                    type="text"
                    name="ref"
                    placeholder="Reference Number"
                    className="input input-bordered w-full bg-white"
                    {...register("pass.ref", {
                      required: true,
                    })}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        {/* submit */}
        {(mode === "ADD" ||
          (mode === "USE" && sessions && sessions.length > 0)) && (
          <button
            type="submit"
            className={clsx(
              "btn btn-primary",
              using && "loading",
              adding && "loading"
            )}
          >
            {mode}{" "}
            {category === "DROPIN"
              ? `drop-in pass ${
                  mode === "ADD" ? formatter.format(total / 100) : ""
                }`
              : `unlimited pass ${
                  mode === "ADD" ? formatter.format(total / 100) : ""
                }`}
          </button>
        )}
      </form>
    </div>
  );
}

function PassesList(props) {
  const { isAdmin, isManager } = props;

  const navigate = useNavigate();

  // get tomorrow's date yyyy-mm-dd
  const dateTomorrow = new Date(new Date().setDate(new Date().getDate() + 1))
    .toISOString()
    .split("T")[0];
  // get 1 month ago yyyy-mm-dd
  const dateMonthAgo = new Date(new Date().setMonth(new Date().getMonth() - 1))
    .toISOString()
    .split("T")[0];

  const [name, setName] = useState("");
  const [from, setFrom] = useState(dateMonthAgo);
  const [to, setTo] = useState(dateTomorrow);
  const [passes, setPasses] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [limit /*, setLimit*/] = useState(10);
  const [maxPage, setMaxPage] = useState(0);
  const [data, setData] = useState([]);

  const debouncedName = useDebounce(name, 1000);

  const { loading, refetch } = useQuery(PASSES_LIST, {
    onCompleted: (data) => {
      setPasses(data.list.passes);
      setTotal(data.list.total);
      // based on the total and limit, calculate the max page
      setMaxPage(Math.ceil(data.list.total / limit));
    },
    onError: (error) => {
      toast.error(error.message);
    },
    variables: {
      name: !!debouncedName ? debouncedName : null,
      from: !!from ? from : null,
      to: !!to ? to : null,
      page,
      limit,
    },
    fetchPolicy: "network-only",
    pollInterval: 5000,
  });

  const [getPassesToDownload, { data: download, loading: downloading }] =
    useLazyQuery(PASSES_LIST, {
      onCompleted: (res) => {
        if (res.list.passes.length > 0) {
          const formatted = res.list.passes.map((pass, i) => ({
            "No.": i + 1,
            Name: pass.name,
            Category: pass.category,
            Method: pass.method ?? "UNKNOWN",
            ...(isAdmin && {
              Paid: formatter.format(pass.total / 100),
              "Voucher Amt.": formatter.format(pass.voucher / 100),
              "Discount Amt.": formatter.format(pass.discount / 100),
              "Total Amt.": formatter.format(
                (pass.total + pass.voucher + pass.discount) / 100
              ),
              "Refund Amt.": formatter.format(pass.refunded / 100),
              "Payment Ref.": pass.ref,
            }),
            Status: pass.status,
            Student: pass.student.name,
            Email: pass.user.email ?? "-",
            Phone: pass.user.phone ? formatMobile(pass.user.phone) : "-",
            Term: pass.term.name,
            Sessions: pass.rosterCount,
            Attended: pass.rosterCountAttended,
          }));
          setData(formatted);
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
      fetchPolicy: "network-only",
    });

  const getDownloadData = () => {
    getPassesToDownload({
      variables: {
        name: !!name ? name : null,
        from: !!from ? from : null,
        to: !!to ? to : null,
        page: 0,
        limit: total,
      },
    });
  };

  const formatLabel = (str) => str.replace(/_/g, " ");

  const nextPage = () => {
    // if page is max page, then do nothing
    if (page === maxPage - 1) return;
    setPage(page + 1);
    refetch();
  };

  const prevPage = () => {
    // if page is 0, then do nothing
    if (page === 0) return;
    setPage(page - 1);
    refetch();
  };

  return (
    <UserLayout title="Passes List">
      <section>
        <SectionHead heading="Passes List">
          {/* Back to Passes */}
          <button
            className="btn btn-outline"
            onClick={() => navigate("/pass/draft")}
          >
            Draft Pass
          </button>
        </SectionHead>
        <div className="flex flex-col gap-4 py-2">
          {/* table */}
          <div className="overflow-x-auto rounded-xl shadow-md my-4 bg-white">
            {/* table header */}
            <div className="flex w-full px-4 py-4 border-b-2">
              <div className="flex flex-row justify-between items-center w-full gap-4">
                {/* filters */}
                <div className="basis-3/4 flex flex-row gap-4">
                  {/* name search */}
                  <div className="w-1/3">
                    <label className="label sr-only">
                      <span className="label-text font-semibold">Name</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Search by name"
                      className="input input-bordered w-full bg-white"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </div>
                  {/* date from */}
                  <div className="w-1/3">
                    <label className="label sr-only">
                      <span className="label-text font-semibold">
                        Date From
                      </span>
                    </label>
                    <input
                      type="date"
                      className="input input-bordered w-full bg-white"
                      onChange={(e) => {
                        setFrom(e.target.value);
                        refetch();
                      }}
                      value={from}
                      max={to}
                    />
                  </div>
                  {/* date to */}
                  <div className="w-1/3">
                    <label className="label sr-only">
                      <span className="label-text font-semibold">Date To</span>
                    </label>
                    <input
                      type="date"
                      className="input input-bordered w-full bg-white"
                      onChange={(e) => {
                        setTo(e.target.value);
                        refetch();
                      }}
                      value={to}
                      min={from}
                    />
                  </div>
                </div>
                {/* buttons */}
                <div className="flex flex-row gap-2">
                  {(isAdmin || isManager) && (
                    <>
                      {/* download csv */}
                      {download &&
                      download?.list?.passes.length === data.length ? (
                        <CSVLink
                          className="btn btn-ghost"
                          data={data}
                          filename={`passes.csv`}
                          onClick={(e) => {
                            setData([]);
                          }}
                        >
                          Download CSV
                        </CSVLink>
                      ) : (
                        <button
                          className={clsx(
                            "btn btn-ghost",
                            downloading && "loading"
                          )}
                          onClick={getDownloadData}
                        >
                          Generate CSV
                        </button>
                      )}
                    </>
                  )}
                  {/* reset button */}
                  <button
                    className="btn btn-ghost"
                    onClick={() => {
                      setName("");
                      setFrom(dateMonthAgo);
                      setTo(dateTomorrow);
                      setData([]);
                      refetch();
                    }}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
            {/* table content */}
            {!loading ? (
              <table className="table-normal divide-y-2 bg-white rounded-xl w-full">
                {/* head */}
                <thead className="text-left">
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Category</th>
                    <th>Method</th>
                    <th>Paid</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                {/* body */}
                {passes.length > 0 ? (
                  <tbody className="divide-y-2">
                    {passes.map((value, index) => (
                      <tr key={value.id}>
                        <th>{page * limit + index + 1}</th>
                        <td className="flex flex-row gap-4 items-center">
                          <div>
                            <p className="font-bold">{value.name}</p>
                            <p>
                              <span>{value.term.name}</span>
                              {value.voucher > 0 && (
                                <span className="text-xs text-gray-600">
                                  {" "}
                                  / voucher:{" "}
                                  {formatter.format(value.voucher / 100)}
                                </span>
                              )}
                              {value.discount > 0 && (
                                <span className="text-xs text-gray-600">
                                  {" "}
                                  / discount:{" "}
                                  {formatter.format(value.discount / 100)}
                                </span>
                              )}
                            </p>
                          </div>
                        </td>
                        <td>
                          <span className="font-medium capitalize">
                            {value.category.toLowerCase()}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`text-xs py-1 px-2 rounded-md text-white ${paymentTagColor(
                              value.method
                            )}`}
                          >
                            {formatLabel(value.method ?? "UNKNOWN")}
                          </span>
                        </td>
                        <td className="tracking-wider font-mono">
                          {formatter.format(value.total / 100)}
                        </td>
                        <td>
                          <span
                            className={`text-xs py-1 px-2 rounded-md text-white ${statusTagColor(
                              value.status
                            )}`}
                          >
                            {value.status}
                          </span>
                        </td>
                        <td>
                          <button
                            className={`btn btn-xs btn-ghost ${
                              value.status === "ACTIVE" ? "hidden" : ""
                            }`}
                            onClick={() => navigate(`/pass/${value.id}`)}
                          >
                            Details
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="7">
                        <div className="flex flex-col items-center justify-center py-8">
                          <p className="text-gray-400 text-lg">
                            No passes found
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            ) : (
              <>
                {/* loading skeleton */}
                <LoadingLayout type="list" />
              </>
            )}
            {/* table footer */}
            <div className="flex w-full px-4 py-4 border-t-2">
              <div className="flex flex-row justify-between items-center w-full">
                <div>
                  <p>
                    Showing{" "}
                    <span className="font-bold">{page * limit + 1}</span> -{" "}
                    <span className="font-bold">
                      {page * limit + passes.length}
                    </span>{" "}
                    of <span className="font-bold">{total}</span> results
                  </p>
                </div>
                <div className="btn-group">
                  <button
                    className="btn btn-outline btn-sm"
                    disabled={page === 0 || maxPage <= 1}
                    onClick={() => prevPage()}
                  >
                    Prev
                  </button>
                  <button
                    className="btn btn-outline btn-sm"
                    disabled={page === maxPage - 1 || maxPage <= 1}
                    onClick={() => nextPage()}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </UserLayout>
  );
}

function PassDetail(props) {
  const { id, isAdmin } = props;

  const navigate = useNavigate();

  const { data, refetch } = useQuery(PASS_INFO, {
    onError: (error) => {
      toast.error(error.message);
      navigate(-1);
    },
    variables: {
      passId: id,
    },
    fetchPolicy: "network-only",
  });

  const [cancelEntity, { loading: cancelling }] = useMutation(CANCEL_ENTITY, {
    onError: (error) => {
      console.error(error.message);
      toast.error("Unable to cancel the draft.");
    },
    onCompleted: () => {
      toast.success("Cancelled successfully.");
      navigate(-1);
    },
  });

  const handleCancel = () => {
    window.confirm("Are you sure you want to cancel this pass?") &&
      cancelEntity({
        variables: {
          type: "PASS",
          entityId: id,
        },
      });
  };

  return (
    <UserLayout title={`Pass Details`}>
      <section>
        <SectionHead heading="Pass Details">
          {/* cancel - if draft */}
          {data && data.pass && data.pass.status === "DRAFT" && (
            <button
              className={clsx(
                "btn btn-outline btn-error",
                cancelling && "loading"
              )}
              onClick={handleCancel}
            >
              Cancel
            </button>
          )}
          {/* change - if used and type is DROPIN */}
          {data &&
            data.pass &&
            data.pass.category === "DROPIN" &&
            data.pass.status === "USED" && (
              <ChangePassModal
                label="Change Pass Usage"
                title="Change Pass"
                content="Update the pass usage to a different class session."
              >
                <ChangeDropInForm id={id} refetch={refetch} />
              </ChangePassModal>
            )}
          {/* Back to Passes */}
          <button className="btn btn-outline" onClick={() => navigate(-1)}>
            Back
          </button>
        </SectionHead>
      </section>
      {/* overview section */}
      {data && data.pass && <OverviewSection data={data.pass} />}
      {/* rosters section */}
      {data && data.pass && data.pass.rosters && (
        <RostersList rosters={data.pass.rosters} />
      )}
      {/* transaction section */}
      {data && data.pass && data.pass.status !== "DRAFT" && (
        <TransactionSection
          id={id}
          paymentRefundAllowed={data.pass.status === "APPROVED" && isAdmin}
        />
      )}
    </UserLayout>
  );
}
