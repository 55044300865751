import { useState, useContext } from "react";
import { AuthContext } from "../context/authContext";
import { useParams, useNavigate } from "react-router-dom";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { useDebounce } from "@uidotdev/usehooks";
import { CSVLink } from "react-csv";
import toast from "react-hot-toast";
import clsx from "clsx";
import { UserLayout } from "../layout/User";
import { SectionHead } from "../library/section/Head";
import { OverviewSection } from "../library/section/Overview";
import { TransactionSection } from "../library/section/Transaction";
import { AcceptForm } from "../library/form/Accept";
import { LoadingLayout } from "../layout/Loading";
import {
  formatter,
  paymentTagColor,
  statusTagColor,
  convertUnixTimestamp,
} from "../utils/helpers";
import { PERFORMANCE_INFO, PERFORMANCES_LIST } from "../utils/queries";
import { REJECT_ENTITY } from "../utils/mutations";

export default function Performances(props) {
  const { param } = useParams();

  const { isAdmin, isManager } = useContext(AuthContext);

  if (param) {
    return (
      <PerformanceDetail id={param} isAdmin={isAdmin} isManager={isManager} />
    );
  } else {
    return <PerformancesList isAdmin={isAdmin} isManager={isManager} />;
  }
}

function PerformancesList(props) {
  const { isAdmin, isManager } = props;

  const navigate = useNavigate();

  // get tomorrow's date yyyy-mm-dd
  const dateTomorrow = new Date(new Date().setDate(new Date().getDate() + 1))
    .toISOString()
    .split("T")[0];
  // get 1 month ago yyyy-mm-dd
  const dateMonthAgo = new Date(new Date().setMonth(new Date().getMonth() - 1))
    .toISOString()
    .split("T")[0];

  const [name, setName] = useState("");
  const [from, setFrom] = useState(dateMonthAgo);
  const [to, setTo] = useState(dateTomorrow);
  const [performances, setPerformances] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [limit /*, setLimit*/] = useState(10);
  const [maxPage, setMaxPage] = useState(0);
  const [data, setData] = useState([]);

  const debouncedName = useDebounce(name, 1000);

  const { loading, refetch } = useQuery(PERFORMANCES_LIST, {
    onCompleted: (data) => {
      setPerformances(data.list.performances);
      setTotal(data.list.total);
      // based on the total and limit, calculate the max page
      setMaxPage(Math.ceil(data.list.total / limit));
    },
    onError: (error) => {
      toast.error(error.message);
    },
    variables: {
      name: !!debouncedName ? debouncedName : null,
      from: !!from ? from : null,
      to: !!to ? to : null,
      status: [
        "REQUESTED",
        "DRAFT",
        "APPROVED",
        "DECLINED",
        "REFUNDED",
        "COMPLETED",
      ],
      page,
      limit,
    },
    fetchPolicy: "network-only",
    pollInterval: 5000,
  });

  const [getPerformancesToDownload, { data: download, loading: downloading }] =
    useLazyQuery(PERFORMANCES_LIST, {
      onCompleted: (res) => {
        if (res.list.performances.length > 0) {
          const formatted = res.list.performances.map((performance, i) => ({
            "No.": i + 1,
            Name: performance.name,
            Date: convertUnixTimestamp(performance.date),
            Requirements: performance.requirements ?? "-",
            Dancers: performance.dancers ?? 0,
            "Vennue Name": performance.venue?.name ?? "-",
            Street: performance.venue?.address ?? "-",
            Suburb: performance.venue?.suburb ?? "-",
            Postcode: performance.venue?.postcode ?? "-",
            State: performance.venue?.state ?? "-",
            Notes: performance.notes ?? "-",
            Email: performance.user?.email,
            Phone: performance.user?.phone ?? "-",
            Method: performance.payment.method ?? "UNKNOWN",
            ...(isAdmin && {
              Paid: formatter.format(performance.total / 100),
              Discount: formatter.format(
                performance.amounts?.discount / 100 ?? 0
              ),
              Refunded: formatter.format(
                performance.amounts?.refunded / 100 ?? 0
              ),
              "Payment Ref.": performance.payment?.reference ?? "-",
            }),
            Status: performance.status,
            Created: convertUnixTimestamp(performance.created),
          }));
          setData(formatted);
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
      fetchPolicy: "network-only",
    });

  const getDownloadData = () => {
    getPerformancesToDownload({
      variables: {
        name: !!debouncedName ? debouncedName : null,
        from: !!from ? from : null,
        to: !!to ? to : null,
        status: [
          "REQUESTED",
          "DRAFT",
          "APPROVED",
          "DECLINED",
          "REFUNDED",
          "COMPLETED",
        ],
        page: 0,
        limit: total,
      },
    });
  };

  const formatLabel = (str) => str.replace(/_/g, " ");

  const nextPage = () => {
    // if page is max page, then do nothing
    if (page === maxPage - 1) return;
    setPage(page + 1);
    refetch();
  };

  const prevPage = () => {
    // if page is 0, then do nothing
    if (page === 0) return;
    setPage(page - 1);
    refetch();
  };

  return (
    <UserLayout title="Performance List">
      <section>
        <SectionHead heading="Performance List"></SectionHead>
        <div className="flex flex-col gap-4 py-2">
          {/* table */}
          <div className="overflow-x-auto rounded-xl shadow-md my-4 bg-white">
            {/* table header */}
            <div className="flex w-full px-4 py-4 border-b-2">
              <div className="flex flex-row justify-between items-center w-full gap-4">
                {/* filters */}
                <div className="basis-3/4 flex flex-row gap-4">
                  {/* name search */}
                  <div className="w-1/3">
                    <label className="label sr-only">
                      <span className="label-text font-semibold">Name</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Search by name"
                      className="input input-bordered w-full bg-white"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </div>
                  {/* date from */}
                  <div className="w-1/3">
                    <label className="label sr-only">
                      <span className="label-text font-semibold">
                        Date From
                      </span>
                    </label>
                    <input
                      type="date"
                      className="input input-bordered w-full bg-white"
                      onChange={(e) => {
                        setFrom(e.target.value);
                        refetch();
                      }}
                      value={from}
                      max={to}
                    />
                  </div>
                  {/* date to */}
                  <div className="w-1/3">
                    <label className="label sr-only">
                      <span className="label-text font-semibold">Date To</span>
                    </label>
                    <input
                      type="date"
                      className="input input-bordered w-full bg-white"
                      onChange={(e) => {
                        setTo(e.target.value);
                        refetch();
                      }}
                      value={to}
                      min={from}
                    />
                  </div>
                </div>
                {/* buttons */}
                <div className="flex flex-row gap-2">
                  {(isAdmin || isManager) && (
                    <>
                      {/* download csv */}
                      {download &&
                      download?.list?.performances.length === data.length ? (
                        <CSVLink
                          className="btn btn-ghost"
                          data={data}
                          filename={`performances.csv`}
                          onClick={(e) => {
                            setData([]);
                          }}
                        >
                          Download CSV
                        </CSVLink>
                      ) : (
                        <button
                          className={clsx(
                            "btn btn-ghost",
                            downloading && "loading"
                          )}
                          onClick={getDownloadData}
                        >
                          Generate CSV
                        </button>
                      )}
                    </>
                  )}
                  {/* reset button */}
                  <button
                    className="btn btn-ghost"
                    onClick={() => {
                      setName("");
                      setFrom(dateMonthAgo);
                      setTo(dateTomorrow);
                      setData([]);
                      refetch();
                    }}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
            {/* table content */}
            {!loading ? (
              <table className="table-normal divide-y-2 bg-white rounded-xl w-full">
                {/* head */}
                <thead className="text-left">
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Date</th>
                    <th>Method</th>
                    <th>Paid</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                {/* body */}
                {performances.length > 0 ? (
                  <tbody className="divide-y-2">
                    {performances.map((value, index) => (
                      <tr key={value.id}>
                        <th>{page * limit + index + 1}</th>
                        <td className="flex flex-row gap-4 items-center">
                          <div>
                            <p className="font-bold">{value.name}</p>
                            <p>
                              <span className="font-medium">
                                {value.venue?.name ?? "-"} (
                                {value.venue?.suburb ?? "-"})
                              </span>
                            </p>
                            <p>
                              <span className="text-sm text-gray-600">
                                {convertUnixTimestamp(value.created)}
                              </span>
                            </p>
                          </div>
                        </td>
                        <td>{convertUnixTimestamp(value.date)}</td>
                        <td>
                          <span
                            className={`text-xs py-1 px-2 rounded-md text-white ${paymentTagColor(
                              value.payment.method
                            )}`}
                          >
                            {formatLabel(value.payment.method ?? "UNKNOWN")}
                          </span>
                        </td>
                        <td className="tracking-wider font-mono">
                          {formatter.format(value.total / 100)}
                        </td>
                        <td>
                          <span
                            className={`text-xs py-1 px-2 rounded-md text-white ${statusTagColor(
                              value.status
                            )}`}
                          >
                            {value.status}
                          </span>
                        </td>
                        <td>
                          <button
                            className={`btn btn-xs btn-ghost ${
                              value.status === "ACTIVE" ? "hidden" : ""
                            }`}
                            onClick={() => navigate(`/performance/${value.id}`)}
                          >
                            Details
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="7">
                        <div className="flex flex-col items-center justify-center py-8">
                          <p className="text-gray-400 text-lg">
                            No performances found
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            ) : (
              <>
                {/* loading skeleton */}
                <LoadingLayout type="list" />
              </>
            )}
            {/* table footer */}
            <div className="flex w-full px-4 py-4 border-t-2">
              <div className="flex flex-row justify-between items-center w-full">
                <div>
                  <p>
                    Showing{" "}
                    <span className="font-bold">{page * limit + 1}</span> -{" "}
                    <span className="font-bold">
                      {page * limit + performances.length}
                    </span>{" "}
                    of <span className="font-bold">{total}</span> results
                  </p>
                </div>
                <div className="btn-group">
                  <button
                    className="btn btn-outline btn-sm"
                    disabled={page === 0 || maxPage <= 1}
                    onClick={() => prevPage()}
                  >
                    Prev
                  </button>
                  <button
                    className="btn btn-outline btn-sm"
                    disabled={page === maxPage - 1 || maxPage <= 1}
                    onClick={() => nextPage()}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </UserLayout>
  );
}

function PerformanceDetail(props) {
  const { id, isAdmin } = props;

  const [canDecide, setCanDecide] = useState(false);

  const navigate = useNavigate();

  const { data, refetch } = useQuery(PERFORMANCE_INFO, {
    variables: { performanceId: id },
    onCompleted: (information) => {
      if (information.performance.status === "REQUESTED") {
        setCanDecide(true);
      } else {
        setCanDecide(false);
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error("Unable to get details!");
    },
    fetchPolicy: "network-only",
  });

  const [rejectRequest, { loading: updating }] = useMutation(REJECT_ENTITY, {
    onCompleted: () => {
      toast.success("Request rejected successfully!");
      refetch();
    },
    onError: (error) => {
      console.error(error);
      toast.error(error.message);
    },
  });

  const handleRejectRequest = () => {
    window.confirm("Are you sure you want to reject this request?") &&
      rejectRequest({
        variables: {
          type: "PERFORMANCE",
          entityId: id,
        },
      });
  };

  return (
    <UserLayout title={`Performance Details`}>
      <section>
        <SectionHead heading="Performance Details">
          {/* reject request */}
          {canDecide && (
            <button
              className={clsx(
                "btn btn-outline btn-error",
                updating && "loading"
              )}
              onClick={handleRejectRequest}
            >
              Reject Request
            </button>
          )}
          {/* Back to Passes */}
          <button className="btn btn-outline" onClick={() => navigate(-1)}>
            Back
          </button>
        </SectionHead>
      </section>
      {/* overview section */}
      {data && data.performance && <OverviewSection data={data.performance} />}
      {/* accept form */}
      {canDecide && <AcceptForm id={id} type="PERFORMANCE" refetch={refetch} />}
      {/* transaction section */}
      {data && data.performance && data.performance.status !== "REQUESTED" && (
        <TransactionSection
          id={id}
          paymentRefundAllowed={
            data.performance.status === "APPROVED" && isAdmin
          }
        />
      )}
    </UserLayout>
  );
}
