export const TeacherDetails = (props) => {
  const { details } = props;

  return (
    <>
      {details && (
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 py-6 px-8 my-6 bg-white rounded-lg shadow-md">
          <div>
            <dt className="p-1 pb-0">
              <span className="label-text font-semibold">Name</span>
            </dt>
            <dd className="p-1">
              <span className="mt-1 text-md">{details.name}</span>
            </dd>
          </div>

          <div>
            <dt className="p-1 pb-0">
              <span className="label-text font-semibold">Email</span>
            </dt>
            <dd className="p-1">
              <span className="mt-1 text-md">{details.email}</span>
            </dd>
          </div>

          <div>
            <dt className="p-1 pb-0">
              <span className="label-text font-semibold">Phone</span>
            </dt>
            <dd className="p-1">
              <span className="mt-1 text-md">{details.phone}</span>
            </dd>
          </div>

          <div>
            <dt className="p-1 pb-0">
              <span className="label-text font-semibold">Gender</span>
            </dt>
            <dd className="p-1">
              <span className="mt-1 text-md">{details.gender}</span>
            </dd>
          </div>

          <div>
            <dt className="p-1 pb-0">
              <span className="label-text font-semibold">Address</span>
            </dt>
            <dd className="p-1">
              <span className="mt-1 text-md">{details.address}</span>
            </dd>
          </div>

          <div>
            <dt className="p-1 pb-0">
              <span className="label-text font-semibold">Status</span>
            </dt>
            <dd className="p-1">
              <span
                className={`text-xs py-1 px-2 rounded-md text-white ${statusTagColor(
                  details.status
                )}`}
              >
                {details.status}
              </span>
            </dd>
          </div>
        </div>
      )}
    </>
  );
};

function statusTagColor(str) {
  if (str === "ACTIVE") return `bg-info`;
  return `bg-base-300`;
}
