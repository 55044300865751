import { useState } from "react";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import { UserLayout } from "../layout/User";
import { SectionHead } from "../library/section/Head";
import { upperFirst } from "../utils/helpers";
import { AttendanceReport } from "../library/report/Attendance";
import { EnrolmentsReport } from "../library/report/Enrolments";
import { RetentionReport } from "../library/report/Retention";
import { StudentsAttritionReport } from "../library/report/StudentsAttrition";
import { CSVLink } from "react-csv";
import { Now } from "../utils/helpers";

function Reports(props) {
  const { type } = useParams();
  const navigate = useNavigate();

  const [formatted, setFormatted] = useState([]);

  const basedOnType = (type) => {
    switch (type) {
      case "attendance":
        return <AttendanceReport csv={setFormatted} />;
      case "enrolment":
        return <EnrolmentsReport csv={setFormatted} />;
      case "retention":
        return <RetentionReport csv={setFormatted} />;
      case "attrition":
        return <StudentsAttritionReport csv={setFormatted} />;
      default:
        return <Navigate to={-1} />;
    }
  };

  return (
    <UserLayout title={`${upperFirst(type)} report`}>
      <header>
        <SectionHead heading={`${upperFirst(type)} report`}>
          {formatted.length > 0 && (
            <CSVLink
              data={formatted}
              target="_blank"
              filename={`${type}-${Now}.csv`}
              className="btn btn-dark btn-outline"
            >
              Download CSV
            </CSVLink>
          )}
          <button className="btn btn-outline" onClick={() => navigate(-1)}>
            Back
          </button>
        </SectionHead>
      </header>
      <main>{basedOnType(type)}</main>
    </UserLayout>
  );
}

export default Reports;
