import { LIST_STUDENTS } from "../../utils/queries";
import { useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { StudentDrawer } from "../drawer/Student";
import { ChatBubbleLeftEllipsisIcon as ChatAltIcon } from "@heroicons/react/20/solid";
import { UpdateStudentModal } from "../modal/UpdateStudent";
import { UpdateStudentForm } from "../form/UpdateStudent";

const BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL;

export const StudentsTable = (props) => {
  const { term, formatted } = props;

  const [results, setResults] = useState([]);
  const [response, setResponse] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [resultsPerPage] = useState(10);
  const [list, setList] = useState([]);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(resultsPerPage);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selected, setSelected] = useState(null);
  const [updateStudent, setUpdateStudent] = useState(false);

  const notifyError = () => toast.error("Something went wrong!");
  const notifyLoading = () => toast.loading("Loading...");

  const { loading } = useQuery(LIST_STUDENTS, {
    onCompleted: (data) => {
      if (data?.students) {
        setResults(data.students);
        setResponse(data.students);
        formatted({
          title: "Students",
          label: "students",
          list: data.students.map((student) => ({
            Name: student.name,
            Gender: student.gender,
            DoB: student.dob,
            Age: student.age,
            Notes: student.notes,
            Account: student.email,
          })),
        });
      }
    },
    onError: (error) => {
      if (error) {
        notifyError();
      }
    },
    pollInterval: 120000,
  });

  const sliceData = (data, page, rows) => {
    setFrom(data.length > 0 ? (page - 1) * rows + 1 : 0);
    setTo(page * rows > data.length ? data.length : page * rows);
    return data.slice((page - 1) * rows, page * rows);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleOpen = (id) => {
    setOpenDrawer(true);
    setSelected(id);
  };
  const handleClose = () => {
    setOpenDrawer(false);
    setSelected(null);
  };

  useEffect(() => {
    if (loading) {
      notifyLoading();
    } else {
      toast.dismiss();
    }
  }, [loading]);

  useEffect(() => {
    if (term) {
      setResults(
        response.filter(
          (data) =>
            data.name.toLowerCase().includes(term.toLowerCase()) ||
            data.email.toLowerCase().includes(term.toLowerCase())
        )
      );
      setCurrentPage(1);
    } else {
      setResults(response);
    }
  }, [term, response]);

  useEffect(() => {
    if (results) setTotalPages(Math.ceil(results.length / resultsPerPage));
  }, [results, resultsPerPage]);

  useEffect(() => {
    const slice = sliceData(results, currentPage, resultsPerPage);
    setList([...slice]);
  }, [results, currentPage, setList, resultsPerPage]);

  return (
    <div>
      <div className="overflow-x-auto rounded-xl shadow-md">
        <table className="table-normal divide-y-2 bg-white rounded-xl w-full">
          {/* head */}
          <thead className="text-left">
            <tr>
              <th></th>
              <th>Name</th>
              <th>DoB</th>
              <th>Email</th>
              <th>Notes</th>
              <th></th>
            </tr>
          </thead>
          <tbody className="divide-y-2">
            {list &&
              list.map((value, index) => (
                <tr key={value.id}>
                  <th>{from + index}</th>
                  <td className="flex flex-row gap-4 items-center">
                    {value.picture ? (
                      <img
                        src={`${BUCKET_URL}/${value.picture}`}
                        className="w-14 h-14 rounded-full"
                        alt={`${value.name}`}
                      />
                    ) : (
                      <img
                        src={`${BUCKET_URL}/pictures/placeholder.jpg`}
                        className="w-14 h-14 rounded-full"
                        alt={`${value.name}`}
                      />
                    )}
                    <div>
                      <p className="font-bold">{value.name}</p>
                      <p>
                        {value.gender} - {value.age} yrs
                      </p>
                    </div>
                    {/* comments count badge */}
                    <div className="bg-gray-100 rounded-full px-2">
                      <p className="text-gray-600 text-sm flex items-center gap-1">
                        <ChatAltIcon className="h-4 w-4" />{" "}
                        <span className="font-semibold font-mono">
                          {value.commentsCount ? value.commentsCount : 0}
                        </span>
                      </p>
                    </div>
                  </td>
                  <td>{value.dob}</td>
                  <td>{value.email}</td>
                  <td>{value.notes}</td>
                  <td className="">
                    <button
                      className="btn btn-xs btn-ghost"
                      onClick={() => handleOpen(value.id)}
                    >
                      View Profile
                    </button>
                    <label
                      htmlFor="student-modal-update"
                      className="btn btn-xs btn-ghost"
                      onClick={() => setUpdateStudent(value.id)}
                    >
                      Update Info
                    </label>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {/* table footer */}
      <div className="flex w-full px-2 py-4">
        {results && (
          <div className="flex flex-row justify-between items-center w-full">
            <div>
              <p>
                Showing <span className="font-bold">{from}</span> -{" "}
                <span className="font-bold">{to}</span> of{" "}
                <span className="font-bold">{results.length}</span> results
              </p>
            </div>
            <div className="btn-group">
              <button
                className="btn btn-outline btn-sm"
                disabled={currentPage === 1 || totalPages === 0}
                onClick={() => prevPage()}
              >
                Prev
              </button>
              <button
                className="btn btn-outline btn-sm"
                disabled={currentPage === totalPages || totalPages === 0}
                onClick={() => nextPage()}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
      {openDrawer && <StudentDrawer close={handleClose} studentId={selected} />}
      <UpdateStudentModal
        title="Update student information"
        content="This information will be used for student enrolment to the class"
        btn="btn btn-sm btn-outline hidden"
        label={"Update"}
      >
        {updateStudent && <UpdateStudentForm student={updateStudent} />}
      </UpdateStudentModal>
    </div>
  );
};
