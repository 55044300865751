import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { CREATE_TEACHER } from "../../utils/mutations";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { LIST_TEACHERS } from "../../utils/queries";

export const TeacherForm = (props) => {
  const navigate = useNavigate();

  const { register, handleSubmit } = useForm();

  const notifySuccess = () => toast.success("Teacher created successfully!");
  const notifyError = () => toast.error("Something went wrong!");

  const [createTeacher, { loading: creating }] = useMutation(CREATE_TEACHER, {
    onCompleted: () => {
      notifySuccess();
      navigate("/teachers");
    },
    onError: () => {
      notifyError();
    },
    refetchQueries: [{ query: LIST_TEACHERS }],
  });

  const onSubmit = (data) => {
    const {
      firstName,
      lastName,
      dob,
      gender,
      email,
      phone,
      street,
      suburb,
      postcode,
    } = data.teacher;
    createTeacher({
      variables: {
        input: {
          firstName,
          lastName,
          dob,
          gender,
          email: email.toLowerCase(),
          phone,
          street,
          suburb,
          postcode,
        },
      },
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-between space-x-3 max-w-md">
          <div className="w-full">
            <label className="label">
              <span className="label-text font-semibold">First name</span>
            </label>
            <input
              type="text"
              placeholder="John"
              className="input input-bordered w-full bg-white"
              {...register("teacher.firstName")}
              required
            />
          </div>
          <div className="w-full">
            <label className="label">
              <span className="label-text font-semibold">Last name</span>
            </label>
            <input
              type="text"
              placeholder="Doe"
              className="input input-bordered w-full bg-white"
              {...register("teacher.lastName")}
              required
            />
          </div>
        </div>
        <div>
          <label className="label">
            <span className="label-text font-semibold">Date of birth</span>
          </label>
          <input
            type="date"
            placeholder="Type here"
            className="input input-bordered w-full max-w-md bg-white"
            {...register("teacher.dob")}
            required
          />
        </div>
        <div>
          <label className="label">
            <span className="label-text font-semibold">Gender</span>
          </label>
          <select
            defaultValue="Select"
            className="select select-bordered w-full max-w-md bg-white"
            {...register("teacher.gender")}
            required
          >
            <option disabled>Select</option>
            <option value="Female">Female</option>
            <option value="Male">Male</option>
          </select>
        </div>
        <div>
          <label className="label">
            <span className="label-text font-semibold">Phone</span>
          </label>
          <input
            type="tel"
            placeholder="0400 000 000"
            className="input input-bordered w-full max-w-md bg-white"
            {...register("teacher.phone")}
            required
          />
        </div>
        <div>
          <label className="label">
            <span className="label-text font-semibold">Email address</span>
          </label>
          <input
            type="email"
            placeholder="john.doe@email.com"
            className="input input-bordered w-full max-w-md bg-white"
            {...register("teacher.email")}
            required
          />
        </div>
        <div>
          <label className="label">
            <span className="label-text font-semibold">Street</span>
          </label>
          <input
            type="text"
            placeholder="Street address"
            className="input input-bordered w-full max-w-md bg-white"
            {...register("teacher.street")}
          />
        </div>
        <div className="flex justify-between space-x-3 max-w-md">
          <div className="w-full">
            <label className="label">
              <span className="label-text font-semibold">Suburb</span>
            </label>
            <input
              type="text"
              placeholder="Suburb"
              className="input input-bordered w-full bg-white"
              {...register("teacher.suburb")}
            />
          </div>
          <div className="w-full">
            <label className="label">
              <span className="label-text font-semibold">Postcode</span>
            </label>
            <input
              type="text"
              placeholder="Postcode"
              className="input input-bordered w-full bg-white"
              {...register("teacher.postcode")}
            />
          </div>
        </div>
        <div className="pt-4">
          <button type="submit" className={creating ? "btn loading" : "btn"}>
            Create
          </button>
        </div>
      </form>
    </div>
  );
};
