import { LIST_PAYMENTS } from "../../utils/queries";
import { REFUND_ENROLMENT } from "../../utils/mutations";
import { useQuery, useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";

export const PaymentsTable = (props) => {
  const { term, formatted } = props;

  const [results, setResults] = useState([]);
  const [response, setResponse] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [resultsPerPage] = useState(10);
  const [list, setList] = useState([]);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(resultsPerPage);

  const notifyError = () => toast.error("Something went wrong!");
  const notifyLoading = () => toast.loading("Loading...");

  const { loading } = useQuery(LIST_PAYMENTS, {
    onCompleted: (data) => {
      if (data?.payments) {
        setResults(data.payments);
        setResponse(data.payments);
        formatted({
          title: "Payments",
          label: "payments",
          list: data.payments.map((payment) => ({
            Name: payment.name,
            Ref: payment.ref,
            Method: payment.method,
            Email: payment.email,
            Class: payment.class,
            Amount: formatter.format(payment.total / 100),
            Cancelled: payment.cancelled ? "Yes" : "No",
            Refunded: payment.refunded ? "Yes" : "No",
          })),
        });
      }
    },
    onError: (error) => {
      if (error) {
        notifyError();
      }
    },
    pollInterval: 60000,
  });

  const [refundEnrolment, { loading: refundLoading }] = useMutation(
    REFUND_ENROLMENT,
    {
      onCompleted: () => {
        toast.success("Enrolment refund processed!");
      },
      onError: () => notifyError(),
      refetchQueries: [
        {
          query: LIST_PAYMENTS,
        },
      ],
    }
  );

  const handleRefund = (enrolmentId) => {
    window.confirm(
      "Are you sure you want to fully refund this payment? THIS ACTION CANNOT BE UNDONE. (For Online Payment Refund: processed in 5 - 10 business days)."
    ) &&
      refundEnrolment({
        variables: {
          enrolmentId,
        },
      });
  };

  const sliceData = (data, page, rows) => {
    setFrom(data.length > 0 ? (page - 1) * rows + 1 : 0);
    setTo(page * rows > data.length ? data.length : page * rows);
    return data.slice((page - 1) * rows, page * rows);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  useEffect(() => {
    if (loading) {
      notifyLoading();
    } else {
      toast.dismiss();
    }
  }, [loading]);

  useEffect(() => {
    if (term) {
      setResults(
        response.filter(
          (data) =>
            data.name.toLowerCase().includes(term.toLowerCase()) ||
            data.email.toLowerCase().includes(term.toLowerCase())
        )
      );
      setCurrentPage(1);
    } else {
      setResults(response);
    }
  }, [term, response]);

  useEffect(() => {
    if (results) setTotalPages(Math.ceil(results.length / resultsPerPage));
  }, [results, resultsPerPage]);

  useEffect(() => {
    const slice = sliceData(results, currentPage, resultsPerPage);
    setList([...slice]);
  }, [results, currentPage, setList, resultsPerPage]);

  return (
    <div>
      <div className="overflow-x-auto rounded-xl shadow-md">
        <table className="table-normal divide-y-2 bg-white rounded-xl w-full">
          {/* head */}
          <thead className="text-left">
            <tr>
              <th></th>
              <th>Name</th>
              <th>Email</th>
              <th>Class</th>
              <th>Total</th>
              <th></th>
            </tr>
          </thead>
          <tbody className="divide-y-2">
            {list.length > 0 &&
              list.map((value, index) => (
                <tr key={value.id}>
                  <th>{from + index}</th>
                  <td>
                    <p className="font-bold">{value.name}</p>
                    {value.method} |{" "}
                    {value.ref ? truncateString(value.ref, 30) : ""}{" "}
                  </td>
                  <td>{value.email}</td>
                  <td
                    className={`${
                      value.cancelled &&
                      "line-through decoration-2 decoration-red-400"
                    } ${
                      value.refunded &&
                      "line-through decoration-2 decoration-red-400"
                    }`}
                  >
                    {value.class}
                  </td>
                  <td
                    className={`${
                      value.refunded &&
                      "line-through decoration-2 decoration-red-400"
                    }`}
                  >
                    {formatter.format(value.total / 100)}
                  </td>
                  <td>
                    {value.cancelled && (
                      <button
                        className={`btn btn-xs btn-outline btn-error ${
                          refundLoading ? "loading" : ""
                        }`}
                        onClick={() => handleRefund(value.id)}
                      >
                        Refund
                      </button>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {/* table footer */}
      <div className="flex w-full px-2 py-4">
        {results && (
          <div className="flex flex-row justify-between items-center w-full">
            <div>
              <p>
                Showing <span className="font-bold">{from}</span> -{" "}
                <span className="font-bold">{to}</span> of{" "}
                <span className="font-bold">{results.length}</span> results
              </p>
            </div>
            <div className="btn-group">
              <button
                className="btn btn-outline btn-sm"
                disabled={currentPage === 1 || totalPages === 0}
                onClick={() => prevPage()}
              >
                Prev
              </button>
              <button
                className="btn btn-outline btn-sm"
                disabled={currentPage === totalPages || totalPages === 0}
                onClick={() => nextPage()}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

function truncateString(string, limit) {
  if (string.length > limit) {
    return string.substring(0, limit) + "...";
  } else {
    return string;
  }
}

const formatter = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD",
});
