import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { PublicLayout } from "../layout/Public";
import { useState, useEffect, useContext } from "react";
import { useMutation, gql } from "@apollo/client";
import { AuthContext } from "../context/authContext";
import toast from "react-hot-toast";

function Login(props) {
  const { login } = useContext(AuthContext);
  const [loader, setLoader] = useState(false);

  const notifyError = () =>
    toast.error("Please check your account credentials!");

  const USER_LOGIN = gql`
    mutation Login($input: userCredentials) {
      auth: login(input: $input) {
        token
        user {
          id
          email
          admin
        }
      }
    }
  `;

  const { register, handleSubmit } = useForm();

  const [loginUser, { loading, error }] = useMutation(USER_LOGIN, {
    update(_, { data: { auth: userData } }) {
      login(userData);
    },
  });

  const onSubmit = (data) => {
    console.log(data);
    const { email, password } = data.login;
    loginUser({
      variables: {
        input: {
          email: email.toLowerCase(),
          password,
        },
      },
    });
  };

  useEffect(() => {
    if (loading) setLoader(true);
  }, [loading]);

  useEffect(() => {
    if (error) {
      setLoader(false);
      notifyError();
    }
  }, [error]);

  return (
    <PublicLayout title="Admin Login | Swastik App">
      <div className="text-center pb-4">
        <h1 className="text-4xl font-bold">Login</h1>
        <div className="text-center pt-4">For Admin only</div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
        <div>
          <label className="label">
            <span className="label-text font-semibold">Email address</span>
          </label>
          <input
            type="email"
            placeholder="john.doe@email.com"
            className="input input-bordered w-full"
            {...register("login.email")}
            required
          />
        </div>
        <div>
          <label className="label">
            <span className="label-text font-semibold">Password</span>
          </label>
          <input
            type="password"
            placeholder="********"
            className="input input-bordered w-full"
            {...register("login.password")}
            required
          />
        </div>
        <div className="pt-4">
          <button
            type="submit"
            className={
              loader
                ? "btn btn-primary w-full loading"
                : "btn btn-primary w-full"
            }
          >
            Login
          </button>
        </div>
      </form>
      <div className="text-center pt-6">
        <Link to="/reset" className="text-secondary">
          Forgot password?
        </Link>
      </div>
    </PublicLayout>
  );
}

export default Login;
