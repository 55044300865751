import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/authContext";
import { useQuery } from "@apollo/client";
import { REPORT_RETENTION } from "../../utils/queries";
import toast from "react-hot-toast";
import { formatter } from "../../utils/helpers";

export const RetentionReport = (props) => {
  const { csv } = props;

  const { isAdmin } = useContext(AuthContext);

  const [list, setList] = useState([]);
  const [filter, setFilter] = useState({
    terms: null,
    enrolments: null,
    spend: null,
  });
  const [filtered, setFiltered] = useState([]);

  const { loading, data } = useQuery(REPORT_RETENTION, {
    onCompleted: (data) => {
      if (data.report) {
        setList(data.report);
        setFiltered(data.report);
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  useEffect(() => {
    let filtered = [...list];
    if (filter.terms) {
      const values = filter.terms.split(",").map((item) => parseInt(item));
      filtered = list.filter(
        (item) => item.terms >= values[0] && item.terms < values[1]
      );
    }
    if (filter.enrolments) {
      const values = filter.enrolments.split(",").map((item) => parseInt(item));
      filtered = list.filter(
        (item) => item.enrolments >= values[0] && item.enrolments < values[1]
      );
    }
    if (filter.spend) {
      const values = filter.spend
        .split(",")
        .map((item) => parseInt(item) * 100);
      filtered = list.filter(
        (item) => item.spend >= values[0] && item.spend < values[1]
      );
    }
    setFiltered(filtered);
  }, [filter, list]);

  useEffect(() => {
    const formatted =
      filtered.length > 0
        ? filtered.map((item) => ({
            name: item.name,
            dob: item.dob,
            gender: item.gender,
            email: item.email,
            phone: item.phone,
            terms: item.terms,
            enrolments: item.enrolments,
            vouchers: item.vouchers,
            ...(isAdmin && { spend: formatter.format(item.spend / 100) }),
            recent: item.recent,
          }))
        : [];

    csv(formatted);
  }, [filtered, csv, isAdmin]);

  if (loading) return <p>Generating report...</p>;

  return (
    <div className="flex flex-col gap-6">
      {data && (
        <>
          <RetentionReportList
            data={filtered}
            filter={filter}
            setFilter={setFilter}
            isAdmin={isAdmin}
          />
        </>
      )}
    </div>
  );
};

const RetentionReportList = (props) => {
  const { data, filter, setFilter, isAdmin } = props;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="overflow-x-auto bg-white rounded-lg shadow mt-4">
      {/* filters */}
      <div className="px-4 flex flex-row justify-between items-center gap-4 border-b">
        {/* dropdown for term, program, location */}
        <div>
          <select
            name="terms"
            className="select select-md w-full max-w-max bg-white"
            value={filter.terms || ""}
            onChange={handleChange}
          >
            <option disabled value="">
              Filter by Terms
            </option>
            <option value={[0, 1]}>None</option>
            <option value={[1, 2]}>One (1)</option>
            <option value={[2, 3]}>Two (2)</option>
            <option value={[3, 4]}>Three (3)</option>
            <option value={[4, 1000]}>More than 3</option>
          </select>

          <select
            name="enrolments"
            className="select select-md w-full max-w-max bg-white"
            value={filter.enrolments || ""}
            onChange={handleChange}
          >
            <option disabled value="">
              Filter by Enrolments
            </option>
            <option value={[0, 1]}>None</option>
            <option value={[1, 2]}>One (1)</option>
            <option value={[2, 3]}>Two (2)</option>
            <option value={[3, 4]}>Three (3)</option>
            <option value={[4, 1000]}>More than 3</option>
          </select>

          <select
            name="spend"
            className="select select-md w-full max-w-max bg-white"
            value={filter.spend || ""}
            onChange={handleChange}
          >
            <option disabled value="">
              Filter by Spend
            </option>
            <option value={[0, 1]}>Zero Spend</option>
            <option value={[1, 500]}>Upto $500</option>
            <option value={[500, 1000]}>$500 to $1K</option>
            <option value={[1000, 2500]}>$1K to $2.5K</option>
            <option value={[2500, 10000000]}>More than $2.5K</option>
          </select>
        </div>

        {/* reset button */}
        <button className="btn btn-sm btn-ghost" onClick={() => setFilter({})}>
          Reset
        </button>
      </div>
      <table className="table-compact w-full divide-y">
        <thead>
          <tr className="text-left">
            <th></th>
            <th>Name</th>
            <th>DoB</th>
            <th>Gender</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Terms</th>
            <th>Enrolments</th>
            <th>Vouchers</th>
            {isAdmin && <th>Spend</th>}
            <th>Recent</th>
          </tr>
        </thead>
        <tbody className="divide-y">
          {data.map((row, index) => (
            <tr key={index}>
              <th>{index + 1}</th>
              <td>{row.name}</td>
              <td>{row.dob}</td>
              <td>{row.gender}</td>
              <td>{row.email}</td>
              <td>{row.phone}</td>
              <td>{row.terms}</td>
              <td>{row.enrolments}</td>
              <td>{row.vouchers}</td>
              {isAdmin && <td>{formatter.format(row.spend / 100)}</td>}
              <td>{row.recent}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr className="text-left">
            <th></th>
            <th>Name</th>
            <th>DoB</th>
            <th>Gender</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Enrolments</th>
            <th>Vouchers</th>
            <th>Terms</th>
            <th>Spend</th>
            <th>Recent</th>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
