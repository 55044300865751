import { useState, useEffect } from "react";

export const ScheduleFilter = (props) => {
  const { groups, locations, programs, terms } = props.filters;
  const { populate } = props;

  const [term, setTerm] = useState(null);
  const [location, setLocation] = useState(null);
  const [program, setProgram] = useState(null);
  const [group, setGroup] = useState(null);
  const [filter, setFilter] = useState({
    termId: "",
    locationId: "",
    programId: "",
    groupId: "",
  });

  const handleChange = (event) => {
    let name = event.target.name;
    let data = JSON.parse(event.target.value);
    switch (name) {
      case "term":
        setTerm(JSON.stringify(data));
        setFilter({ ...filter, termId: data.key });
        break;
      case "location":
        setLocation(JSON.stringify(data));
        setFilter({ ...filter, locationId: data.key });
        break;
      case "program":
        setProgram(JSON.stringify(data));
        setFilter({ ...filter, programId: data.key });
        break;
      case "group":
        setGroup(JSON.stringify(data));
        setFilter({ ...filter, groupId: data.key });
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    if (term || location || group || program) {
      populate({
        variables: {
          input: {
            termId: filter.termId,
            locationId: filter.locationId,
            programId: filter.programId,
            groupId: filter.groupId,
          },
        },
      });
    }
  }, [term, location, program, group, filter, populate]);

  return (
    <div>
      <form>
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 pt-4 pb-6">
          {/* terms */}
          <div className="sm:col-span-2 md:col-span-3">
            <label className="label">
              <span className="label-text font-semibold">Term</span>
            </label>
            <select
              name="term"
              defaultValue="Select"
              className="select select-bordered w-full bg-white"
              onChange={handleChange}
            >
              <option disabled>Select</option>
              {terms &&
                terms.map((data, index) => {
                  return (
                    <option
                      key={index}
                      value={JSON.stringify({ key: data.id, value: data.name })}
                    >
                      {`${data.name} (${data.start} - ${data.end})`}
                    </option>
                  );
                })}
            </select>
          </div>
          {/* locations */}
          {term && (
            <div className="sm:col-span-2 md:col-span-1">
              <label className="label">
                <span className="label-text font-semibold">Studio</span>
              </label>
              <select
                name="location"
                defaultValue="Select"
                className="select select-bordered w-full bg-white"
                onChange={handleChange}
              >
                <option disabled>Select</option>
                {locations &&
                  locations.map((data, index) => {
                    return (
                      <option
                        key={index}
                        value={JSON.stringify({
                          key: data.id,
                          value: data.name,
                        })}
                      >
                        {data.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          )}
          {/* programs */}
          {location && (
            <div>
              <label className="label">
                <span className="label-text font-semibold">Program</span>
              </label>
              <select
                name="program"
                defaultValue="Select"
                className="select select-bordered w-full bg-white"
                onChange={handleChange}
              >
                <option disabled>Select</option>
                {programs &&
                  programs.map((data, index) => {
                    return (
                      <option
                        key={index}
                        value={JSON.stringify({
                          key: data.id,
                          value: data.name,
                        })}
                      >
                        {data.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          )}
          {/* age groups */}
          {program && (
            <div>
              <label className="label">
                <span className="label-text font-semibold">Age Group</span>
              </label>
              <select
                name="group"
                defaultValue="Select"
                className="select select-bordered w-full bg-white"
                onChange={handleChange}
              >
                <option disabled>Select</option>
                {groups &&
                  groups.map((data, index) => {
                    return (
                      <option
                        key={index}
                        value={JSON.stringify({
                          key: data.id,
                          value: data.name,
                        })}
                      >
                        {data.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};
