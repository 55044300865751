import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CREATE_TEMPLATE, UPDATE_TEMPLATE } from "../../utils/mutations";
import { TEMPLATE_INFO, LIST_TEMPLATES } from "../../utils/queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { parseDateTime, Tomorrow } from "../../utils/helpers";
import { templateCategories as Categories } from "../../utils/constants";

export const TemplateForm = (props) => {
  const { id } = props;
  const navigate = useNavigate();

  const [categories, setCategories] = useState(Categories);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [info, setInfo] = useState(null);

  const { register, setValue, watch, handleSubmit } = useForm();

  const notifyError = () => toast.error("Something went wrong!");
  const notifySuccess = () => toast.success("Updated successfully!");

  const [prefill, { data: prefillData }] = useLazyQuery(TEMPLATE_INFO, {
    variables: { templateId: id },
    onError: () => notifyError(),
  });
  const [createUser, { loading: creating }] = useMutation(CREATE_TEMPLATE, {
    refetchQueries: [{ query: LIST_TEMPLATES }],
    onCompleted: (data) => {
      if (data.template) {
        notifySuccess();
        navigate(`/config/templates`);
      }
    },
    onError: () => notifyError(),
  });
  const [updateUser, { loading: updating }] = useMutation(UPDATE_TEMPLATE, {
    refetchQueries: [{ query: TEMPLATE_INFO, variables: { templateId: id } }],
    onCompleted: (data) => {
      if (data.updated) {
        notifySuccess();
        navigate(`/config/templates`);
      }
    },
    onError: () => notifyError(),
  });

  const selectedType = watch("template.type");

  const onTypeChange = (e) => {
    const type = e.target.value;
    const filtered = Categories.filter((c) => c.type === type);
    if (type && type !== "") {
      setCategories(filtered);
    }
  };

  const onCategoryChange = (e) => {
    const category = e.target.value;
    const selected = Categories.find((c) => c.value === category);
    setSelectedCategory(selected);
  };

  const onSubmit = (data) => {
    const {
      type,
      category,
      subject,
      headline,
      body,
      status,
      actionLabel,
      actionLink,
      trigger,
    } = data.template;

    if (id) {
      updateUser({
        variables: {
          input: {
            id: id,
            type,
            category,
            body,
            status,
            ...(subject && { subject }),
            ...(headline && { headline }),
            ...(actionLabel && { actionLabel }),
            ...(actionLink && { actionLink }),
            ...(trigger && { trigger }),
          },
        },
      });
    } else {
      createUser({
        variables: {
          input: {
            type,
            category,
            body,
            status,
            ...(subject && { subject }),
            ...(headline && { headline }),
            ...(actionLabel && { actionLabel }),
            ...(actionLink && { actionLink }),
            ...(trigger && { trigger }),
          },
        },
      });
    }
  };

  useEffect(() => {
    if (id) {
      prefill();
    }
  }, [id, prefill]);

  useEffect(() => {
    if (prefillData) {
      setInfo(prefillData.info);
    }
  }, [prefillData]);

  useEffect(() => {
    if (info) {
      const {
        type,
        category,
        subject,
        headline,
        body,
        status,
        action,
        trigger,
      } = info;

      setCategories(Categories.filter((c) => c.type === type));
      setSelectedCategory(Categories.find((c) => c.value === category));

      setValue("template.type", type);
      setValue("template.category", category);
      setValue("template.body", body);
      setValue("template.status", status);
      if (type === "EMAIL") {
        setValue("template.subject", subject);
        setValue("template.headline", headline);
        setValue("template.actionLabel", action.label);
        setValue("template.actionLink", action.link);
      }
      if (trigger) setValue("template.trigger", parseDateTime(trigger));
    }
  }, [info, setValue]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* type: SMS / Email */}
        <div>
          <label className="label">
            <span className="label-text font-semibold">Type</span>
          </label>
          <select
            className="input input-bordered w-full max-w-md bg-white"
            {...register("template.type", {
              onChange: (e) => onTypeChange(e),
            })}
            required
            defaultValue={""}
            disabled={id}
          >
            <option disabled value="">
              Select Type
            </option>
            <option value="SMS">SMS</option>
            <option value="EMAIL">Email</option>
          </select>
        </div>
        {/* category */}
        <div>
          <label className="label">
            <span className="label-text font-semibold">Category</span>
          </label>
          <select
            className="input input-bordered w-full max-w-md bg-white"
            {...register("template.category", {
              onChange: (e) => onCategoryChange(e),
            })}
            required
            defaultValue={""}
            disabled={id}
          >
            <option disabled value="">
              Select Category
            </option>
            {categories.map((c) => (
              <option key={c.value} value={c.value}>
                {c.label}
              </option>
            ))}
          </select>
        </div>
        {/* Note: for template values */}
        <p className="text-sm border-t py-4 w-full max-w-md">
          <span className="font-semibold">Note:</span> for dynamic values, use{" "}
          <span className="font-semibold">%value%</span> format.
          <br />
          <br />
          Ex. <span className="font-semibold">Dear %student_name%,</span>
          {/* comma seperated values */}
          <br />
          <br />
          {selectedCategory && selectedCategory.label && (
            <>
              Accessible dynamic values are:{" "}
              <span className="font-semibold">
                {selectedCategory?.variables?.join(", ")}
              </span>
            </>
          )}
        </p>
        {/* subject (if type is Email) */}
        {selectedType === "EMAIL" && (
          <div>
            <label className="label">
              <span className="label-text font-semibold">Subject</span>
            </label>
            <input
              className="input input-bordered w-full max-w-md bg-white"
              {...register("template.subject")}
              required
            />
          </div>
        )}
        {/* headline (if type is Email) */}
        {selectedType === "EMAIL" && (
          <div>
            <label className="label">
              <span className="label-text font-semibold">Headline</span>
            </label>
            <input
              className="input input-bordered w-full max-w-md bg-white"
              {...register("template.headline")}
              required
            />
          </div>
        )}
        {/* body */}
        <div>
          <label className="label">
            <span className="label-text font-semibold">Body</span>
          </label>
          <textarea
            rows={10}
            className="textarea textarea-bordered w-full max-w-md bg-white"
            {...register("template.body")}
            required
          />
        </div>
        {/* button label (if type is Email) */}
        {selectedType === "EMAIL" && (
          <div>
            <label className="label">
              <span className="label-text font-semibold">Button Label</span>
            </label>
            <input
              className="input input-bordered w-full max-w-md bg-white"
              {...register("template.actionLabel")}
            />
          </div>
        )}
        {/* button link (if type is Email) */}
        {selectedType === "EMAIL" && (
          <div>
            <label className="label">
              <span className="label-text font-semibold">Button Link</span>
            </label>
            <input
              className="input input-bordered w-full max-w-md bg-white"
              {...register("template.actionLink")}
            />
          </div>
        )}
        {/* trigger date (only if category contains RENEWAL) */}
        {selectedCategory?.trigger && (
          <div>
            <label className="label">
              <span className="label-text font-semibold">Trigger Date</span>
            </label>
            <input
              className="input input-bordered w-full max-w-md bg-white"
              {...register("template.trigger")}
              type="datetime-local"
              step="1800"
              min={Tomorrow}
              required
            />
          </div>
        )}
        {/* status: Active / Inactive */}
        <div>
          <label className="label">
            <span className="label-text font-semibold">Status</span>
          </label>
          <select
            className="input input-bordered w-full max-w-md bg-white"
            {...register("template.status")}
            required
          >
            <option value="ACTIVE">Active</option>
            <option value="INACTIVE">Inactive</option>
          </select>
        </div>
        {/* submit */}
        <div className="pt-4">
          <button
            type="submit"
            className={creating || updating ? "btn loading" : "btn"}
          >
            {id ? "Update" : "Create"}
          </button>
        </div>
      </form>
    </div>
  );
};
