import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { PublicLayout } from "../layout/Public";
import { useState, useEffect } from "react";
import { useMutation, gql } from "@apollo/client";
import toast from "react-hot-toast";

function Reset(props) {
  const [loader, setLoader] = useState(false);

  const notifySuccess = () => toast.success("New password sent to your email");
  const notifyError = () => toast.error("No account found with this email");

  const RESET_PASSWORD = gql`
    mutation Reset($input: userProfile) {
      reset(input: $input)
    }
  `;

  const { register, handleSubmit, reset } = useForm();

  const [resetPassword, { loading, data, error }] = useMutation(RESET_PASSWORD);

  const onSubmit = (data) => {
    console.log(data);
    const { email } = data.forgot;
    resetPassword({ variables: { input: { email } } });
  };

  useEffect(() => {
    if (loading) setLoader(true);
  }, [loading]);

  useEffect(() => {
    if (data) {
      setLoader(false);
      reset({ forgot: { email: "" } });
      notifySuccess();
    }
  }, [data, reset]);

  useEffect(() => {
    if (error) {
      setLoader(false);
      reset({ forgot: { email: "" } });
      notifyError();
    }
  }, [error, reset]);

  return (
    <PublicLayout title="Reset Admin Password | Swastik App">
      <div className="text-center pb-4">
        <h1 className="text-4xl font-bold">Reset</h1>
        <div className="text-center pt-4">
          <p className="text-gray-600">
            Please provide your existing account email address to receive a new
            password
          </p>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
        <div>
          <label className="label">
            <span className="label-text font-semibold">Email address</span>
          </label>
          <input
            type="email"
            placeholder="john.doe@email.com"
            className="input input-bordered w-full"
            {...register("forgot.email")}
            required
          />
        </div>
        <div className="pt-4">
          <button
            type="submit"
            className={
              loader
                ? "btn btn-primary w-full loading"
                : "btn btn-primary w-full"
            }
          >
            Reset Password
          </button>
        </div>
      </form>
      <div className="text-center pt-6">
        <Link to="/login" className="text-secondary">
          Back to login
        </Link>
      </div>
    </PublicLayout>
  );
}

export default Reset;
