import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { REPORT_ATTENDANCE } from "../../utils/queries";
import toast from "react-hot-toast";

export const AttendanceReport = (props) => {
  const { csv } = props;

  const [list, setList] = useState([]);
  const [options, setOptions] = useState({
    terms: [],
    programs: [],
    groups: [],
    locations: [],
  });
  const [filter, setFilter] = useState({
    term: null,
    program: null,
    group: null,
    location: null,
    attendance: null,
  });
  const [filtered, setFiltered] = useState([]);

  const { loading, data } = useQuery(REPORT_ATTENDANCE, {
    onCompleted: (data) => {
      if (data.report) {
        setList(data.report);
        setFiltered(data.report);

        // create a list of unique terms, programs and locations
        const terms = [...new Set(data.report.map((item) => item.term))];
        const programs = [...new Set(data.report.map((item) => item.program))];
        const groups = [...new Set(data.report.map((item) => item.group))];
        const locations = [
          ...new Set(data.report.map((item) => item.location)),
        ];

        setOptions({
          terms,
          programs,
          groups,
          locations,
        });
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  useEffect(() => {
    let filtered = [...list];
    if (filter.term) {
      filtered = filtered.filter((item) => item.term === filter.term);
    }
    if (filter.program) {
      filtered = filtered.filter((item) => item.program === filter.program);
    }
    if (filter.group) {
      filtered = filtered.filter((item) => item.group === filter.group);
    }
    if (filter.location) {
      filtered = filtered.filter((item) => item.location === filter.location);
    }
    if (filter.attendance) {
      const values = filter.attendance.split(",").map((item) => parseInt(item));
      filtered = filtered.filter(
        (item) => item.attendance >= values[0] && item.attendance < values[1]
      );
    }
    setFiltered(filtered);
  }, [filter, list]);

  useEffect(() => {
    const formatted =
      filtered.length > 0
        ? filtered.map((item) => ({
            name: item.name,
            email: item.email,
            phone: item.phone,
            class: item.class,
            term: item.term,
            program: item.program,
            group: item.group,
            location: item.location,
            attendance: `${item.attendance}%`,
          }))
        : [];
    csv(formatted);
  }, [filtered, csv]);

  if (loading) return <p>Generating report...</p>;

  return (
    <div className="flex flex-col py-6">
      {data && (
        <>
          <AttendanceReportList
            data={filtered}
            options={options}
            filter={filter}
            setFilter={setFilter}
          />
        </>
      )}
    </div>
  );
};

const AttendanceReportList = (props) => {
  const { data, options, filter, setFilter } = props;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="overflow-x-auto bg-white rounded-lg shadow mt-4">
      {/* filters */}
      <div className="px-4 flex flex-row justify-between items-center gap-4 border-b">
        {/* dropdown for term, program, location */}
        <div>
          <select
            name="term"
            className="select select-md w-full max-w-max bg-white"
            value={filter.term || ""}
            onChange={handleChange}
          >
            <option disabled value="">
              Filter by Term
            </option>
            {options.terms.map((term, index) => (
              <option key={index} value={term}>
                {term}
              </option>
            ))}
          </select>

          <select
            name="program"
            className="select select-md w-full max-w-max bg-white"
            value={filter.program || ""}
            onChange={handleChange}
          >
            <option disabled value="">
              Filter by Program
            </option>
            {options.programs.map((program, index) => (
              <option key={index} value={program}>
                {program}
              </option>
            ))}
          </select>

          <select
            name="group"
            className="select select-md w-full max-w-max bg-white"
            value={filter.group || ""}
            onChange={handleChange}
          >
            <option disabled value="">
              Filter by Group
            </option>
            {options.groups.map((group, index) => (
              <option key={index} value={group}>
                {group}
              </option>
            ))}
          </select>

          <select
            name="location"
            className="select select-md w-full max-w-max bg-white"
            value={filter.location || ""}
            onChange={handleChange}
          >
            <option disabled value="">
              Filter by Location
            </option>
            {options.locations.map((location, index) => (
              <option key={index} value={location}>
                {location}
              </option>
            ))}
          </select>

          <select
            name="attendance"
            className="select select-md w-full max-w-max bg-white"
            value={filter.attendance || ""}
            onChange={handleChange}
          >
            <option disabled value="">
              Filter by Attendance
            </option>
            <option value={[0, 1]}>Zero</option>
            <option value={[1, 26]}>Upto 25%</option>
            <option value={[26, 51]}>26% to 50%</option>
            <option value={[51, 76]}>51% to 75%</option>
            <option value={[76, 91]}>76% to 90%</option>
            <option value={[91, 101]}>91% to 100%</option>
          </select>
        </div>

        {/* reset button */}
        <button className="btn btn-sm btn-ghost" onClick={() => setFilter({})}>
          Reset
        </button>
      </div>
      <table className="table-compact w-full divide-y">
        <thead>
          <tr className="text-left">
            <th></th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Class</th>
            <th>Term</th>
            <th>Program</th>
            <th>Location</th>
            <th>Attendance</th>
          </tr>
        </thead>
        <tbody className="divide-y">
          {data.map((row, index) => (
            <tr key={index}>
              <th>{index + 1}</th>
              <td>{row.name}</td>
              <td>{row.email}</td>
              <td>{row.phone}</td>
              <td>{row.class}</td>
              <td>{row.term}</td>
              <td>{row.program}</td>
              <td>{row.location}</td>
              <td>{row.attendance} %</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr className="text-left">
            <th></th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Class</th>
            <th>Term</th>
            <th>Program</th>
            <th>Location</th>
            <th>Attendance</th>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
