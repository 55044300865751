import { useNavigate, useParams } from "react-router-dom";
import { UserLayout } from "../layout/User";
import { SectionHead } from "../library/section/Head";
import { SessionDetails } from "../library/details/Session";
import { RosterTable } from "../library/table/Roster";
import { SESSION_DETAILS } from "../utils/queries";
import { useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import { AllocateModal } from "../library/modal/Allocate";
import { AllocateForm } from "../library/form/Allocate";
import { CommentsList } from "../library/list/Comments";
import { AssessmentsList } from "../library/list/Assessments";

function Session(props) {
  const { id } = useParams();
  const navigate = useNavigate();

  const notifyError = () => toast.error("Unable to get details!");

  const { data } = useQuery(SESSION_DETAILS, {
    variables: { sessionId: id },
    onError: (error) => {
      console.log(error);
      if (error) notifyError();
    },
  });

  return (
    <UserLayout title="Session Details">
      <section>
        <SectionHead heading="Session Details">
          <AllocateModal
            title="Update team"
            content="Update teacher and assistant for the session, this operation will overwrite any existing session teacher and assistant for this session"
            btn="btn btn-outline"
            label="Update Team"
          >
            <AllocateForm type="session" id={id} />
          </AllocateModal>
          <button className="btn btn-outline" onClick={() => navigate(-1)}>
            Back
          </button>
        </SectionHead>
        <div>
          {data && data.details && (
            <SessionDetails
              details={{
                name: data.details.name,
                class: data.details.class,
                date: data.details.date,
                time: data.details.time,
                teacher: data.details.teacher,
                assistant: data.details.assistant,
                duration: data.details.duration,
                status: data.details.status,
              }}
            />
          )}
          {data && data.details && data.details.assessments.length > 0 && (
            <AssessmentsList
              sessionId={id}
              assessments={data.details.assessments}
            />
          )}
          {data && data.details && (
            <RosterTable sessionId={id} roster={data.details.roster} />
          )}
        </div>
      </section>
      <section>
        <div className="px-2 py-6">
          <h3 className="font-medium mb-4 label-text">Comments</h3>
          <CommentsList type="Class" id={id} commenting={true} />
        </div>
      </section>
    </UserLayout>
  );
}

export default Session;
