import { Link } from "react-router-dom";

export const TodayTable = (props) => {
  const { list } = props;

  return (
    <>
      {list?.length > 0 && (
        <div>
          <label className="label">
            <span className="label-text font-semibold">
              Today's class sessions
            </span>
          </label>
          <div className="overflow-x-auto rounded-xl shadow-md">
            <table className="table-normal bg-white rounded-xl w-full">
              {/* head */}
              <thead className="text-left border-b">
                <tr>
                  <th></th>
                  <th>Class</th>
                  <th>Session</th>
                  <th>Time</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="divide-y">
                {list.map((data, index) => (
                  <tr key={index}>
                    <th>{index + 1}</th>
                    <td className="font-bold">{data.class}</td>
                    <td>{data.name}</td>
                    <td>{data.time}</td>
                    <td>
                      <span
                        className={`text-xs py-1 px-2 rounded-md text-white ${statusTagColor(
                          data.status
                        )}`}
                      >
                        {data.status}
                      </span>
                    </td>
                    <td className="flex gap-2">
                      <Link
                        to={`/session/${data.id}`}
                        className="btn btn-xs btn-ghost"
                      >
                        view session
                      </Link>
                      <Link
                        to={`/class/${data.classId}`}
                        className="btn btn-xs btn-ghost"
                      >
                        view class
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

function statusTagColor(str) {
  switch (str) {
    case "UPCOMING":
      return `bg-warning`;
    case "ONGOING":
      return `bg-info`;
    default:
      return `bg-base-300`;
  }
}
