import { useEffect, useState } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { LIST_TERMS, REPORT_ATTRITION } from "../../utils/queries";
import toast from "react-hot-toast";
import { formatMobile } from "../../utils/helpers";

export const StudentsAttritionReport = (props) => {
  const { csv } = props;

  const [list, setList] = useState([]);

  const { data: terms } = useQuery(LIST_TERMS, {
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const [getReport, { loading }] = useLazyQuery(REPORT_ATTRITION, {
    onCompleted: (data) => {
      if (data.report) {
        setList(data.report);
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
    fetchPolicy: "network-only",
  });

  const reset = () => {
    setList([]);
    csv([]);
  };

  useEffect(() => {
    if (list.length > 0) {
      const formatted = list.map((item) => ({
        name: item.name ?? "-",
        age: item.age,
        gender: item.gender ?? "-",
        email: item.email,
        phone: item.phone ?? "-",
      }));

      csv(formatted);
    } else {
      csv([]);
    }
  }, [list, csv]);

  return (
    <div className="flex flex-col gap-6">
      <StudentsActivityReportList
        terms={terms?.terms ?? []}
        fetch={getReport}
        data={list}
        loading={loading}
        reset={reset}
      />
    </div>
  );
};

const StudentsActivityReportList = (props) => {
  const { terms, fetch, data, loading, reset } = props;

  const [termA, setTermA] = useState(null);
  const [termB, setTermB] = useState(null);

  return (
    <div className="overflow-x-auto bg-white rounded-lg shadow mt-4">
      {/* filters */}
      <div className="px-4 flex flex-row justify-between items-center gap-4 border-b">
        {/* dropdown for termA, termB */}
        <div>
          <select
            name="terms"
            className="select select-md w-full max-w-max bg-white"
            value={termA || ""}
            onChange={(e) => setTermA(e.target.value)}
          >
            <option disabled value="">
              Select Base Term
            </option>
            {terms &&
              terms.map((term, index) => (
                <option key={index} value={term.id}>
                  {term.name}
                </option>
              ))}
          </select>

          {termA && (
            <select
              name="terms"
              className="select select-md w-full max-w-max bg-white"
              value={termB || ""}
              onChange={(e) => setTermB(e.target.value)}
            >
              <option disabled value="">
                Select Comparison Term
              </option>
              {terms &&
                terms.map((term, index) => (
                  <option
                    key={index}
                    value={term.id}
                    disabled={term?.id === termA}
                  >
                    {term.name}
                  </option>
                ))}
            </select>
          )}

          {/* generate button */}
          {termA && termB && (
            <button
              className="btn btn-sm btn-ghost"
              onClick={() => fetch({ variables: { termA, termB } })}
            >
              Generate Report
            </button>
          )}
        </div>

        {/* reset button */}
        <button
          className="btn btn-sm btn-ghost"
          onClick={() => {
            reset();
            setTermA(null);
            setTermB(null);
          }}
        >
          Reset
        </button>
      </div>

      {loading && <div className="p-4">Loading...</div>}

      {data && data.length > 0 && (
        <table className="table-compact w-full divide-y">
          <thead>
            <tr className="text-left">
              <th></th>
              <th>Name</th>
              <th>Age</th>
              <th>Gender</th>
              <th>Email</th>
              <th>Phone</th>
            </tr>
          </thead>
          <tbody className="divide-y">
            {data.map((row, index) => (
              <tr key={index}>
                <th>{index + 1}</th>
                <td>{row.name}</td>
                <td>{row.age}</td>
                <td>{row.gender}</td>
                <td>{row.email}</td>
                <td>{formatMobile(row?.phone) ?? "-"}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr className="text-left">
              <th></th>
              <th>Name</th>
              <th>Age</th>
              <th>Gender</th>
              <th>Email</th>
              <th>Phone</th>
            </tr>
          </tfoot>
        </table>
      )}
    </div>
  );
};
