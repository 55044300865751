import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { REPORT_ENROLMENTS } from "../../utils/queries";
import toast from "react-hot-toast";
import { formatter, enumToLabel, truncateString } from "../../utils/helpers";

export const EnrolmentsReport = (props) => {
  const { csv } = props;

  const [list, setList] = useState([]);
  const [options, setOptions] = useState({
    terms: [],
    methods: [],
    locations: [],
    programs: [],
    groups: [],
    statuses: [],
  });
  const [filter, setFilter] = useState({
    term: null,
    method: null,
    location: null,
    program: null,
    group: null,
    status: null,
  });
  const [filtered, setFiltered] = useState([]);

  const { loading, data } = useQuery(REPORT_ENROLMENTS, {
    onCompleted: (data) => {
      if (data.report) {
        setList(data.report);
        setFiltered(data.report);
        // create a list of unique terms, methods, locations and statuses
        const terms = [...new Set(data.report.map((item) => item.term))];
        const methods = [...new Set(data.report.map((item) => item.method))];
        const locations = [
          ...new Set(data.report.map((item) => item.location)),
        ];
        const programs = [...new Set(data.report.map((item) => item.program))];
        const groups = [...new Set(data.report.map((item) => item.group))];
        const statuses = [...new Set(data.report.map((item) => item.status))];

        setOptions({
          terms,
          methods,
          locations,
          programs,
          groups,
          statuses,
        });
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  useEffect(() => {
    let filtered = [...list];
    if (filter.term) {
      filtered = filtered.filter((item) => item.term === filter.term);
    }
    if (filter.method) {
      filtered = filtered.filter((item) => item.method === filter.method);
    }
    if (filter.location) {
      filtered = filtered.filter((item) => item.location === filter.location);
    }
    if (filter.program) {
      filtered = filtered.filter((item) => item.program === filter.program);
    }
    if (filter.group) {
      filtered = filtered.filter((item) => item.group === filter.group);
    }
    if (filter.status) {
      filtered = filtered.filter((item) => item.status === filter.status);
    }
    setFiltered(filtered);
  }, [filter, list]);

  useEffect(() => {
    const formatted =
      filtered.length > 0
        ? filtered.map((item) => ({
            name: item.name,
            email: item.email,
            class: item.class,
            term: item.term,
            location: item.location,
            program: item.program,
            group: item.group,
            method: enumToLabel(item.method),
            ref: item.ref,
            amount: formatter.format(item.amount / 100),
            voucher: formatter.format(item.voucher),
            refund: formatter.format(item.refund / 100),
            status: item.status,
          }))
        : [];

    csv(formatted);
  }, [filtered, csv]);

  if (loading) return <p>Generating report...</p>;

  return (
    <div className="flex flex-col gap-6">
      {data && (
        <>
          <EnrolmentsReportList
            data={filtered}
            options={options}
            filter={filter}
            setFilter={setFilter}
          />
        </>
      )}
    </div>
  );
};

const EnrolmentsReportList = (props) => {
  const { data, options, filter, setFilter } = props;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="overflow-x-auto bg-white rounded-lg shadow mt-4">
      {/* filters */}
      <div className="px-4 flex flex-row justify-between items-center gap-4 border-b">
        {/* dropdown for term, program, location */}
        <div>
          <select
            name="term"
            className="select select-md w-full max-w-max bg-white"
            value={filter.term || ""}
            onChange={handleChange}
          >
            <option disabled value="">
              Filter by Term
            </option>
            {options.terms.map((term, index) => (
              <option key={index} value={term}>
                {term}
              </option>
            ))}
          </select>

          <select
            name="method"
            className="select select-md w-full max-w-max bg-white"
            value={filter.method || ""}
            onChange={handleChange}
          >
            <option disabled value="">
              Filter by Method
            </option>
            {options.methods.map((method, index) => (
              <option key={index} value={method}>
                {enumToLabel(method)}
              </option>
            ))}
          </select>

          <select
            name="location"
            className="select select-md w-full max-w-max bg-white"
            value={filter.location || ""}
            onChange={handleChange}
          >
            <option disabled value="">
              Filter by Location
            </option>
            {options.locations.map((location, index) => (
              <option key={index} value={location}>
                {location}
              </option>
            ))}
          </select>

          <select
            name="program"
            className="select select-md w-full max-w-max bg-white"
            value={filter.program || ""}
            onChange={handleChange}
          >
            <option disabled value="">
              Filter by Program
            </option>
            {options.programs.map((program, index) => (
              <option key={index} value={program}>
                {program}
              </option>
            ))}
          </select>

          <select
            name="group"
            className="select select-md w-full max-w-max bg-white"
            value={filter.group || ""}
            onChange={handleChange}
          >
            <option disabled value="">
              Filter by Group
            </option>
            {options.groups.map((group, index) => (
              <option key={index} value={group}>
                {group}
              </option>
            ))}
          </select>

          <select
            name="status"
            className="select select-md w-full max-w-max bg-white"
            value={filter.status || ""}
            onChange={handleChange}
          >
            <option disabled value="">
              Filter by Status
            </option>
            {options.statuses.map((status, index) => (
              <option key={index} value={status}>
                {status}
              </option>
            ))}
          </select>
        </div>

        {/* reset button */}
        <button className="btn btn-sm btn-ghost" onClick={() => setFilter({})}>
          Reset
        </button>
      </div>
      <table className="table-compact w-full divide-y">
        <thead>
          <tr className="text-left">
            <th></th>
            <th>Name</th>
            <th>Class</th>
            <th>Method</th>
            <th>Ref</th>
            <th>Amount</th>
            <th>Voucher</th>
            <th>Refund</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody className="divide-y">
          {data.map((row, index) => (
            <tr key={index}>
              <th>{index + 1}</th>
              <td>{row.name}</td>
              <td>{row.class}</td>
              <td>{enumToLabel(row.method)}</td>
              <td>{truncateString(row.ref ?? "-", 30)}</td>
              <td>{formatter.format(row.amount / 100)}</td>
              <td>{formatter.format(row.voucher)}</td>
              <td>{formatter.format(row.refund / 100)}</td>
              <td>{row.status}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr className="text-left">
            <th></th>
            <th>Name</th>
            <th>Class</th>
            <th>Method</th>
            <th>Ref</th>
            <th>Amount</th>
            <th>Voucher</th>
            <th>Refund</th>
            <th>Status</th>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
