import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { UPDATE_CUSTOMER } from "../../utils/mutations";
import { LIST_CUSTOMERS } from "../../utils/queries";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";

export const CustomerForm = (props) => {
  const { customer } = props;

  const [loader, setLoader] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  const { register, setValue, handleSubmit } = useForm();

  const notifyError = () => toast.error("Something went wrong!");
  const notifySuccess = () => toast.success("Information updated!");

  const [createCustomer, { loading, data, error }] = useMutation(
    UPDATE_CUSTOMER,
    {
      refetchQueries: [{ query: LIST_CUSTOMERS }],
      onCompleted() {
        setIsComplete(true);
        notifySuccess();
        setLoader(false);
      },
    }
  );

  const onSubmit = (data) => {
    const { email } = data.customer;
    createCustomer({
      variables: {
        input: {
          id: customer.id,
          email: email.toLowerCase(),
        },
      },
    });
  };

  useEffect(() => {
    if (loading) setLoader(true);
  }, [loading]);

  useEffect(() => {
    console.log(error);
    if (error) {
      setLoader(false);
      notifyError();
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      setLoader(false);
    }
  }, [data]);

  useEffect(() => {
    if (customer?.email) setValue("customer.email", customer.email);
  }, [customer, setValue]);

  return (
    <div>
      {!isComplete && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label className="label">
              <span className="label-text font-semibold">Email address</span>
            </label>
            <input
              type="email"
              placeholder="john.doe@email.com"
              className="input input-bordered w-full max-w-md bg-white"
              {...register("customer.email")}
              required
            />
          </div>
          <div className="pt-4 space-x-2">
            <button type="submit" className={loader ? "btn loading" : "btn"}>
              Update
            </button>
            <label htmlFor="update-modal" className="btn btn-outline">
              Cancel
            </label>
          </div>
        </form>
      )}
      {isComplete && (
        <>
          <p className="font-bold text-xl mb-6">Email updated successfully!</p>
          <label
            htmlFor="update-modal"
            className="btn btn-outline"
            onClick={() => {
              setIsComplete(false);
            }}
          >
            Close
          </label>
        </>
      )}
    </div>
  );
};
