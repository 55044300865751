import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import { UserLayout } from "../layout/User";
import { SectionHead } from "../library/section/Head";
import { LocationsTable } from "../library/table/Locations";
import { GroupsTable } from "../library/table/Groups";
import { ProgramsTable } from "../library/table/Programs";
import { TermsTable } from "../library/table/Terms";
import { TemplatesTable } from "../library/table/Templates";
import { ProductsTable } from "../library/table/Products";
import { HallsTable } from "../library/table/Halls";
import { UsersTable } from "../library/table/Users";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/20/solid";

function Settings(props) {
  const { type } = useParams();
  const { isAdmin, isManager, isReception } = useContext(AuthContext);

  const [createLink, setCreateLink] = useState(null);

  const navigate = useNavigate();

  const items = [
    {
      type: "users",
      label: "Admin Users",
      link: "/config/users",
      permission: isAdmin,
      component: <UsersTable />,
    },
    {
      type: "programs",
      label: "Programs",
      link: "/config/programs",
      permission: isAdmin || isManager,
      component: <ProgramsTable />,
    },
    {
      type: "groups",
      label: "Groups",
      link: "/config/groups",
      permission: isAdmin || isManager,
      component: <GroupsTable />,
    },
    {
      type: "locations",
      label: "Locations",
      link: "/config/locations",
      permission: isAdmin || isManager,
      component: <LocationsTable />,
    },
    {
      type: "halls",
      label: "Halls",
      link: "/config/halls",
      permission: isAdmin || isManager,
      component: <HallsTable />,
    },
    {
      type: "templates",
      label: "Templates",
      link: "/config/templates",
      permission: isAdmin || isManager || isReception,
      component: <TemplatesTable />,
    },
    {
      type: "terms",
      label: "Terms",
      link: "/config/terms",
      permission: isAdmin || isManager,
      component: <TermsTable />,
    },
    {
      type: "products",
      label: "Products",
      link: "/config/products",
      permission: isAdmin || isManager,
      component: <ProductsTable />,
    },
  ];

  const isValidType = items.find((item) => item.type === type) ? true : false;
  const allowedItems = items.filter((item) => item.permission);

  const getTitle = () => {
    if (type) {
      const item = items.find((item) => item.type === type);
      return item ? item.label : "Configuration";
    } else {
      return "Configuration";
    }
  };

  useEffect(() => {
    switch (type) {
      case "programs":
        setCreateLink("/create/program");
        break;
      case "groups":
        setCreateLink("/create/group");
        break;
      case "locations":
        setCreateLink("/create/location");
        break;
      case "templates":
        setCreateLink("/create/template");
        break;
      case "users":
        setCreateLink("/create/user");
        break;
      case "terms":
        setCreateLink("/create/term");
        break;
      case "products":
        setCreateLink("/create/product");
        break;
      case "halls":
        setCreateLink("/create/hall");
        break;
      default:
        setCreateLink(null);
    }
  }, [type]);

  return (
    <UserLayout title="Configuration">
      <section>
        <SectionHead heading={getTitle()}>
          {type && (
            <button
              onClick={() => navigate("/config")}
              className="btn btn-outline"
            >
              Back
            </button>
          )}
          {createLink && (
            <Link to={createLink} className="btn">
              Create New
            </Link>
          )}
        </SectionHead>
        {!type && (
          <div className="flex flex-col mt-6 bg-white rounded-xl shadow-md divide-y overflow-clip">
            {allowedItems.map((item, index) => (
              <Link
                key={index}
                to={item.link}
                className="p-4 flex flex-row justify-between items-center hover:bg-base-100"
              >
                <h3 className="text-lg font-bold tracking-wide">
                  {item.label}
                </h3>
                <ChevronRightIcon className="w-6 h-6 text-gray-500 p-1 bg-gray-300 rounded-full" />
              </Link>
            ))}
          </div>
        )}

        {type &&
          (isValidType ? (
            <div className="mt-6">
              {allowedItems.find((item) => item.type === type).component}
            </div>
          ) : (
            <Navigate to="/config" />
          ))}
      </section>
    </UserLayout>
  );
}

export default Settings;
