import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context/authContext";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import { UserLayout } from "../layout/User";
import { SectionHead } from "../library/section/Head";
import { ClassForm } from "../library/form/Class";
import { TermForm } from "../library/form/Term";
import { ProgramForm } from "../library/form/Program";
import { GroupForm } from "../library/form/Group";
import { LocationForm } from "../library/form/Location";
import { TemplateForm } from "../library/form/Template";
import { UserForm } from "../library/form/User";
import { HallForm } from "../library/form/Hall";
import { ProductForm } from "../library/form/Product";
import {
  REMOVE_TERM,
  REMOVE_CLASS,
  REMOVE_USER,
  REMOVE_TEMPLATE,
  REMOVE_HALL,
  REMOVE_PRODUCT,
} from "../utils/mutations";
import {
  LIST_TERMS,
  LIST_ADMIN_USERS,
  LIST_TEMPLATES,
  LIST_HALLS,
  LIST_PRODUCTS,
} from "../utils/queries";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";

function Edit(props) {
  const { type, id } = useParams();

  const { isAdmin } = useContext(AuthContext);

  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [canDelete, setCanDelete] = useState(true);

  const notifyError = () => toast.error("Something went wrong!");
  const notifySuccess = () => toast.success("Removed successfully!");

  const [removeTerm, { loading: termLoading }] = useMutation(REMOVE_TERM, {
    variables: { termId: id },
    onCompleted: () => {
      setLoader(false);
      notifySuccess();
      navigate(`/config/terms`);
    },
    onError: () => {
      setLoader(false);
      notifyError();
    },
    refetchQueries: [{ query: LIST_TERMS }],
  });

  const [removeClass, { loading: classLoading }] = useMutation(REMOVE_CLASS, {
    variables: { classId: id },
    onCompleted: () => {
      setLoader(false);
      notifySuccess();
      navigate(`/schedule/`);
    },
    onError: () => {
      setLoader(false);
      notifyError();
    },
  });

  const [removeUser, { loading: userLoading }] = useMutation(REMOVE_USER, {
    variables: { userId: id },
    onCompleted: () => {
      setLoader(false);
      notifySuccess();
      navigate(`/config/users`);
    },
    onError: () => {
      setLoader(false);
      notifyError();
    },
    refectQueries: [{ query: LIST_ADMIN_USERS }],
  });

  const [removeTemplate, { loading: templateLoading }] = useMutation(
    REMOVE_TEMPLATE,
    {
      variables: { templateId: id },
      onCompleted: () => {
        setLoader(false);
        notifySuccess();
        navigate(`/config/templates`);
      },
      onError: () => {
        setLoader(false);
        notifyError();
      },
      refectQueries: [{ query: LIST_TEMPLATES }],
    }
  );

  const [removeHall, { loading: hallLoading }] = useMutation(REMOVE_HALL, {
    variables: { hallId: id },
    onCompleted: () => {
      setLoader(false);
      notifySuccess();
      navigate(`/config/halls`);
    },
    onError: () => {
      setLoader(false);
      notifyError();
    },
    refectQueries: [{ query: LIST_HALLS }],
  });

  const [removeProduct, { loading: productLoading }] = useMutation(
    REMOVE_PRODUCT,
    {
      variables: { productId: id },
      onCompleted: () => {
        setLoader(false);
        notifySuccess();
        navigate(`/config/products`);
      },
      onError: () => {
        setLoader(false);
        notifyError();
      },
      refectQueries: [{ query: LIST_PRODUCTS }],
    }
  );

  const basedOnType = (param) => {
    switch (param) {
      case "class":
        return <ClassForm id={id} allowDelete={setCanDelete} />;
      case "term":
        return <TermForm id={id} allowDelete={setCanDelete} />;
      case "program":
        return <ProgramForm id={id} allowDelete={setCanDelete} />;
      case "group":
        return <GroupForm id={id} allowDelete={setCanDelete} />;
      case "location":
        return <LocationForm id={id} allowDelete={setCanDelete} />;
      case "template":
        return <TemplateForm id={id} allowDelete={setCanDelete} />;
      case "user":
        if (!isAdmin) return <Navigate to="/" />;
        return <UserForm id={id} allowDelete={setCanDelete} />;
      case "hall":
        return <HallForm id={id} allowDelete={setCanDelete} />;
      case "product":
        return <ProductForm id={id} allowDelete={setCanDelete} />;
      default:
        return <Navigate to={-1} />;
    }
  };

  const invokeDelete = () => {
    if (
      window.confirm(`Are you sure you want to delete the this information?`)
    ) {
      switch (type) {
        case "term":
          removeTerm();
          break;
        case "class":
          removeClass();
          break;
        case "user":
          removeUser();
          break;
        case "template":
          removeTemplate();
          break;
        case "hall":
          removeHall();
          break;
        case "product":
          removeProduct();
          break;
        default:
          window.alert("Not a valid type!");
      }
    }
  };

  useEffect(() => {
    if (
      termLoading ||
      classLoading ||
      userLoading ||
      templateLoading ||
      hallLoading ||
      productLoading
    )
      setLoader(true);
  }, [
    termLoading,
    classLoading,
    userLoading,
    templateLoading,
    hallLoading,
    productLoading,
  ]);

  useEffect(() => {
    if (type === "program" || type === "group" || type === "location") {
      setCanDelete(false);
    }
  }, [type, canDelete]);

  return (
    <UserLayout title={`Edit ${type}`}>
      <section>
        <SectionHead heading={`Edit ${type}`}>
          {canDelete && (
            <button
              className={
                loader
                  ? "btn btn-outline btn-error loading"
                  : "btn btn-outline btn-error"
              }
              onClick={() => invokeDelete()}
            >
              Delete
            </button>
          )}
          <button className="btn btn-outline" onClick={() => navigate(-1)}>
            Back
          </button>
        </SectionHead>
        {basedOnType(type)}
      </section>
    </UserLayout>
  );
}

export default Edit;
