import { LIST_TEMPLATES } from "../../utils/queries";
import { useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export const TemplatesTable = (props) => {
  const notifyError = () => toast.error("Something went wrong!");

  const { data, error } = useQuery(LIST_TEMPLATES);

  useEffect(() => {
    if (error) notifyError();
  }, [error]);

  return (
    <div>
      <div className="overflow-x-auto rounded-xl shadow-md">
        <table className="table-normal divide-y-2 bg-white rounded-xl w-full">
          {/* head */}
          <thead className="text-left">
            <tr>
              <th></th>
              <th>Name</th>
              <th>Type</th>
              <th>Trigger</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody className="divide-y-2">
            {data &&
              data.templates &&
              data.templates.map((value, index) => (
                <tr key={value.id}>
                  <th>{index + 1}</th>
                  <td className="font-bold">
                    {categoryToLabel(value.category)}
                  </td>
                  <td>{value.type}</td>
                  <td>{value.trigger ? value.trigger : "-"}</td>
                  <td>
                    <span
                      className={`text-xs py-1 px-2 rounded-md text-white ${statusTagColor(
                        value.status
                      )}`}
                    >
                      {value.status}
                    </span>
                  </td>
                  <td>
                    <Link
                      to={`/edit/template/${value.id}`}
                      className={`btn btn-xs btn-ghost`}
                    >
                      edit
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

function categoryToLabel(str) {
  // separate by ::
  const arr = str.split("::");

  // make all lowercase
  arr.forEach((value, index) => {
    arr[index] = value.toLowerCase();
  });
  // make first letter uppercase
  arr.forEach((value, index) => {
    arr[index] = value.charAt(0).toUpperCase() + value.slice(1);
  });

  // remove the last element
  arr.pop();

  // join with space
  return arr.join(" ");
}

function statusTagColor(str) {
  switch (str) {
    case "ACTIVE":
      return `bg-info`;
    default:
      return `bg-base-300`;
  }
}
