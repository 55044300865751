import { useState, useEffect, Fragment } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import { useLazyQuery } from "@apollo/client";
import { Combobox } from "@headlessui/react";
import toast from "react-hot-toast";
import clsx from "clsx";
import { USERS_LIST } from "../../utils/queries";

export const CustomerSelector = (props) => {
  const { selection, setSelection } = props;

  const [customers, setCustomers] = useState([]);
  const [query, setQuery] = useState("");

  const debouncedQuery = useDebounce(query, 500);

  const [getCustomers] = useLazyQuery(USERS_LIST, {
    onCompleted: (data) => {
      setCustomers(data.list.users);
    },
    onError: (error) => {
      toast.error(error.message);
    },
    fetchPolicy: "network-only",
  });

  const onClear = () => {
    setQuery("");
    setSelection(null);
  };

  useEffect(() => {
    if (debouncedQuery && debouncedQuery.length > 2) {
      getCustomers({
        variables: {
          search: debouncedQuery,
          limit: 5,
        },
      });
    } else {
      setCustomers([]);
    }
  }, [debouncedQuery, getCustomers]);

  return (
    <div className="relative border-b-2 pb-4">
      <label className="label">
        <span className="label-text font-semibold">Select a customer</span>
      </label>
      <Combobox value={selection?.email ?? null} onChange={setSelection}>
        <Combobox.Input
          className="relative w-full input bg-white border border-gray-300 rounded-md"
          placeholder="Search for customer"
          onChange={(e) => setQuery(e.target.value)}
        >
          {/* clear button */}
        </Combobox.Input>
        {selection && (
          <button
            className="btn btn-xs btn-ghost absolute right-2 mt-3"
            onClick={() => onClear()}
          >
            Clear
          </button>
        )}
        {customers.length > 0 && (
          <Combobox.Options className="absolute w-full mt-1 bg-white divide-y px-2 py-1 border border-gray-300 rounded-md">
            {customers.map((customer) => (
              <Combobox.Option key={customer.id} value={customer} as={Fragment}>
                {({ active, selected }) => (
                  <li
                    className={clsx(
                      "px-2 py-2 text-secondary-500 hover:bg-base-300 focus:bg-base-300 rounded cursor-pointer",
                      active && "bg-base-300",
                      selected && "bg-secondary-focus text-white"
                    )}
                  >
                    {customer.email} / {customer.phone}
                  </li>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </Combobox>
    </div>
  );
};
