import { Link } from "react-router-dom";
import { UserLayout } from "../layout/User";
import { SectionHead } from "../library/section/Head";
import { TeachersTable } from "../library/table/Teachers";
import { TeachersCalendar } from "../library/calendar/Teachers";

function Teachers(props) {
  const { view } = props;

  return (
    <UserLayout title="Teachers">
      <section>
        <SectionHead heading="Teachers">
          {view === "Table" && (
            <Link to={`/teachers/calendar`} className="btn btn-outline">
              View Calendar
            </Link>
          )}
          {view === "Calendar" && (
            <Link to={`/teachers`} className="btn btn-outline">
              View List
            </Link>
          )}
          <Link to={`/create/teacher`} className="btn">
            Add teacher
          </Link>
        </SectionHead>
      </section>
      <div className="pt-8 space-y-8">
        {view === "Table" && <TeachersTable />}
        {view === "Calendar" && <TeachersCalendar />}
      </div>
    </UserLayout>
  );
}

export default Teachers;
