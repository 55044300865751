import { statusTagColor } from "../../utils/helpers";

export const RostersList = (props) => {
  const { rosters } = props;

  return (
    <section className="mt-4">
      <div className="label">
        <span className="label-text font-semibold">Rosters</span>
      </div>
      <div className="bg-white rounded-xl p-4 divide-y">
        {rosters?.length > 0 ? (
          rosters.map((item) => (
            <div
              key={item.id}
              className="flex flex-col md:flex-row md:items-center justify-between gap-4 p-2"
            >
              <div className="flex items-center gap-4 w-full">
                <div className="flex flex-col md:flex-row gap-2 flex-grow justify-between">
                  <div>
                    <h3 className="text-lg font-medium">
                      {item.session?.class?.name ?? "-"}
                    </h3>
                    <p className="text-sm text-gray-500">
                      {item.session?.name}, {item.session?.start} -{" "}
                      {item.session?.end}
                    </p>
                  </div>
                  <div className="flex items-center gap-4">
                    <p
                      className={`text-xs py-1 px-2 rounded-md text-white ${statusTagColor(
                        item.session.status
                      )}`}
                    >
                      {item.session.status}
                    </p>
                    <p className="text-sm text-gray-900 font-medium">
                      {item.attended ? "Attended" : "Not Attended"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center text-gray-500">Rosters not found</p>
        )}
      </div>
    </section>
  );
};
