import { useContext } from "react";
import { AuthContext } from "../../context/authContext";

export const TermStats = (props) => {
  const { term, classes, sessions, enrolments, attendance, revenue } = props;

  const { isAdmin } = useContext(AuthContext);

  return (
    <div>
      <label className="label">
        <span className="label-text font-semibold">
          Overview ({term.status.toLowerCase()} term)
        </span>
      </label>
      <div className="stats shadow w-full">
        <div className="stat bg-white">
          <div className="stat-title">Classes</div>
          <div className="stat-value">{classes.toLocaleString()}</div>
        </div>

        <div className="stat bg-white">
          <div className="stat-title">Sessions</div>
          <div className="stat-value">{sessions.toLocaleString()}</div>
        </div>

        <div className="stat bg-white">
          <div className="stat-title">Enrolments</div>
          <div className="stat-value">{enrolments.toLocaleString()}</div>
        </div>

        <div className="stat bg-white">
          <div className="stat-title">Attendance</div>
          <div className="stat-value">
            {attendance === "NaN" ? 0 : attendance}%
          </div>
        </div>

        {isAdmin && (
          <div className="stat bg-white">
            <div className="stat-title">Revenue</div>
            <div className="stat-value">{formatter.format(revenue / 100)}</div>
          </div>
        )}
      </div>
    </div>
  );
};

const formatter = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD",
  notation: "compact",
});
