import { useContext } from "react";
import { AuthContext } from "../../context/authContext";
import { useQuery } from "@apollo/client";
import { ANALYTICS_LAST_PARAMS } from "../../utils/queries";
import toast from "react-hot-toast";
import { formatter } from "../../utils/helpers";

export const OverviewLastStats = (props) => {
  const { isAdmin } = useContext(AuthContext);

  const notifyError = (message) => toast.error(message);

  const { loading, data } = useQuery(ANALYTICS_LAST_PARAMS, {
    onError: (error) => {
      notifyError(error.message);
    },
    fetchPolicy: "network-only",
  });

  if (loading) return <></>;

  return (
    <div className="card bg-white shadow p-6 w-full h-full">
      <h3 className="sr-only">Overview (This)</h3>
      {data && (
        <div>
          <table className="table-auto w-full">
            <thead>
              <tr>
                <th></th>
                <th className="py-2">Enrolments</th>
                {isAdmin && <th className="py-2">Revenues</th>}
              </tr>
            </thead>
            <tbody className="divide-y">
              <tr>
                <td className="py-2 font-semibold">Last Week</td>
                <td className="text-center tracking-wider">
                  {data.last.enrolments.lastWeek}
                </td>
                {isAdmin && (
                  <td className="text-center tracking-wider">
                    {formatter.format(data.last.revenues.lastWeek / 100)}
                  </td>
                )}
              </tr>
              <tr>
                <td className="py-2 font-semibold">Last Month</td>
                <td className="text-center tracking-wider">
                  {data.last.enrolments.lastMonth}
                </td>
                {isAdmin && (
                  <td className="text-center tracking-wider">
                    {formatter.format(data.last.revenues.lastMonth / 100)}
                  </td>
                )}
              </tr>
              <tr>
                <td className="py-2 font-semibold">Last Quarter</td>
                <td className="text-center tracking-wider">
                  {data.last.enrolments.lastQuarter}
                </td>
                {isAdmin && (
                  <td className="text-center tracking-wider">
                    {formatter.format(data.last.revenues.lastQuarter / 100)}
                  </td>
                )}
              </tr>
              <tr>
                <td className="py-2 font-semibold">Last Year</td>
                <td className="text-center tracking-wider">
                  {data.last.enrolments.lastYear}
                </td>
                {isAdmin && (
                  <td className="text-center tracking-wider">
                    {formatter.format(data.last.revenues.lastYear / 100)}
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
