import { useState, useContext } from "react";
import { AuthContext } from "../context/authContext";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useLazyQuery } from "@apollo/client";
import { useDebounce } from "@uidotdev/usehooks";
import { CSVLink } from "react-csv";
import toast from "react-hot-toast";
import clsx from "clsx";
import { UserLayout } from "../layout/User";
import { SectionHead } from "../library/section/Head";
import { LoadingLayout } from "../layout/Loading";
import { OverviewSection } from "../library/section/Overview";
import { ScheduleList } from "../library/list/Schedule";
import { SessionsList } from "../library/list/Sessions";
import { TransactionSection } from "../library/section/Transaction";
import {
  formatter,
  paymentTagColor,
  statusTagColor,
  convertUnixTimestamp,
} from "../utils/helpers";
import { HIRES_LIST, HIRE_INFO } from "../utils/queries";

export default function Hires(props) {
  const { param } = useParams();

  const { isAdmin, isManager } = useContext(AuthContext);

  if (param) {
    return <HireDetails id={param} isAdmin={isAdmin} isManager={isManager} />;
  } else {
    return <HiresList isAdmin={isAdmin} isManager={isManager} />;
  }
}

function HiresList(props) {
  const { isAdmin, isManager } = props;

  const navigate = useNavigate();

  // get tomorrow's date yyyy-mm-dd
  const dateTomorrow = new Date(new Date().setDate(new Date().getDate() + 1))
    .toISOString()
    .split("T")[0];
  // get 1 month ago yyyy-mm-dd
  const dateMonthAgo = new Date(new Date().setMonth(new Date().getMonth() - 1))
    .toISOString()
    .split("T")[0];

  const [name, setName] = useState("");
  const [from, setFrom] = useState(dateMonthAgo);
  const [to, setTo] = useState(dateTomorrow);
  const [hires, setHires] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [limit /*, setLimit*/] = useState(10);
  const [maxPage, setMaxPage] = useState(0);
  const [data, setData] = useState([]);

  const debouncedName = useDebounce(name, 1000);

  const { loading, refetch } = useQuery(HIRES_LIST, {
    onCompleted: (data) => {
      setHires(data.list.hires);
      setTotal(data.list.total);
      // based on the total and limit, calculate the max page
      setMaxPage(Math.ceil(data.list.total / limit));
    },
    onError: (error) => {
      toast.error(error.message);
    },
    variables: {
      name: !!debouncedName ? debouncedName : null,
      from: !!from ? from : null,
      to: !!to ? to : null,
      status: ["DRAFT", "APPROVED", "REFUNDED", "COMPLETED"],
      page,
      limit,
    },
    fetchPolicy: "network-only",
  });

  const [getHiresToDownload, { data: download, loading: downloading }] =
    useLazyQuery(HIRES_LIST, {
      onCompleted: (res) => {
        if (res.list.hires.length > 0) {
          const formatted = res.list.hires.map((hire, i) => ({
            "No.": i + 1,
            Name: hire.name,
            Email: hire.user?.email,
            Phone: hire.user?.phone ?? "-",
            Location: hire.location?.name ?? "-",
            Hall: hire.hall?.name ?? "-",
            Method: hire.payment.method ?? "MANUAL",
            ...(isAdmin && {
              Paid: formatter.format(hire.total / 100),
              Discount: formatter.format(hire.amounts?.discount / 100 ?? 0),
              Refunded: formatter.format(hire.amounts?.refunded / 100 ?? 0),
              Deposit: formatter.format(hire.amounts?.deposit / 100 ?? 0),
              "Payment Ref.": hire.payment?.ref ?? "-",
            }),
            Status: hire.status,
            Created: convertUnixTimestamp(hire.created),
          }));
          setData(formatted);
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
      fetchPolicy: "network-only",
      pollInterval: 5000,
    });

  const getDownloadData = () => {
    getHiresToDownload({
      variables: {
        name: !!debouncedName ? debouncedName : null,
        from: !!from ? from : null,
        to: !!to ? to : null,
        status: ["DRAFT", "APPROVED", "REFUNDED", "COMPLETED"],
        page: 0,
        limit: total,
      },
    });
  };

  const formatLabel = (str) => str.replace(/_/g, " ");

  const nextPage = () => {
    // if page is max page, then do nothing
    if (page === maxPage - 1) return;
    setPage(page + 1);
    refetch();
  };

  const prevPage = () => {
    // if page is 0, then do nothing
    if (page === 0) return;
    setPage(page - 1);
    refetch();
  };

  return (
    <UserLayout title="Hires List">
      <section>
        <SectionHead heading="Hall Hires List"></SectionHead>
        <div className="flex flex-col gap-4 py-2">
          {/* table */}
          <div className="overflow-x-auto rounded-xl shadow-md my-4 bg-white">
            {/* table header */}
            <div className="flex w-full px-4 py-4 border-b-2">
              <div className="flex flex-row justify-between items-center w-full gap-4">
                {/* filters */}
                <div className="basis-3/4 flex flex-row gap-4">
                  {/* name search */}
                  <div className="w-1/3">
                    <label className="label sr-only">
                      <span className="label-text font-semibold">Name</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Search by name"
                      className="input input-bordered w-full bg-white"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </div>
                  {/* date from */}
                  <div className="w-1/3">
                    <label className="label sr-only">
                      <span className="label-text font-semibold">
                        Date From
                      </span>
                    </label>
                    <input
                      type="date"
                      className="input input-bordered w-full bg-white"
                      onChange={(e) => {
                        setFrom(e.target.value);
                        refetch();
                      }}
                      value={from}
                      max={to}
                    />
                  </div>
                  {/* date to */}
                  <div className="w-1/3">
                    <label className="label sr-only">
                      <span className="label-text font-semibold">Date To</span>
                    </label>
                    <input
                      type="date"
                      className="input input-bordered w-full bg-white"
                      onChange={(e) => {
                        setTo(e.target.value);
                        refetch();
                      }}
                      value={to}
                      min={from}
                    />
                  </div>
                </div>
                {/* buttons */}
                <div className="flex flex-row gap-2">
                  {(isAdmin || isManager) && (
                    <>
                      {/* download csv */}
                      {download &&
                      download?.list?.hires.length === data.length ? (
                        <CSVLink
                          className="btn btn-ghost"
                          data={data}
                          filename={`hires.csv`}
                          onClick={(e) => {
                            setData([]);
                          }}
                        >
                          Download CSV
                        </CSVLink>
                      ) : (
                        <button
                          className={clsx(
                            "btn btn-ghost",
                            downloading && "loading"
                          )}
                          onClick={getDownloadData}
                        >
                          Generate CSV
                        </button>
                      )}
                    </>
                  )}
                  {/* reset button */}
                  <button
                    className="btn btn-ghost"
                    onClick={() => {
                      setName("");
                      setFrom(dateMonthAgo);
                      setTo(dateTomorrow);
                      setData([]);
                      refetch();
                    }}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
            {/* table content */}
            {!loading ? (
              <table className="table-normal divide-y-2 bg-white rounded-xl w-full">
                {/* head */}
                <thead className="text-left">
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Method</th>
                    <th>Paid</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                {/* body */}
                {hires.length > 0 ? (
                  <tbody className="divide-y-2">
                    {hires.map((value, index) => (
                      <tr key={value.id}>
                        <th>{page * limit + index + 1}</th>
                        <td className="flex flex-row gap-4 items-center">
                          <div>
                            <p className="font-bold">{value.name}</p>
                            <p>
                              <span className="font-medium">
                                {value.hall?.name ?? "-"} (
                                {value.location?.name ?? "-"})
                              </span>
                            </p>
                            <p>
                              <span className="text-sm text-gray-600">
                                {convertUnixTimestamp(value.created)}
                              </span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <span
                            className={`text-xs py-1 px-2 rounded-md text-white ${paymentTagColor(
                              value.payment.method
                            )}`}
                          >
                            {formatLabel(value.payment.method ?? "MANUAL")}
                          </span>
                        </td>
                        <td className="tracking-wider font-mono">
                          {formatter.format(value.total / 100)}
                        </td>
                        <td>
                          <span
                            className={`text-xs py-1 px-2 rounded-md text-white ${statusTagColor(
                              value.status
                            )}`}
                          >
                            {value.status}
                          </span>
                        </td>
                        <td>
                          <button
                            className={`btn btn-xs btn-ghost ${
                              value.status === "ACTIVE" ? "hidden" : ""
                            }`}
                            onClick={() => navigate(`/hire/${value.id}`)}
                          >
                            Details
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="7">
                        <div className="flex flex-col items-center justify-center py-8">
                          <p className="text-gray-400 text-lg">
                            No hires found
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            ) : (
              <>
                {/* loading skeleton */}
                <LoadingLayout type="list" />
              </>
            )}
            {/* table footer */}
            <div className="flex w-full px-4 py-4 border-t-2">
              <div className="flex flex-row justify-between items-center w-full">
                <div>
                  <p>
                    Showing{" "}
                    <span className="font-bold">{page * limit + 1}</span> -{" "}
                    <span className="font-bold">
                      {page * limit + hires.length}
                    </span>{" "}
                    of <span className="font-bold">{total}</span> results
                  </p>
                </div>
                <div className="btn-group">
                  <button
                    className="btn btn-outline btn-sm"
                    disabled={page === 0 || maxPage <= 1}
                    onClick={() => prevPage()}
                  >
                    Prev
                  </button>
                  <button
                    className="btn btn-outline btn-sm"
                    disabled={page === maxPage - 1 || maxPage <= 1}
                    onClick={() => nextPage()}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </UserLayout>
  );
}

function HireDetails(props) {
  const { id, isAdmin, isManager } = props;

  const navigate = useNavigate();

  const { data } = useQuery(HIRE_INFO, {
    variables: { hireId: id },
    onError: (error) => {
      console.log(error);
      toast.error("Unable to get details!");
    },
    fetchPolicy: "network-only",
  });

  return (
    <UserLayout title={`Hire Details`}>
      <section>
        <SectionHead heading="Hall Hire Details">
          {/* Back to Passes */}
          <button className="btn btn-outline" onClick={() => navigate(-1)}>
            Back
          </button>
        </SectionHead>
      </section>
      {/* overview section */}
      {data && data.hire && <OverviewSection data={data.hire} />}
      {/* schedule list */}
      {data &&
        data.hire &&
        data.hire.schedule &&
        data.hire.status === "DRAFT" && (
          <ScheduleList schedule={data.hire.schedule} />
        )}
      {/* sessions list */}
      {data && data.hire && data.hire.sessions && (
        <SessionsList sessions={data.hire.sessions} />
      )}
      {/* transaction section */}
      {data && data.hire && (
        <TransactionSection
          id={id}
          depositRefundAllowed={
            (data.hire.status === "APPROVED" ||
              data.hire.status === "COMPLETED") &&
            (isAdmin || isManager)
          }
          paymentRefundAllowed={data.hire.status === "APPROVED" && isAdmin}
        />
      )}
    </UserLayout>
  );
}
