import moment from "moment";

export const paymentTagColor = (str) => {
  switch (str) {
    case "STRIPE":
      return `bg-blue-500`;
    default:
      return `bg-gray-700`;
  }
};

export const statusTagColor = (str) => {
  switch (str) {
    case "REQUESTED":
      return `bg-yellow-400`;
    case "PENDING":
      return `bg-yellow-400`;
    case "UPCOMING":
      return `bg-yellow-400`;
    case "ONGOING":
      return `bg-blue-400`;
    case "DRAFT":
      return `bg-gray-500`;
    case "APPROVED":
      return `bg-info`;
    case "USED":
      return `bg-blue-400`;
    case "READY":
      return `bg-orange-400`;
    case "COMPLETED":
      return `bg-green-500`;
    case "SUCCESSFUL":
      return `bg-green-500`;
    case "FULFILLED":
      return `bg-green-500`;
    case "EXPIRED":
      return `bg-green-500`;
    case "REFUNDED":
      return `bg-red-500`;
    case "AWAITING":
      return `bg-red-400`;
    default:
      return `bg-base-300`;
  }
};

export const formatter = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD",
});

export const compactFormatter = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD",
  maximumFractionDigits: 0,
  compactDisplay: "short",
  notation: "compact",
});

export const Today = new Date().toISOString().split("T")[0];
export const Tomorrow = moment()
  .add(1, "day")
  .startOf("day")
  .format("YYYY-MM-DDTHH:mm");
export const Now = new Date().toISOString();

export const getDate = (date) => {
  return new Date(date).toISOString().split("T")[0];
};

export const enumToLabel = (str) => {
  // replace _ with space
  return str.replace(/_/g, " ");
};

export const truncateString = (string, limit) => {
  if (string.length > limit) {
    return string.substring(0, limit) + "...";
  } else {
    return string;
  }
};

export const upperFirst = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const parseDateTime = (datetimeString) => {
  // Split the datetimeString into date and time parts
  var parsedDatetime = moment(datetimeString, "YYYY-MM-DD hh:mm A");

  // Format the parsed datetime to ISO 8601 format
  var isoDateTime = parsedDatetime.format("YYYY-MM-DDTHH:mm");

  return isoDateTime;
};

export const convertUnixTimestamp = (datetimeString) => {
  // convert unix timestamp to milliseconds and then create a new Date object
  const date = new Date(datetimeString * 1);
  // Split the datetimeString into date and time parts
  const parsedDatetime = moment(date);
  const isoDateTime = parsedDatetime.format("DD-MM-YYYY hh:mm A");

  return isoDateTime;
};

// get the month view grid for the calendar month
export const getMonthGrid = (month, year) => {
  moment.updateLocale("en", {
    week: {
      dow: 1,
    },
  });
  const startOfMonth = moment([year, month - 1]);
  const startOfWeek = startOfMonth.startOf("week");
  const endOfMonth = moment([year, month - 1]).endOf("month");
  const endOfWeek = endOfMonth.endOf("week");

  const monthGrid = [];
  let week = [];
  let day = startOfWeek.clone();
  while (day.isSameOrBefore(endOfWeek, "day")) {
    week.push({
      date: day.clone().format("DD-MM-YYYY"),
      isCurrentMonth: day.clone().month() === month - 1,
      isSelected: false,
      isToday: day.isSame(moment(), "day"),
      startOfDay: day.clone().startOf("day"),
      endOfDay: day.clone().endOf("day"),
      day: day.clone().format("D"),
      weekday: day.clone().format("ddd"),
    });
    if (week.length === 7) {
      monthGrid.push(week);
      week = [];
    }
    day.add(1, "day");
  }

  return monthGrid.flat();
};

// get prev 3 month, current month, and next 3 month for the calendar
export const getCalendarMonthRange = () => {
  const currentMonth = moment().month();

  const monthRange = [];
  for (let i = -3; i <= 3; i++) {
    monthRange.push({
      month: moment()
        .month(currentMonth + i)
        .format("M"),
      year: moment()
        .month(currentMonth + i)
        .format("YYYY"),
      isCurrent: i === 0 ? true : false,
      // get label eb. Sep 2023
      label: moment()
        .month(currentMonth + i)
        .format("MMM YYYY"),
    });
  }
  return monthRange;
};

export const formatMobile = (number) => {
  const trimmedNumber = number.replace(/\s/g, "");
  const startIndex = trimmedNumber.split("").findIndex((char) => char === "4");
  const formattedNumber = trimmedNumber.slice(startIndex, startIndex + 9);
  const countryCode = "+61";

  return countryCode.concat(formattedNumber);
};

export const toAmPm = (time) => {
  return moment(time, "HH:mm").format("hh:mm A");
};
