import { CLASS_ENROLMENTS } from "../../utils/queries";
import { useQuery } from "@apollo/client";
import toast from "react-hot-toast";

const BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL;

export const EnrolmentsTable = (props) => {
  const { classId, csv, showDollarValues = false } = props;

  const notifyError = () => toast.error("Something went wrong!");

  const { data } = useQuery(CLASS_ENROLMENTS, {
    variables: {
      classId,
    },
    pollInterval: 60000,
    onCompleted: (list) => {
      if (list.enrolments.length > 0) {
        const enrolments = list.enrolments.map((value) => ({
          Name: value.name,
          Email: value.email,
          Phone: value.phone,
          Enrolment: value.enrolment,
          From: value.from,
          Method: value.method,
          ...(showDollarValues && { Paid: formatter.format(value.paid / 100) }),
          Attendance: `${value.attendance}%`,
        }));
        csv(enrolments);
      }
    },
    onError: () => notifyError(),
  });

  return (
    <div>
      <div className="overflow-x-auto rounded-xl shadow-md">
        <table className="table-normal divide-y-2 bg-white rounded-xl w-full">
          {/* head */}
          <thead className="text-left">
            <tr>
              <th></th>
              <th>Name</th>
              <th>From</th>
              <th>Method</th>
              {showDollarValues && <th>Paid</th>}
              <th>Attendance</th>
            </tr>
          </thead>
          <tbody className="divide-y-2">
            {data &&
              data.enrolments &&
              data.enrolments.map((value, index) => (
                <tr key={value.id}>
                  <th>{index + 1}</th>
                  <td className="flex flex-row gap-4 items-center">
                    {value.picture ? (
                      <img
                        src={`${BUCKET_URL}/${value.picture}`}
                        className="w-14 h-14 rounded-full"
                        alt={`${value.name}`}
                      />
                    ) : (
                      <img
                        src={`${BUCKET_URL}/pictures/placeholder.jpg`}
                        className="w-14 h-14 rounded-full"
                        alt={`${value.name}`}
                      />
                    )}
                    <div>
                      <p className="font-bold">{value.name}</p>
                      <p>
                        {value.email} / {value.phone}
                      </p>
                    </div>
                  </td>
                  <td>{value.from}</td>
                  <td>
                    <span
                      className={`text-xs py-1 px-2 rounded-md text-white ${paymentTagColor(
                        value.method
                      )}`}
                    >
                      {formatLabel(value.method)}
                    </span>
                  </td>
                  {showDollarValues && (
                    <td>{formatter.format(value.paid / 100)}</td>
                  )}
                  <td>{value.attendance}%</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

function formatLabel(str) {
  // replace all _ with space
  return str.replace(/_/g, " ");
}

function paymentTagColor(str) {
  switch (str) {
    case "STRIPE":
      return `bg-info`;
    default:
      return `bg-warning`;
  }
}

const formatter = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD",
});
