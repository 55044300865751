import { LIST_TEACHERS } from "../../utils/queries";
import { useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

export const TeachersTable = (props) => {
  const notifyError = () => toast.error("Something went wrong!");

  const { data } = useQuery(LIST_TEACHERS, {
    onError: () => notifyError(),
  });

  return (
    <div>
      <div className="overflow-x-auto rounded-xl shadow-md">
        <table className="table-normal divide-y-2 bg-white rounded-xl w-full">
          {/* head */}
          <thead className="text-left">
            <tr>
              <th></th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Gender</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody className="divide-y-2">
            {data &&
              data.teachers &&
              data.teachers.map((value, index) => (
                <tr key={value.id}>
                  <th>{index + 1}</th>
                  <td className="font-bold">{value.name}</td>
                  <td>{value.email}</td>
                  <td>{value.phone}</td>
                  <td>{value.gender}</td>
                  <td>
                    <span
                      className={`text-xs py-1 px-2 rounded-md text-white ${statusTagColor(
                        value.status
                      )}`}
                    >
                      {value.status}
                    </span>
                  </td>
                  <td>
                    <Link
                      to={`/teacher/${value.id}`}
                      className="btn btn-xs btn-ghost"
                    >
                      details
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

function statusTagColor(str) {
  if (str === "ACTIVE") return `bg-info`;
  return `bg-base-300`;
}
