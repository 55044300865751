export const OverallStats = (props) => {
  const { customers, students, terms, programs, locations } = props;

  return (
    <div className="py-4">
      <label className="label">
        <span className="label-text font-semibold">Overall</span>
      </label>
      <div className="stats shadow w-full">
        <div className="stat bg-white">
          <div className="stat-title">Customers</div>
          <div className="stat-value">{nFormatter(customers)}</div>
        </div>

        <div className="stat bg-white">
          <div className="stat-title">Students</div>
          <div className="stat-value">{nFormatter(students)}</div>
        </div>

        <div className="stat bg-white">
          <div className="stat-title">Terms</div>
          <div className="stat-value">{terms.toLocaleString()}</div>
        </div>

        <div className="stat bg-white">
          <div className="stat-title">Programs</div>
          <div className="stat-value">{programs.toLocaleString()}</div>
        </div>

        <div className="stat bg-white">
          <div className="stat-title">Locations</div>
          <div className="stat-value">{locations.toLocaleString()}</div>
        </div>
      </div>
    </div>
  );
};

function nFormatter(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
}
