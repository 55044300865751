import { gql } from "@apollo/client";

export const GET_CLASSES = gql`
  mutation ScheduleAdmin($input: scheduleFilter) {
    schedule: scheduleAdmin(input: $input) {
      id
      name
      price
      schedule
      remaining
      preassessment
      status
      enrolcount
      commentsCount
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation Update($input: userProfile) {
    update(input: $input)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation Change($password: String) {
    change(password: $password)
  }
`;

export const UPDATE_ROSTER = gql`
  mutation UpdateRoster($input: rosterEntry) {
    success: updateRoster(input: $input)
  }
`;

export const MARK_FULL = gql`
  mutation DeactivateClass($classId: ID) {
    success: deactivateClass(classId: $classId)
  }
`;

export const MARK_AVAILABLE = gql`
  mutation ActivateClass($classId: ID) {
    success: activateClass(classId: $classId)
  }
`;

export const CREATE_CLASS = gql`
  mutation CreateClass($input: classDetails) {
    class: createClass(input: $input)
  }
`;

export const UPDATE_CLASS = gql`
  mutation UpdateClass($input: classDetails) {
    class: updateClass(input: $input)
  }
`;

export const REMOVE_CLASS = gql`
  mutation RemoveClass($classId: ID) {
    removed: removeClass(classId: $classId)
  }
`;

export const CREATE_TERM = gql`
  mutation CreateTerm($input: termDetails) {
    term: createTerm(input: $input)
  }
`;

export const UPDATE_TERM = gql`
  mutation UpdateTerm($input: termDetails) {
    term: updateTerm(input: $input)
  }
`;

export const REMOVE_TERM = gql`
  mutation RemoveTerm($termId: ID) {
    removed: removeTerm(termId: $termId)
  }
`;

export const CREATE_PROGRAM = gql`
  mutation CreateProgram($input: programDetails) {
    program: createProgram(input: $input)
  }
`;

export const UPDATE_PROGRAM = gql`
  mutation UpdateProgram($input: programDetails) {
    program: updateProgram(input: $input)
  }
`;

export const CREATE_GROUP = gql`
  mutation CreateGroup($input: groupDetails) {
    group: createGroup(input: $input)
  }
`;

export const UPDATE_GROUP = gql`
  mutation UpdateGroup($input: groupDetails) {
    group: updateGroup(input: $input)
  }
`;

export const CREATE_LOCATION = gql`
  mutation CreateLocation($input: locationDetails) {
    location: createLocation(input: $input)
  }
`;

export const UPDATE_LOCATION = gql`
  mutation UpdateLocation($input: locationDetails) {
    location: updateLocation(input: $input)
  }
`;

export const FIND_STUDENTS = gql`
  mutation FindCustomerStudents($email: String!) {
    customer: findCustomerStudents(email: $email) {
      phone
      address
      students {
        id
        name
        dob
        gender
        age
        notes
        phone
        address
      }
    }
  }
`;

export const GET_AVAILABLE_CLASSES = gql`
  mutation Schedule($input: scheduleFilter) {
    schedule(input: $input) {
      id
      name
      price
      schedule
      remaining
      preassessment
    }
  }
`;

export const ENROL_STUDENT = gql`
  mutation Mutation($input: directEnrolment) {
    enrolment: enrolStudent(input: $input)
  }
`;

export const CREATE_CUSTOMER = gql`
  mutation CreateCustomer($input: registerProfile) {
    customer: createCustomer(input: $input)
  }
`;

export const CREATE_TEACHER = gql`
  mutation CreateTeacher($input: teacherProfile) {
    createTeacher(input: $input)
  }
`;

export const ASSIGN_CLASS_TEACHERS = gql`
  mutation AssignClassTeacher($input: classTeacher) {
    assigned: assignClassTeacher(input: $input)
  }
`;

export const ASSIGN_SESSION_TEACHERS = gql`
  mutation AssignSessionTeacher($input: sessionTeacher) {
    assigned: assignSessionTeacher(input: $input)
  }
`;

export const DEACTIVATE_TEACHER = gql`
  mutation DeactivateTeacher($teacherId: ID) {
    deactivate: deactivateTeacher(teacherId: $teacherId)
  }
`;

export const ACTIVATE_TEACHER = gql`
  mutation ActivateTeacher($teacherId: ID) {
    activate: activateTeacher(teacherId: $teacherId)
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer($input: userProfile) {
    updated: updateCustomer(input: $input)
  }
`;

export const CREATE_USER = gql`
  mutation CreateAdmin($email: String!, $role: String!) {
    user: createAdmin(email: $email, role: $role)
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateAdmin($id: ID!, $email: String!, $role: String!) {
    updated: updateAdmin(id: $id, email: $email, role: $role)
  }
`;

export const REMOVE_USER = gql`
  mutation RemoveAdmin($userId: ID!) {
    remove: removeAdmin(id: $userId)
  }
`;

export const VOUCHER_IS_VALID = gql`
  mutation VoucherIsValid($voucherId: ID, $isValid: Boolean) {
    updated: voucherIsValid(voucherId: $voucherId, isValid: $isValid)
  }
`;

export const VOUCHER_IS_PAID = gql`
  mutation VoucherIsPaid($voucherId: ID, $isPaid: Boolean) {
    updated: voucherIsPaid(voucherId: $voucherId, isPaid: $isPaid)
  }
`;

export const CANCEL_ENROLMENT = gql`
  mutation CancelEnrolment($enrolmentId: ID) {
    cancelled: cancelEnrolment(enrolmentId: $enrolmentId)
  }
`;

export const REFUND_ENROLMENT = gql`
  mutation RefundEnrolment($enrolmentId: ID) {
    refunded: refundEnrolment(enrolmentId: $enrolmentId)
  }
`;

export const CREATE_TEMPLATE = gql`
  mutation CreateTemplate($input: TemplateDetails) {
    template: createTemplate(input: $input)
  }
`;

export const UPDATE_TEMPLATE = gql`
  mutation UpdateTemplate($input: TemplateDetails) {
    updated: updateTemplate(input: $input)
  }
`;

export const REMOVE_TEMPLATE = gql`
  mutation RemoveTemplate($templateId: ID) {
    removed: removeTemplate(templateId: $templateId)
  }
`;

export const CLASS_NOTIFICATION = gql`
  mutation ClassNotification($input: TemplateDetails) {
    sent: classNotification(input: $input)
  }
`;

export const REMOVE_SESSION = gql`
  mutation RemoveClassSession($sessionId: ID) {
    removed: removeClassSession(sessionId: $sessionId)
  }
`;

export const UPDATE_CLASS_SCHEDULE = gql`
  mutation UpdateClassSchedule($input: ClassSchedule) {
    updated: updateClassSchedule(input: $input)
  }
`;

export const ADD_COMMENT = gql`
  mutation CreateComment($input: CommentDetails) {
    created: createComment(input: $input)
  }
`;

export const UPDATE_COMMENT = gql`
  mutation UpdateComment($input: CommentDetails) {
    updated: updateComment(input: $input)
  }
`;

export const REMOVE_COMMENT = gql`
  mutation RemoveComment($id: ID) {
    removed: removeComment(commentId: $id)
  }
`;

export const UPDATE_STUDENT = gql`
  mutation UpdateStudent($input: studentProfile) {
    student: updateStudent(input: $input)
  }
`;

export const CREATE_HALL = gql`
  mutation CreateHall($input: HallDetails) {
    created: createHall(input: $input)
  }
`;

export const UPDATE_HALL = gql`
  mutation UpdateHall($input: HallDetails) {
    updated: updateHall(input: $input)
  }
`;

export const REMOVE_HALL = gql`
  mutation RemoveHall($hallId: ID) {
    removed: removeHall(hallId: $hallId)
  }
`;

export const CREATE_PRODUCT = gql`
  mutation CreateProduct($input: ProductDetails) {
    created: createProduct(input: $input)
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($input: ProductDetails) {
    updated: updateProduct(input: $input)
  }
`;

export const REMOVE_PRODUCT = gql`
  mutation RemoveProduct($productId: ID) {
    removed: removeProduct(productId: $productId)
  }
`;

export const SEND_CUSTOM_NOTIFICATION = gql`
  mutation SendCustomNotification($entityId: ID!, $custom: CustomNotification) {
    sent: sendCustomNotification(entityId: $entityId, custom: $custom)
  }
`;

export const UPDATE_ENROLMENT = gql`
  mutation UpdateEnrolment($input: EnrolmentDetails) {
    updated: updateEnrolment(input: $input)
  }
`;

export const CREATE_PASS = gql`
  mutation CreatePass($input: PassDetails) {
    created: createPass(input: $input)
  }
`;

export const UPDATE_PASS = gql`
  mutation UpdatePass($input: PassDetails) {
    updated: updatePass(input: $input)
  }
`;

export const REMOVE_PASS = gql`
  mutation RemovePass($passId: ID) {
    removed: removePass(passId: $passId)
  }
`;

export const ADD_STUDENT_PASS = gql`
  mutation AddPass($input: PassDetails) {
    action: addPass(input: $input)
  }
`;

export const USE_STUDENT_PASS = gql`
  mutation UsePass(
    $usePassId: ID!
    $classId: ID!
    $sessionId: ID!
    $category: String!
  ) {
    action: usePass(
      id: $usePassId
      classId: $classId
      sessionId: $sessionId
      category: $category
    )
  }
`;

export const ADD_STUDENT_ENROLMENT = gql`
  mutation AddEnrolment($input: EnrolmentDetails) {
    action: addEnrolment(input: $input)
  }
`;

export const CREATE_ORDER = gql`
  mutation CreateOrder($input: OrderDetails) {
    created: createOrder(input: $input)
  }
`;

export const UPDATE_ORDER = gql`
  mutation UpdateOrder($input: OrderDetails) {
    updated: updateOrder(input: $input)
  }
`;

export const REMOVE_ORDER = gql`
  mutation RemoveOrder($orderId: ID) {
    removed: removeOrder(orderId: $orderId)
  }
`;

export const DRAFT_ENTITY = gql`
  mutation DraftEntity(
    $type: String!
    $enrolment: EnrolmentDetails
    $pass: PassDetails
    $order: OrderDetails
    $training: TrainingDetails
    $performance: PerformanceDetails
    $hire: HireDetails
  ) {
    transaction: draftEntity(
      type: $type
      enrolment: $enrolment
      pass: $pass
      order: $order
      training: $training
      performance: $performance
      hire: $hire
    )
  }
`;

export const CANCEL_ENTITY = gql`
  mutation CancelEntity($type: String!, $entityId: ID!) {
    entity: cancelEntity(type: $type, entityId: $entityId)
  }
`;

export const USE_ENTITY = gql`
  mutation UseEntity($type: String!, $entityId: ID!, $pass: PassDetails) {
    entity: useEntity(type: $type, entityId: $entityId, pass: $pass)
  }
`;

export const ACCEPT_ENTITY = gql`
  mutation AcceptEntity(
    $type: String!
    $entityId: ID!
    $training: TrainingDetails
    $performance: PerformanceDetails
  ) {
    entity: acceptEntity(
      type: $type
      entityId: $entityId
      training: $training
      performance: $performance
    )
  }
`;

export const REJECT_ENTITY = gql`
  mutation RejectEntity($type: String!, $entityId: ID!, $reason: String) {
    entity: rejectEntity(type: $type, entityId: $entityId, reason: $reason)
  }
`;

export const READY_ENTITY = gql`
  mutation ReadyEntity($type: String!, $entityId: ID!, $order: OrderDetails) {
    entity: readyEntity(type: $type, entityId: $entityId, order: $order)
  }
`;

export const COMPLETE_ENTITY = gql`
  mutation CompleteEntity($type: String!, $entityId: ID!) {
    entity: completeEntity(type: $type, entityId: $entityId)
  }
`;

export const REFUND_ENTITY = gql`
  mutation RefundEntity($transactionId: ID!, $category: String!) {
    entity: refundEntity(transactionId: $transactionId, category: $category)
  }
`;
