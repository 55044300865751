import { gql } from "@apollo/client";

export const LIST_LOCATIONS = gql`
  query ListLocations {
    locations: listLocations {
      id
      name
      line1
      line2
      suburb
      state
      postcode
      email
      active
    }
  }
`;

export const LIST_GROUPS = gql`
  query ListGroups {
    groups: listGroups {
      id
      name
      min
      max
      active
    }
  }
`;

export const LIST_PROGRAMS = gql`
  query ListPrograms {
    programs: listPrograms {
      id
      name
      details
      active
    }
  }
`;

export const LIST_TERMS = gql`
  query ListTerms($status: [String]) {
    terms: listTerms(status: $status) {
      id
      name
      start
      end
      status
      dropIn
      unlimited
    }
  }
`;

export const SCHEDULE_FILTER = gql`
  query FilterOptions {
    filters: filterOptions {
      locations {
        id
        name
      }
      terms {
        id
        name
        start
        end
      }
      programs {
        id
        name
      }
      groups {
        id
        name
      }
      halls {
        id
        name
        location {
          id
          name
        }
      }
    }
  }
`;

export const ADMIN_SCHEDULE_FILTER = gql`
  query AdminFilterOptions {
    filters: adminFilterOptions {
      locations {
        id
        name
      }
      terms {
        id
        name
        start
        end
      }
      programs {
        id
        name
      }
      groups {
        id
        name
      }
    }
  }
`;

export const LIST_ATTENDANCE = gql`
  query ListAttendance($sessionId: ID) {
    listAttendance(sessionId: $sessionId) {
      id
      name
      attended
    }
  }
`;

export const PROFILE_INFO = gql`
  query ProfileInfo {
    info: profileInfo {
      id
      email
    }
  }
`;

export const CLASS_INFO = gql`
  query Class($classId: ID) {
    info: class(classId: $classId) {
      id
      name
      price
      remaining: sessionsAvailable
      enrolments: enrolmentCount
      day
      from
      to
      location {
        id
        name
      }
      term {
        id
        name
      }
      program {
        id
        name
      }
      group {
        id
        name
      }
      hall {
        id
        name
      }
      preassessment
      allocated
      status
    }
  }
`;

export const CLASS_SESSIONS = gql`
  query ClassSessions($classId: ID) {
    sessions: classSessions(classId: $classId) {
      id
      name
      date
      time
      teacher
      enrolments
      status
      commentsCount
      assessmentsCount
    }
  }
`;

export const CLASS_ENROLMENTS = gql`
  query ClassEnrolments($classId: ID) {
    enrolments: classEnrolments(classId: $classId) {
      id
      enrolment
      name
      picture
      email
      phone
      from
      method
      ref
      paid
      attendance
      notes
    }
  }
`;

export const SESSION_DETAILS = gql`
  query Session($sessionId: ID) {
    details: session(sessionId: $sessionId) {
      id
      name
      class
      date
      time
      duration
      teacher
      assistant
      status
      roster {
        id
        name
        attended
      }
      assessments {
        id
        from {
          id
          userId
          name
        }
        text
        created
        updated
      }
    }
  }
`;

export const TERM_INFO = gql`
  query Term($termId: ID) {
    term(termId: $termId) {
      id
      name
      start
      end
      status
      dropIn
      unlimited
    }
  }
`;

export const PROGRAM_INFO = gql`
  query Program($programId: ID) {
    program(programId: $programId) {
      id
      name
      details
      active
    }
  }
`;

export const GROUP_INFO = gql`
  query Group($groupId: ID) {
    group(groupId: $groupId) {
      id
      name
      min
      max
      active
    }
  }
`;

export const LOCATION_INFO = gql`
  query Query($locationId: ID) {
    location(locationId: $locationId) {
      id
      name
      line1
      line2
      suburb
      state
      postcode
      email
      active
    }
  }
`;

export const ENROL_FILTER = gql`
  query FilterOptions {
    filters: filterOptions {
      terms {
        id
        name
        start
        end
      }
      locations {
        id
        name
      }
      programs {
        id
        name
      }
      groups {
        id
        name
        min
        max
      }
    }
  }
`;

export const DASHBOARD_PARAMS = gql`
  query DashboardParams {
    dashboard: adminDashboard {
      customers
      students
      terms
      programs
      locations
      term {
        id
        name
        start
        end
        status
      }
      classes
      sessions
      enrolments
      attendance
      revenue
      list {
        id
        class
        classId
        name
        time
        status
      }
    }
  }
`;

export const LIST_TEACHERS = gql`
  query ListTeachers {
    teachers: listTeachers {
      id
      name
      email
      phone
      gender
      status
    }
  }
`;

export const TEACHER_DETAILS = gql`
  query TeacherDetails($teacherId: ID) {
    details: teacherDetails(teacherId: $teacherId) {
      id
      name
      email
      phone
      address
      gender
      status
      timesheet {
        label
        teaching
        assisting
      }
    }
  }
`;

export const TEACHER_CALENDAR = gql`
  query TeacherCalendar($teacherId: ID, $month: Int, $year: Int) {
    events: teacherCalendar(teacherId: $teacherId, month: $month, year: $year) {
      id
      class
      classId
      name
      date
      time
      start
      end
      status
      teaching
      assisting
    }
  }
`;

export const LIST_CUSTOMERS = gql`
  query ListCustomers {
    customers: listCustomers {
      id
      email
      phone
      address
      students
      registered
      street
      suburb
      postcode
    }
  }
`;

export const LIST_STUDENTS = gql`
  query ListStudents {
    students: listStudents {
      id
      name
      picture
      gender
      dob
      age
      email
      notes
      commentsCount
    }
  }
`;

export const LIST_PAYMENTS = gql`
  query ListPayments {
    payments: listPayments {
      id
      name
      method
      ref
      total
      email
      class
      from
      cancelled
      refunded
    }
  }
`;

export const LIST_ADMIN_USERS = gql`
  query ListAdminUsers {
    users: listAdminUsers {
      id
      email
      role
    }
  }
`;

export const USER_INFO = gql`
  query AdminUser($userId: ID) {
    user: adminUser(userId: $userId) {
      id
      email
      role
    }
  }
`;

export const LIST_REDEMPTION = gql`
  query ListVouchers {
    vouchers: listVouchers {
      id
      student {
        id
        firstName
        middleName
        lastName
        picture
        dob
        gender
      }
      class {
        name
      }
      code
      amount
      charge
      valid
      paid
      type
      status
    }
  }
`;

export const REDEMPTION_INFO = gql`
  query Voucher($id: ID) {
    voucher(voucherId: $id) {
      id
      student {
        id
        firstName
        middleName
        lastName
        picture
        dob
        gender
      }
      class {
        name
      }
      code
      amount
      charge
      valid
      paid
      type
      status
    }
  }
`;

export const STUDENT_PROFILE = gql`
  query StudentProfileDetails($studentId: ID!) {
    profile: studentProfileDetails(studentId: $studentId) {
      student {
        id
        firstName
        middleName
        lastName
        gender
        dob
        picture
        notes
      }
      customer {
        id
        email
        phone
        street
        suburb
        postcode
      }
      enrolments {
        id
        name
        class {
          id
          name
        }
        attendance
        status
        created
      }
    }
  }
`;

export const STUDENT_INFO = gql`
  query StudentInfo($id: ID) {
    student: studentInfo(id: $id) {
      id
      firstName
      middleName
      lastName
      picture
      dob
      gender
      notes
    }
  }
`;

export const ANALYTICS_THIS_PARAMS = gql`
  query AnalyticsThislast {
    this: analyticsThislast {
      enrolments: enrolmentsThis {
        thisWeek
        thisMonth
        thisQuarter
        thisYear
      }
      revenues: revenueThis {
        thisWeek
        thisMonth
        thisQuarter
        thisYear
      }
    }
  }
`;

export const ANALYTICS_LAST_PARAMS = gql`
  query AnalyticsThislast {
    last: analyticsThislast {
      enrolments: enrolmentsLast {
        lastWeek
        lastMonth
        lastQuarter
        lastYear
      }
      revenues: revenueLast {
        lastWeek
        lastMonth
        lastQuarter
        lastYear
      }
    }
  }
`;

export const ANALYTICS_ENROLMENTS_COMPARISON = gql`
  query AnalyticsEnrolments {
    enrolments: analyticsEnrolments {
      term
      year
      count
      amount: revenue
    }
  }
`;

export const ANALYTICS_STUDENTS_GROUPS = gql`
  query AnalyticsGroups {
    students: analyticsGroups {
      count
      group
    }
  }
`;

export const ANALYTICS_ENROLMENTS_PROGRAMS = gql`
  query AnalyticsPrograms {
    enrolments: analyticsPrograms {
      count
      program
    }
  }
`;

export const REPORT_ATTENDANCE = gql`
  query ReportAttendance($from: String, $to: String) {
    report: reportAttendance(from: $from, to: $to) {
      name
      email
      phone
      class
      term
      program
      group
      location
      attendance
      created
    }
  }
`;

export const REPORT_ENROLMENTS = gql`
  query ReportEnrolments($from: String, $to: String) {
    report: reportEnrolments(from: $from, to: $to) {
      name
      email
      class
      term
      location
      program
      group
      method
      ref
      amount
      voucher
      refund
      status
      created
    }
  }
`;

export const REPORT_RETENTION = gql`
  query ReportRetention($from: String, $to: String) {
    report: reportRetention(from: $from, to: $to) {
      name
      dob
      gender
      email
      phone
      enrolments
      vouchers
      terms
      spend
      recent
      created
    }
  }
`;

export const REPORT_ATTRITION = gql`
  query ReportStudentTermsAttrition($termA: ID, $termB: ID) {
    report: reportStudentTermsAttrition(termA: $termA, termB: $termB) {
      id
      name
      email
      phone
      gender
      age
    }
  }
`;

export const REPORT_STUDENTS_ACTIVITY = gql`
  query ReportStudentsActivity {
    report: reportStudentsActivity {
      name
      email
      phone
      age
      gender
      activity {
        term
        enrolments
        passes
        spend
      }
      total
    }
  }
`;

export const LIST_TEMPLATES = gql`
  query ListTemplates {
    templates: listTemplates {
      id
      type
      category
      status
      trigger
    }
  }
`;

export const TEMPLATE_INFO = gql`
  query Template($templateId: ID) {
    info: template(templateId: $templateId) {
      id
      type
      category
      status
      subject
      headline
      body
      action {
        link
        label
      }
      trigger
    }
  }
`;

export const LIST_COMMENTS = gql`
  query ListComments($entity: String, $ref: String) {
    list: listComments(entity: $entity, ref: $ref) {
      id
      to
      visibility
      text
      flagged
      updated
      from {
        id
        username
        email
      }
    }
  }
`;

export const READ_COMMENT = gql`
  query Comment($id: ID) {
    comment(commentId: $id) {
      id
      to
      visibility
      text
      flagged
      updated
      from {
        id
        username
        email
      }
    }
  }
`;

export const LIST_HALLS = gql`
  query ListHalls($location: ID, $status: String) {
    halls: listHalls(location: $location, status: $status) {
      id
      location {
        id
        name
      }
      name
      capacity
      price
      deposit
      days
      from
      to
      status
    }
  }
`;

export const HALL_INFO = gql`
  query Hall($hallId: ID) {
    hall(hallId: $hallId) {
      id
      location {
        id
        name
      }
      name
      price
      deposit
      capacity
      days
      from
      to
      status
    }
  }
`;

export const LIST_PRODUCTS = gql`
  query ListProducts(
    $name: String
    $status: String
    $groupId: ID
    $programId: ID
  ) {
    products: listProducts(
      name: $name
      status: $status
      groupId: $groupId
      programId: $programId
    ) {
      id
      name
      thumbnail
      description
      cost
      price
      groups {
        id
        name
      }
      programs {
        id
        name
      }
      status
      created
      updated
    }
  }
`;

export const PRODUCT_INFO = gql`
  query Product($productId: ID) {
    product(productId: $productId) {
      id
      name
      thumbnail
      description
      cost
      price
      groups {
        id
        name
      }
      programs {
        id
        name
      }
      status
      created
      updated
    }
  }
`;

export const USERS_LIST = gql`
  query Users($search: String, $limit: Int, $page: Int) {
    list: users(search: $search, limit: $limit, page: $page) {
      users {
        id
        email
        phone
        role
      }
      total
    }
  }
`;

export const STUDENTS_LIST = gql`
  query Students(
    $userId: ID
    $search: String
    $limit: Int
    $page: Int
    $status: String
  ) {
    list: students(
      userId: $userId
      search: $search
      limit: $limit
      page: $page
      status: $status
    ) {
      total
      students {
        id
        user {
          id
          email
          phone
          street
          suburb
          postcode
        }
        picture
        name
        dob
        age
        gender
        notes
        status
        commentsCount
        commentsCountStaff
        commentsCountFlagged
        groups {
          id
          name
        }
      }
    }
  }
`;

export const CLASSES_LIST = gql`
  query Classes(
    $termsId: [ID]
    $locationsId: [ID]
    $programsId: [ID]
    $groupsId: [ID]
    $name: String
    $status: String
  ) {
    list: classes(
      termsId: $termsId
      locationsId: $locationsId
      programsId: $programsId
      groupsId: $groupsId
      name: $name
      status: $status
    ) {
      total
      classes {
        id
        name
        price
        day
        from
        to
        term {
          id
          name
          start
          end
          dropIn
          unlimited
        }
        program {
          id
          name
        }
        group {
          id
          name
          min
          max
        }
        location {
          id
          name
          suburb
        }
        preassessment
        status
        enrolmentCount
        dropinCount
        unlimitedCount
        commentsCount
        commentsCountStaff
        commentsCountFlagged
        sessionsTotal
        sessionsAvailable
      }
    }
  }
`;

export const SESSIONS_LIST = gql`
  query Sessions(
    $termId: ID
    $classId: ID
    $hallId: ID
    $teacherId: ID
    $from: String
    $to: String
    $status: [String]
    $limit: Int
    $page: Int
  ) {
    list: sessions(
      termId: $termId
      classId: $classId
      hallId: $hallId
      teacherId: $teacherId
      from: $from
      to: $to
      status: $status
      limit: $limit
      page: $page
    ) {
      total
      sessions {
        id
        name
        date
        start
        end
        duration
        class {
          id
        }
        term {
          id
        }
        teacher {
          id
        }
        assistant {
          id
        }
        hall {
          id
        }
        conflict
        status
        created
        updated
        rosterCount
        attendedCount
        absentCount
        commentsCount
        commentsCountStaff
        commentsCountFlagged
        assessmentsCount
      }
    }
  }
`;

export const APPLICABLE_SESSIONS = gql`
  query ApplicableSessions($classId: ID!, $studentId: ID!, $status: [String]) {
    sessions: applicableSessions(
      classId: $classId
      studentId: $studentId
      status: $status
    ) {
      id
      name
      date
      start
      end
      status
    }
  }
`;

export const APPLICABLE_CLASSES = gql`
  query ApplicableClasses($studentId: ID!, $termId: ID!) {
    classes: applicableClasses(studentId: $studentId, termId: $termId) {
      id
      name
      price
      taxed
      day
      from
      to
      capacity
      term {
        id
        name
        start
        end
        dropIn
        unlimited
      }
      program {
        id
        name
      }
      group {
        id
        name
        min
        max
      }
      location {
        id
        name
        suburb
      }
      preassessment
      status
      enrolmentCount
      dropinCount
      unlimitedCount
      commentsCount
      commentsCountStaff
      commentsCountFlagged
      sessionsTotal
      sessionsAvailable
    }
  }
`;

export const GET_HALL_AVAILABILITY_SLOTS = gql`
  query CheckHallAvailability($hallId: ID, $date: String, $duration: Int) {
    slots: checkHallAvailability(
      hallId: $hallId
      date: $date
      duration: $duration
    ) {
      id
      from
      to
      date
      start
      end
      duration
    }
  }
`;

export const PASS_SWITCH_SESSIONS = gql`
  query SwitchPassSessions($passId: ID) {
    sessions: switchPassSessions(passId: $passId) {
      id
      name
      date
      class {
        id
        name
        day
        from
        to
      }
      start
      end
    }
  }
`;

export const ENROLMENT_SWITCH_CLASSES = gql`
  query SwitchEnrolmentClasses($enrolmentId: ID) {
    classes: switchEnrolmentClasses(enrolmentId: $enrolmentId) {
      id
      name
      day
      from
      to
    }
  }
`;

export const LIST_TRANSACTIONS = gql`
  query Transactions(
    $type: String
    $userId: ID
    $entityId: ID
    $status: [String]
    $from: String
    $to: String
    $limit: Int
    $page: Int
  ) {
    transactions(
      type: $type
      userId: $userId
      entityId: $entityId
      status: $status
      from: $from
      to: $to
      limit: $limit
      page: $page
    ) {
      list: transactions {
        id
        type
        status
        method
        entity
        ref
        amount
        deposit {
          amount
          returned
        }
        discount
        refunded
        redeemed
        created
        updated
        enrolment {
          id
          name
        }
        pass {
          id
          name
        }
        order {
          id
          number
        }
        training {
          id
          name
        }
        performance {
          id
          name
        }
        hire {
          id
          name
        }
      }
      page
      total
    }
  }
`;

export const TRANSACTION_INFO = gql`
  query Transaction($id: ID, $entityId: ID) {
    transaction(transactionId: $id, entityId: $entityId) {
      id
      status
      type
      entity
      method
      ref
      secret
      user {
        id
        email
      }
      amount
      discount
      refunded
      deposit {
        amount
        returned
      }
      redeemed
      vouchers {
        id
        type
        status
      }
      created
      updated
      enrolment {
        id
        name
      }
      pass {
        id
        name
      }
      order {
        id
        number
      }
      training {
        id
        name
      }
      performance {
        id
        name
      }
      hire {
        id
        name
      }
    }
  }
`;

export const ENROLMENTS_LIST = gql`
  query Enrolments(
    $termId: ID
    $userId: ID
    $studentId: ID
    $classId: ID
    $status: [String]
    $name: String
    $from: String
    $to: String
    $limit: Int
    $page: Int
  ) {
    list: enrolments(
      termId: $termId
      userId: $userId
      studentId: $studentId
      classId: $classId
      status: $status
      name: $name
      from: $from
      to: $to
      limit: $limit
      page: $page
    ) {
      total
      page
      enrolments {
        id
        name
        from
        user {
          id
          email
          phone
          street
          suburb
          postcode
        }
        student {
          id
          name
          dob
          age
          gender
          notes
        }
        term {
          id
          name
          start
          end
        }
        class {
          id
          name
          day
          from
          to
          group {
            id
            name
          }
          program {
            id
            name
          }
          location {
            id
            name
          }
        }
        total
        method
        ref
        receipt
        voucher
        discount
        refunded
        status
        created
        updated
        rosterCount
        rosterCountAttended
        rosterCountAbsent
      }
    }
  }
`;

export const ENROLMENT_INFO = gql`
  query Enrolment($enrolmentId: ID) {
    enrolment(enrolmentId: $enrolmentId) {
      id
      name
      from
      user {
        id
        email
        phone
        street
        suburb
        postcode
      }
      student {
        id
        name
        picture
        dob
        age
        gender
        notes
      }
      term {
        id
        name
        start
        end
        dropIn
        unlimited
      }
      class {
        id
        name
        price
        taxed
        day
        from
        to
      }
      total
      method
      ref
      receipt
      voucher
      discount
      refunded
      status
      created
      updated
      rosters {
        id
        attended
        session {
          name
          date
          start
          end
          status
          class {
            id
            name
          }
        }
      }
      rosterCount
      rosterCountAttended
      rosterCountAbsent
    }
  }
`;

export const PASSES_LIST = gql`
  query Passes(
    $termId: ID
    $userId: ID
    $studentId: ID
    $classId: ID
    $category: String
    $status: [String]
    $name: String
    $from: String
    $to: String
    $limit: Int
    $page: Int
  ) {
    list: passes(
      termId: $termId
      userId: $userId
      studentId: $studentId
      classId: $classId
      category: $category
      status: $status
      name: $name
      from: $from
      to: $to
      limit: $limit
      page: $page
    ) {
      passes {
        id
        name
        user {
          id
          email
          phone
        }
        student {
          id
          name
        }
        term {
          id
          name
        }
        category
        classes {
          id
          name
        }
        total
        method
        ref
        intent
        voucher
        discount
        refunded
        status
        created
        updated
        rosterCount
        rosterCountAttended
        rosterCountAbsent
      }
      total
      page
    }
  }
`;

export const PASS_INFO = gql`
  query Pass($passId: ID) {
    pass(passId: $passId) {
      id
      name
      user {
        id
        email
        phone
        street
        suburb
        postcode
      }
      student {
        id
        name
        picture
        dob
        age
        gender
        notes
      }
      term {
        id
        name
        start
        end
        dropIn
        unlimited
      }
      category
      classes {
        id
        name
      }
      total
      method
      ref
      intent
      voucher
      discount
      refunded
      status
      created
      updated
      rosters {
        id
        attended
        session {
          name
          date
          start
          end
          status
          class {
            id
            name
          }
        }
      }
      rosterCount
      rosterCountAttended
      rosterCountAbsent
    }
  }
`;

export const ORDERS_LIST = gql`
  query Orders(
    $number: Int
    $userId: ID
    $locationId: ID
    $email: String
    $status: [String]
    $from: String
    $to: String
    $limit: Int
    $page: Int
  ) {
    list: orders(
      number: $number
      userId: $userId
      locationId: $locationId
      email: $email
      status: $status
      from: $from
      to: $to
      limit: $limit
      page: $page
    ) {
      orders {
        id
        date
        time
        number
        status
        total
        user {
          id
          email
          phone
        }
        location {
          id
          name
        }
        items {
          id
          product {
            id
            name
          }
          price
          quantity
        }
        delivery
        amounts {
          discount
          refunded
        }
        payment {
          method
          receipt
          ref
        }
        created
        updated
      }
      page
      total
    }
    listLocations {
      id
      name
    }
  }
`;

export const ORDER_INFO = gql`
  query Order($orderId: ID) {
    order(orderId: $orderId) {
      id
      number
      date
      time
      status
      total
      user {
        id
        email
        phone
      }
      location {
        id
        name
      }
      items {
        id
        price
        product {
          id
          name
          thumbnail
        }
        quantity
      }
      delivery
      amounts {
        discount
        refunded
      }
      payment {
        method
        receipt
        ref
      }
      created
      updated
    }
  }
`;

export const TRAININGS_LIST = gql`
  query Trainings(
    $name: String
    $userId: ID
    $locationId: ID
    $hallId: ID
    $type: String
    $status: [String]
    $from: String
    $to: String
    $limit: Int
    $page: Int
  ) {
    list: trainings(
      name: $name
      userId: $userId
      locationId: $locationId
      hallId: $hallId
      type: $type
      status: $status
      from: $from
      to: $to
      limit: $limit
      page: $page
    ) {
      trainings {
        id
        name
        status
        location {
          id
          name
        }
        hall {
          id
          name
        }
        user {
          id
          email
        }
        type
        pack
        purpose
        experience
        availability
        total
        amounts {
          discount
          refunded
        }
        payment {
          method
          ref
          receipt
        }
        sessions {
          id
          name
        }
        created
        updated
      }
      total
      page
    }
  }
`;

export const TRAINING_INFO = gql`
  query Training($trainingId: ID) {
    training(trainingId: $trainingId) {
      id
      name
      status
      user {
        id
        email
        phone
      }
      location {
        id
        name
      }
      hall {
        id
        name
      }
      type
      pack
      purpose
      experience
      availability
      total
      amounts {
        discount
        refunded
      }
      payment {
        method
        ref
        receipt
      }
      schedule {
        from
        to
      }
      sessions {
        id
        name
        date
        start
        end
        conflict
        status
      }
      created
    }
  }
`;

export const PERFORMANCES_LIST = gql`
  query Performances(
    $name: String
    $userId: ID
    $locationId: ID
    $hallId: ID
    $status: [String]
    $from: String
    $to: String
    $limit: Int
    $page: Int
  ) {
    list: performances(
      name: $name
      userId: $userId
      locationId: $locationId
      hallId: $hallId
      status: $status
      from: $from
      to: $to
      limit: $limit
      page: $page
    ) {
      performances {
        id
        name
        status
        dancers
        date
        time
        venue {
          name
          address
          suburb
          state
          postcode
        }
        requirements
        notes
        user {
          id
          email
          phone
        }
        total
        amounts {
          discount
          refunded
        }
        payment {
          method
          ref
          receipt
        }
        created
        updated
      }
      total
      page
    }
  }
`;

export const PERFORMANCE_INFO = gql`
  query Performance($performanceId: ID) {
    performance(performanceId: $performanceId) {
      id
      name
      status
      user {
        id
        email
        phone
      }
      venue {
        name
        address
        suburb
        state
        postcode
      }
      dancers
      date
      time
      requirements
      notes
      total
      amounts {
        discount
        refunded
      }
      payment {
        method
        ref
        receipt
      }
      created
    }
  }
`;

export const HIRES_LIST = gql`
  query Hires(
    $name: String
    $userId: ID
    $locationId: ID
    $hallId: ID
    $status: [String]
    $from: String
    $to: String
    $limit: Int
    $page: Int
  ) {
    list: hires(
      name: $name
      userId: $userId
      locationId: $locationId
      hallId: $hallId
      status: $status
      from: $from
      to: $to
      limit: $limit
      page: $page
    ) {
      hires {
        id
        name
        status
        user {
          id
          email
          phone
        }
        hall {
          id
          name
        }
        location {
          id
          name
        }
        total
        payment {
          method
        }
        amounts {
          deposit
          discount
          refunded
          voucher
        }
        sessions {
          id
          name
        }
        created
        updated
      }
      total
      page
    }
  }
`;

export const HIRE_INFO = gql`
  query Hire($hireId: ID) {
    hire(hireId: $hireId) {
      id
      name
      purpose
      duration
      location {
        id
        name
      }
      hall {
        id
        name
      }
      user {
        id
        email
      }
      total
      amounts {
        discount
        refunded
        deposit
      }
      payment {
        method
        ref
        receipt
      }
      schedule {
        from
        to
      }
      sessions {
        id
        name
        date
        start
        end
        conflict
        status
      }
      status
      created
      updated
    }
  }
`;
