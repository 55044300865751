export const templateCategories = [
  {
    label: "Student Welcome (Email)",
    value: "STUDENT::WELCOME::EMAIL",
    type: "EMAIL",
    trigger: false,
    variables: [
      "%student_name%",
      "%student_first_name%",
      "%student_last_name%",
    ],
  },
  {
    label: "Student Session Reminder (Email)",
    value: "ROSTER::REMINDER::EMAIL",
    type: "EMAIL",
    trigger: false,
    variables: [
      "%student_name%",
      "%student_first_name%",
      "%student_last_name%",
      "%session_date%",
      "%session_time%",
      "%session_start%",
      "%session_end%",
    ],
  },
  {
    label: "Student Session Reminder (SMS)",
    value: "ROSTER::REMINDER::SMS",
    type: "SMS",
    trigger: false,
    variables: [
      "%student_name%",
      "%student_first_name%",
      "%student_last_name%",
      "%session_date%",
      "%session_time%",
      "%session_start%",
      "%session_end%",
    ],
  },
  {
    label: "Student Session Missed (Email)",
    value: "ROSTER::MISSED::EMAIL",
    type: "EMAIL",
    trigger: false,
    variables: [
      "%student_name%",
      "%student_first_name%",
      "%student_last_name%",
      "%session_date%",
      "%session_time%",
      "%session_start%",
      "%session_end%",
    ],
  },
  {
    label: "Student Session Missed (SMS)",
    value: "ROSTER::MISSED::SMS",
    type: "SMS",
    trigger: false,
    variables: [
      "%student_name%",
      "%student_first_name%",
      "%student_last_name%",
      "%session_date%",
      "%session_time%",
      "%session_start%",
      "%session_end%",
    ],
  },
  {
    label: "Student Birthday (Email)",
    value: "STUDENT::BIRTHDAY::EMAIL",
    type: "EMAIL",
    trigger: false,
    variables: [
      "%student_name%",
      "%student_first_name%",
      "%student_last_name%",
      "%student_dob%",
    ],
  },
  {
    label: "Student Birthday (SMS)",
    value: "STUDENT::BIRTHDAY::SMS",
    type: "SMS",
    trigger: false,
    variables: [
      "%student_name%",
      "%student_first_name%",
      "%student_last_name%",
      "%student_dob%",
    ],
  },
  {
    label: "Enrolment Receipt (Email) - Deprecated",
    value: "ENROLMENT::RECEIPT::EMAIL",
    type: "EMAIL",
    trigger: false,
    variables: [
      "%student_name%",
      "%student_first_name%",
      "%student_last_name%",
      "%enrolment_date%",
      "%enrolment_amount%",
    ],
  },
  {
    label: "Term Enrolment Renewal (Email)",
    value: "TERM::RENEWAL::EMAIL",
    type: "EMAIL",
    trigger: true,
    variables: [
      "%student_name%",
      "%student_first_name%",
      "%student_last_name%",
    ],
  },
  {
    label: "Enrolment Renewal (SMS)",
    value: "ENROLMENT::RENEWAL::SMS",
    type: "SMS",
    trigger: true,
    variables: [
      "%student_name%",
      "%student_first_name%",
      "%student_last_name%",
    ],
  },
  {
    label: "Order Ready (Email)",
    value: "ORDER::READY::EMAIL",
    type: "EMAIL",
    trigger: false,
    variables: ["%order_number%", "%order_date%", "%order_amount%"],
  },
  {
    label: "Order Ready (SMS)",
    value: "ORDER::READY::SMS",
    type: "SMS",
    trigger: false,
    variables: ["%order_number%", "%order_date%", "%order_amount%"],
  },
  {
    label: "Transaction Receipt (Email)",
    value: "TRANSACTION::RECEIPT::EMAIL",
    type: "EMAIL",
    trigger: false,
    variables: ["%email%", "%phone%", "%type%"],
  },
];
