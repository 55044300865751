import { LIST_PROGRAMS } from "../../utils/queries";
import { useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export const ProgramsTable = (props) => {
  const notifyError = () => toast.error("Something went wrong!");

  const { data, error } = useQuery(LIST_PROGRAMS);

  useEffect(() => {
    if (error) notifyError();
  }, [error]);

  return (
    <div>
      <div className="overflow-x-auto rounded-xl shadow-md">
        <table className="table-normal divide-y-2 bg-white rounded-xl w-full">
          {/* head */}
          <thead className="text-left">
            <tr>
              <th></th>
              <th>Name</th>
              <th>Details</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody className="divide-y-2">
            {data &&
              data.programs &&
              data.programs.map((value, index) => (
                <tr key={value.id}>
                  <th>{index + 1}</th>
                  <td className="font-bold">{value.name}</td>
                  <td>
                    {value.details ? truncateString(value.details, 50) : "-"}
                  </td>
                  <td>
                    <span
                      className={`text-xs py-1 px-2 rounded-md text-white ${statusTagColor(
                        value.active
                      )}`}
                    >
                      {value.active ? "ACTIVE" : "INACTIVE"}
                    </span>
                  </td>
                  <td>
                    <Link
                      to={`/edit/program/${value.id}`}
                      className="btn btn-xs btn-ghost"
                    >
                      edit
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

function truncateString(string, limit) {
  if (string.length > limit) {
    return string.substring(0, limit) + "...";
  } else {
    return string;
  }
}

function statusTagColor(bool) {
  if (bool) return `bg-info`;
  return `bg-base-300`;
}
