import { useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery, useMutation } from "@apollo/client";
import { UPDATE_CLASS } from "../../utils/mutations";
import { LIST_HALLS, CLASS_INFO, CLASS_SESSIONS } from "../../utils/queries";

export const ClassHallForm = (props) => {
  const { id, location, title } = props;

  const [halls, setHalls] = useState([]);
  const [message, setMessage] = useState("");
  const [submission, setSubmission] = useState(null);

  const { register, handleSubmit, reset } = useForm();

  useQuery(LIST_HALLS, {
    onCompleted: (data) => {
      setHalls(data.halls);
    },
    onError: () => {
      setMessage("Unable to get halls!");
    },
    variables: { location },
  });

  const [updateClassHall, { loading }] = useMutation(UPDATE_CLASS, {
    refetchQueries: [
      { query: CLASS_INFO, variables: { classId: id } },
      { query: CLASS_SESSIONS, variables: { classId: id } },
    ],
    onCompleted: (info) => {
      if (info.updated) {
        setMessage("Class hall updated successfully!");
        setSubmission(info);
      }
    },
    onError: (err) => {
      setMessage("Something went wrong!");
      setSubmission(err);
    },
  });

  const onSubmit = (data) => {
    const { classHall } = data;
    updateClassHall({
      variables: {
        input: {
          id,
          hall: classHall,
        },
      },
    });
  };

  return (
    <div>
      {!submission ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <p className="font-bold text-xl pb-2 border-b">{title}</p>
          </div>
          {/* day */}
          <div>
            <label className="label">
              <span className="label-text font-semibold">Hall</span>
            </label>
            <select
              name="hall"
              defaultValue="Select"
              className="select select-bordered w-full bg-white"
              {...register("classHall")}
              required
            >
              <option value="Select" disabled>
                Select
              </option>
              {halls.map((hall) => (
                <option key={hall.id} value={hall.id}>
                  {hall.name}
                </option>
              ))}
            </select>
          </div>
          {/* submit */}
          <div className="pt-4 space-x-4">
            <button type="submit" className={loading ? "btn loading" : "btn"}>
              Update Hall
            </button>
          </div>
        </form>
      ) : (
        <>
          <p
            className={`font-bold text-xl mb-6 ${
              submission === "error" ? "text-error" : ""
            } ${submission === "success" ? "text-success" : ""}`}
          >
            {message}
          </p>
          <label
            htmlFor="update-modal"
            className="btn btn-outline"
            onClick={() => {
              setSubmission(null);
              setMessage("");
              reset();
            }}
          >
            Close
          </label>
        </>
      )}
    </div>
  );
};
