import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CREATE_TERM, UPDATE_TERM } from "../../utils/mutations";
import { TERM_INFO, LIST_TERMS } from "../../utils/queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import moment from "moment";

export const TermForm = (props) => {
  const { id } = props;
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [info, setInfo] = useState(null);
  const { register, setValue, handleSubmit } = useForm();

  const notifyError = () => toast.error("Something went wrong!");
  const notifySuccess = () => toast.success("Updated successfully!");

  const [prefill, { data: prefillData, error: prefillError }] = useLazyQuery(
    TERM_INFO,
    { variables: { termId: id } }
  );
  const [createTerm, { loading, data, error }] = useMutation(CREATE_TERM, {
    refetchQueries: [{ query: LIST_TERMS }],
  });
  const [
    updateTerm,
    { loading: updateLoading, data: updateData, error: updateError },
  ] = useMutation(UPDATE_TERM, {
    refetchQueries: [{ query: TERM_INFO, variables: { termId: id } }],
  });

  const onSubmit = (data) => {
    const { name, commencement, conclusion, dropIn, unlimited } = data.term;
    if (id) {
      updateTerm({
        variables: {
          input: {
            id,
            name,
            commencement,
            conclusion,
            dropIn: parseInt(parseFloat(dropIn).toFixed(2)) * 100,
            unlimited: parseInt(parseFloat(unlimited).toFixed(2)) * 100,
          },
        },
      });
    } else {
      createTerm({
        variables: {
          input: {
            name,
            commencement,
            conclusion,
            dropIn: parseInt(parseFloat(dropIn).toFixed(2)) * 100,
            unlimited: parseInt(parseFloat(unlimited).toFixed(2)) * 100,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (id) {
      prefill();
    }
  }, [id, prefill]);

  useEffect(() => {
    if (prefillData) {
      setInfo({
        name: prefillData.term.name,
        commencement: moment(prefillData.term.start, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
        conclusion: moment(prefillData.term.end, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
        dropIn: parseFloat(prefillData.term.dropIn / 100).toFixed(2),
        unlimited: parseFloat(prefillData.term.unlimited / 100).toFixed(2),
        status: prefillData.term.status,
      });
    }
  }, [prefillData]);

  useEffect(() => {
    if (prefillError) notifyError();
  }, [prefillError]);

  useEffect(() => {
    if (info) {
      setValue("term", info);
    }
  }, [info, setValue]);

  useEffect(() => {
    if (loading) setLoader(true);
  }, [loading]);

  useEffect(() => {
    if (data) {
      setLoader(false);
      navigate(`/config/terms`);
    }
  }, [data, navigate]);

  useEffect(() => {
    if (error) {
      setLoader(false);
      notifyError();
    }
  }, [error]);

  useEffect(() => {
    if (updateLoading) setLoader(true);
  }, [updateLoading]);

  useEffect(() => {
    if (updateData) {
      setLoader(false);
      notifySuccess();
    }
  }, [updateData]);

  useEffect(() => {
    if (updateError) {
      setLoader(false);
      notifyError();
    }
  }, [updateError]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* name */}
        <div>
          <div>
            <label className="label">
              <span className="label-text font-semibold">Name</span>
            </label>
            <input
              type="text"
              name="name"
              placeholder="Term name"
              className="input input-bordered w-full max-w-md bg-white"
              {...register("term.name")}
              required
            />
          </div>
        </div>
        {/* commencement */}
        <div>
          <div>
            <label className="label">
              <span className="label-text font-semibold">Commencement</span>
            </label>
            <input
              type="date"
              name="commencement"
              className="input input-bordered w-full max-w-md bg-white"
              {...register("term.commencement")}
              disabled={info && info.status === "CURRENT"}
              required
            />
          </div>
        </div>
        {/* conclusion */}
        <div>
          <div>
            <label className="label">
              <span className="label-text font-semibold">Conclusion</span>
            </label>
            <input
              type="date"
              name="conclusion"
              className="input input-bordered w-full max-w-md bg-white"
              {...register("term.conclusion")}
              disabled={info && info.status === "CURRENT"}
              required
            />
          </div>
        </div>
        {/* dropIn price */}
        <div>
          <div>
            <label className="label">
              <span className="label-text font-semibold">
                Drop-in Session Pass Price
              </span>
            </label>
            <input
              type="number"
              name="dropIn"
              placeholder="DropIn Price"
              className="input input-bordered w-full max-w-md bg-white"
              {...register("term.dropIn")}
              min={0}
              step={0.01}
              required
            />
          </div>
        </div>
        {/* unlimited price */}
        <div>
          <div>
            <label className="label">
              <span className="label-text font-semibold">
                Unlimited Sessions Pass Price
              </span>
            </label>
            <input
              type="number"
              name="unlimited"
              placeholder="Unlimited Price"
              className="input input-bordered w-full max-w-md bg-white"
              {...register("term.unlimited")}
              min={0}
              step={0.01}
              required
            />
          </div>
        </div>
        {/* submit */}
        <div className="pt-4">
          <button type="submit" className={loader ? "btn loading" : "btn"}>
            {id ? "Update" : "Create"}
          </button>
        </div>
      </form>
    </div>
  );
};
